import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Heading, useToast, Text, Button } from '@chakra-ui/react';
import { DataTable } from '../../../components';
import { CellProps, Column } from 'react-table';
import { IAppointment } from '../../../interfaces';
import { AppointmentStatusConstants } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { getAppointments } from '../../../state/appointment';
import { useNavigate } from 'react-router-dom';

export const AppointmentInProgress = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { doctor } = useAppSelector(state => state.doctor);
    const { appointments, loading, error } = useAppSelector(state => state.appointment);
    const toast = useToast();

    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        if (doctor) {
            dispatch(getAppointments({ page, status: AppointmentStatusConstants.IN_PROGRESS, doctor_id: doctor.id }));
        }
    }, [page, doctor]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const columns = useMemo<Column<IAppointment>[]>(() => [
        {
            id: 'id',
            Header: 'Historia',
            accessor: 'patient',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value.id}</Text>
                );
            },
        },
        {
            id: 'patient',
            Header: 'Paciente',
            accessor: 'patient',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value.name}</Text>
                );
            },
        },
        {
            id: 'specie',
            Header: 'Especie',
            accessor: 'patient',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value.race.specie.name}</Text>
                );
            },
        },
        {
            id: 'status',
            Header: 'Estado',
            accessor: 'status',
            Cell: () => {
                return (
                    <Badge borderRadius={'md'} backgroundColor={'green.600'} variant='solid'>
                        En Progreso
                    </Badge>
                );
            },
        },
        {
            id: 'start',
            Header: 'Accion',
            accessor: 'status',
            Cell: ({ row }: CellProps<IAppointment>) => {
                const appointment = row.original;
                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'xs'}
                        onClick={() => navigate(`/progreso/${appointment.id}`)}
                    >
                        INGRESAR
                    </Button>
                );
            },
        },
    ],  []);

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'}>
                Consultas en Progreso
            </Heading>
            <DataTable
                columns={columns}
                data={appointments?.items || []}
                loading={loading}
                searchTitle={'Búsqueda local'}
                numberOfPage={appointments?.numberOfPages || 1}
                currentPage={appointments?.currentPage || 1}
                handlePagination={page => setPage(page)}
            />
        </>
    );
};
