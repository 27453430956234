import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Heading, useToast, Text, useDisclosure, Button } from '@chakra-ui/react';
import { DataTable } from '../../../components';
import { CellProps, Column } from 'react-table';
import { IAppointment, IParamsReq, ISummary } from '../../../interfaces';
import { getAppointmentStatus } from '../../../tools';
import { AppointmentStatusConstants, ProfileConstants } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { getAppointments } from '../../../state/appointment';
import { DescriptionUpdateModal, SummaryModal, SummaryUpdateModal } from './modals';
import { format } from 'date-fns';

export const Finished = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.user);
    const { doctor } = useAppSelector(state => state.doctor);
    const { appointments, loading, error } = useAppSelector(state => state.appointment);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const [page, setPage] = useState<number>(1);
    const [summaryData, setSummaryData] = useState<ISummary | undefined>(undefined);
    const [openSummaryNumber, setOpenSummaryNumber] = useState<boolean>(false);
    const [openSummaryDescription, setOpenSummaryDescription] = useState<boolean>(false);

    const isDoctor = user?.role_id === ProfileConstants.DOCTOR;

    const doGetList = (): void => {
        let filter: IParamsReq ={
            page,
            status: AppointmentStatusConstants.FINISHED,
        };

        if (isDoctor) {
            filter = {
                ...filter,
                doctor_id: doctor?.id,
            };
        }

        dispatch(getAppointments(filter));
    };

    useEffect(() => {
        doGetList();
    }, [page, doctor]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const statusColor = (status: string): string => {
        switch (status) {
            case 'En espera':
                return 'orange.300';
            case 'En consulta':
                return 'primary.300';
            case 'Finalizada':
                return 'secondary.300';
            default:
                return '';
        }
    };

    const doCloseUpdateSummary = (): void => {
        setOpenSummaryNumber(false);
    };

    const doCloseUpdateSummaryDescription = (): void => {
        setOpenSummaryDescription(false);
    };

    const columns = useMemo<Column<IAppointment>[]>(() => [
        {
            id: 'id',
            Header: 'Historia',
            accessor: 'patient',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value.id}</Text>
                );
            },
        },
        {
            id: 'patient',
            Header: 'Paciente',
            accessor: 'patient',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value.name}</Text>
                );
            },
        },
        {
            id: 'specie',
            Header: 'Especie',
            accessor: 'patient',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value.race.specie.name}</Text>
                );
            },
        },
        {
            id: 'doctor',
            Header: 'Asignado',
            accessor: 'doctor',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value ? `${value.user.first_name} ${value.user.last_name.charAt(0)}.`: 'Libre'}</Text>
                );
            },
        },
        {
            id: 'status',
            Header: 'Estado',
            accessor: 'status',
            Cell: ({ value }: CellProps<IAppointment>) => {
                const status = getAppointmentStatus(value);
                return (
                    <Badge borderRadius={'md'} backgroundColor={statusColor(status)} variant='solid'>
                        {status}
                    </Badge>
                );
            },
        },
        {
            id: 'date',
            Header: 'Fecha',
            accessor: 'createdAt',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{format(new Date(value), ' dd/LL/yyyy')}</Text>
                );
            },
        },
        {
            id: 'time',
            Header: 'Llegada',
            accessor: 'createdAt',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{format(new Date(value), ' hh:mm b')}</Text>
                );
            },
        },
        {
            id: 'details',
            Header: 'Resumen',
            accessor: 'summary',
            Cell: ({ row }: CellProps<IAppointment>) => {
                const appointment = row.original;
                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'sm'}
                        isDisabled={!Boolean(appointment.summary)}
                        onClick={() => {
                            if (appointment && appointment.summary) {
                                const {id, summary} = appointment;
                                setSummaryData({
                                    id: summary.id,
                                    description: summary.description,
                                    number_of_summary: summary.number_of_summary,
                                    appointment: appointment,
                                    appointment_id: id,
                                });
                                onOpen();
                            }
                        }}>
                        Ver
                    </Button>
                );
            },
            disableSortBy: true,
        },
    ],  []);

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'}>
                Consultas Finalizadas
            </Heading>
            <DataTable
                columns={columns}
                data={appointments?.items || []}
                loading={loading}
                searchTitle={'Búsqueda local'}
                numberOfPage={appointments?.numberOfPages || 1}
                currentPage={appointments?.currentPage || 1}
                handlePagination={page => setPage(page)}
            />
            <SummaryModal
                isOpen={isOpen}
                onClose={onClose}
                summary={summaryData}
                handleSummaryNumberModal={setOpenSummaryNumber}
                handleSummaryDescriptionModal={setOpenSummaryDescription}
            />
            <SummaryUpdateModal
                isOpen={openSummaryNumber}
                updateList={doGetList}
                onClose={doCloseUpdateSummary}
                summary={summaryData}
            />
            <DescriptionUpdateModal
                isOpen={openSummaryDescription}
                updateList={doGetList}
                onClose={doCloseUpdateSummaryDescription}
                summary={summaryData}
            />
        </>
    );
};

