import React, { useEffect } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { RecipeTemplate } from './recipe-template';
import { Box, Button, Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import { useFetch } from '../../../hooks';
import { IRecord } from '../../../interfaces';
import { useParams } from 'react-router-dom';

export const Recipe = () => {
    const { record_id } = useParams();
    const toast = useToast();

    const { loading, error, data } = useFetch<IRecord>(`/records/${Number(record_id)}`);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error al cargar datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const handleGeneratePdf = () => {
        const input = document.getElementById(`recipe-${record_id}`);
        if (input) {
            html2canvas(input, { logging: true, useCORS: true }).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    format: 'letter',
                    unit: 'pt',
                });
                const imgProps= pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save(`${new Date().toISOString()}.pdf`);
            });
        }
    };

    if(!record_id || loading || !data) {
        return (
            <Flex alignItems={'center'} height={'lg'} flexDirection={'column'}>
                <Spinner
                    thickness='3px'
                    speed='0.65s'
                    emptyColor='primary.100'
                    color='primary.400'
                    size='xl'
                />
                <Text mt={2} size='sm' noOfLines={1} ml={'1'} mb={'5'} >
                    Cargando datos ...
                </Text>
            </Flex>
        );
    }

    return (
        <Box bg={'white'} padding={3} borderRadius={'lg'}>
            <RecipeTemplate
                recordId={record_id}
                body={data.treatment.treatment}
            />
            <Flex mt={2} justifyContent={'center'} alignItems={'center'}>
                <Button
                    color={'primary.500'}
                    variant={'link'}
                    onClick={handleGeneratePdf}
                >
                    Descargar Receta
                </Button>
            </Flex>
        </Box>
    );
};
