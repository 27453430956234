import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api';
import { IAppointment, IPaginateResponse, IParamsReq, } from '../../interfaces';
import { AppointmentConstants } from './AppointmentConstants';
import { encodingToQuery } from '../../tools';

export const getAppointments = createAsyncThunk(
    AppointmentConstants.APPOINTMENT_LIST,
    async (params: IParamsReq): Promise<IPaginateResponse<IAppointment>> => {
        const response = await API.get<IPaginateResponse<IAppointment>>(`/appointments?${encodingToQuery(params)}`);
        return response.data;
    });

export const currentAppointment = createAsyncThunk(
    AppointmentConstants.APPOINTMENT_CURRENT,
    async (doctorId: number): Promise<IAppointment> => {
        const response = await API.get<IAppointment>(`/appointments/${doctorId}/current`);
        return response.data;
    });
