import React from 'react';
import { FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { recordSchemaType } from './record-schema';

interface IObservationForm {
    loading: boolean;
    register: UseFormRegister<recordSchemaType>;
    formState: FormState<recordSchemaType>;
}

export const ObservationForm = (props: IObservationForm): JSX.Element => {
    const { loading, register, formState } = props;
    const { errors } = formState;

    return (
        <FormControl isInvalid={!!errors?.observation?.message} mt={2} isDisabled={loading}>
            <FormLabel htmlFor="observation" color="primary.600">Observaciones de esta Revisión</FormLabel>
            <Textarea
                minH={300}
                id="observation"
                focusBorderColor='primary.400'
                {...register('observation', { required: true })}
            />
            <FormErrorMessage>
                {errors.observation && errors.observation.message}
            </FormErrorMessage>
        </FormControl>
    );
};
