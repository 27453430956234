import React, { ChangeEvent, useRef } from 'react';
import { Avatar as AvatarChakra, AvatarBadge, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';

interface IAvatarPet {
    avatarName: string;
    imageUrl: string;
    onChange: (file: ChangeEvent) => Promise<void>;
}

export const Avatar = (props: IAvatarPet): JSX.Element => {
    const { avatarName, imageUrl, onChange } = props;
    const imageRef = useRef<HTMLInputElement>(null);

    const onClickItem = (): void => {
        imageRef.current?.click();
    };

    return (
        <Flex justifyContent={'center'}>
            <Menu>
                <MenuButton
                    transition="all 0.3s"
                    _focus={{ boxShadow: 'none' }}>
                    <AvatarChakra
                        marginX={'auto'}
                        showBorder={true}
                        borderColor={'primary.300'}
                        p={0.5}
                        cursor={'pointer'}
                        backgroundColor={'primary.50'}
                        borderRadius='15px' size='2xl'
                        name={avatarName}
                        src={imageUrl}
                    >
                        <AvatarBadge boxSize='7' borderColor='white'>
                            <Search2Icon w={4} h={4} color={'primary.400'} />
                        </AvatarBadge>
                    </AvatarChakra>
                    <input
                        type='file'
                        accept="image/png, image/jpeg"
                        onChange={onChange}
                        ref={imageRef}
                        style={{ display: 'none' }}
                    />
                </MenuButton>
                <MenuList py={1}>
                    <MenuItem
                        onClick={onClickItem}
                        color={'primary.400'}
                    >
                        Cambiar imagen
                    </MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    );
};
