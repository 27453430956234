import React, { useEffect, useRef, useState } from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Select, useToast } from '@chakra-ui/react';
import { useFetch } from '../../../../hooks';
import { IDoctor } from '../../../../interfaces';

interface IModalDoctor {
    isOpen: boolean;
    setData: (doctor_id?: number) => void;
    onClose: () => void;
}

export const ModalDoctor = (props: IModalDoctor): JSX.Element => {
    const toast = useToast();
    const { isOpen, onClose, setData } = props;
    const cancelRef = useRef<HTMLButtonElement>(null);

    const [showAssign, setShowAssign] = useState<boolean>(false);

    const { fetchData, loading: loadingDoctor, error: errorDoctor, data: doctors} = useFetch<IDoctor[]>('/doctors/availables', undefined, false);
    const [doctor, setDoctor] = useState<number | undefined>(doctors ? doctors[0].id : undefined);


    useEffect(() => {
        if(isOpen && !loadingDoctor && !doctors) {
            fetchData();
        }
    }, [isOpen]);

    const sendPatientToRoom = (): void => {
        setData(doctor);
        onClose();
    };

    const AssignComponent = (): JSX.Element => {
        return (
            <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Seleccione a un doctor
                </AlertDialogHeader>

                <AlertDialogBody>
                    <Select value={doctor} onChange={e => setDoctor(Number(e.target.value))}
                        focusBorderColor='primary.400' bg={'white'} variant={'outline'}
                    >
                        <option key={0} value={undefined} className={'capitalize'}>Ver doctores</option>;
                        {doctors?.map(doctor => {
                            return <option key={doctor.id} value={doctor.id} className={'capitalize'}>{`${doctor.user.first_name} ${doctor.user.last_name}`}</option>;
                        })}
                    </Select>
                </AlertDialogBody>

                <AlertDialogFooter>
                    <Button isDisabled={!Boolean(doctor)} color={'white'} backgroundColor={'secondary.300'} _hover={{ backgroundColor: 'secondary.300' }} onClick={sendPatientToRoom} ml={3}>
                        Seleccionar
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        );
    };



    useEffect(() => {
        if (!loadingDoctor && Boolean(errorDoctor)) {

            toast({
                description: errorDoctor?.message || 'Error al cargar a los doctores',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }

    }, [loadingDoctor]);

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isCentered
            onCloseComplete={() => {
                setShowAssign(false);
                setDoctor(undefined);
            }}
        >
            <AlertDialogOverlay>
                {
                    showAssign ?
                        <AssignComponent /> :
                        (
                            <AlertDialogContent>
                                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                    ¿Desea asignar este paciente a un doctor?
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <Button ref={cancelRef} onClick={sendPatientToRoom}>
                                        No
                                    </Button>
                                    <Button color={'white'} backgroundColor={'primary.300'} _hover={{ backgroundColor: 'primary.300' }} onClick={() => setShowAssign(true)} ml={3}>
                                        Si
                                    </Button>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        )
                }
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
