import React, { useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { TreatmentModal } from './modals';

export interface ITreatment {
    treatment: string;
    setTreatment: (treatment: string) => void;
}

export const Treatment = (props: ITreatment): JSX.Element => {
    const { treatment, setTreatment } = props;
    const [openModal, setOpenModal] = useState<boolean>(false);

    const doOpenModal = (): void => {
        setOpenModal(true);
    };

    const doCloseModal = (): void => {
        setOpenModal(false);
    };

    return (
        <Box my={5}>
            <Flex
                justifyContent={'center'}
                alignContent={'center'}
            >
                <Button
                    variant={'link'}
                    color={'primary.500'}
                    fontWeight={'light'}
                    onClick={doOpenModal}
                >
                    Recetar Tratamiento 📝
                </Button>
            </Flex>
            <TreatmentModal
                isOpen={openModal}
                onClose={doCloseModal}
                treatment={treatment}
                setTreatment={setTreatment}
            />
        </Box>
    );
};
