import React from 'react';
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text } from '@chakra-ui/react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { searchPatientSchemaType } from './search-patient-schema';

interface IOwnerForm {
    title: string;
    loading: boolean;
    register: UseFormRegister<searchPatientSchemaType>;
    formState: FormState<searchPatientSchemaType>;
}

export const OwnerForm = (props: IOwnerForm): JSX.Element => {
    const { title, loading, register, formState } = props;

    return (
        <>
            <Box mb={2}>
                <Text noOfLines={1} fontSize={'xl'} fontWeight={'bold'}>{title}</Text>
            </Box>
            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                <FormControl isInvalid={!!formState?.errors?.first_name?.message} mt={2} isDisabled={loading}>
                    <FormLabel htmlFor="email" color="primary.600">Nombre</FormLabel>
                    <Input
                        id="first_name"
                        type="text"
                        placeholder={'Pedro'}
                        focusBorderColor='primary.400'
                        {...register('first_name', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState?.errors.first_name && formState?.errors.first_name.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl ml={{base: 'none', md: 2}} isInvalid={!!formState?.errors?.last_name?.message} mt={2} isDisabled={loading}>
                    <FormLabel htmlFor="last_name" color="primary.600">Apellido</FormLabel>
                    <Input
                        id="last_name"
                        type="text"
                        placeholder={'Perez'}
                        focusBorderColor='primary.400'
                        {...register('last_name', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState?.errors.last_name && formState?.errors.last_name.message}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                <FormControl isInvalid={!!formState?.errors?.dni?.message} mt={2} isDisabled={loading}>
                    <FormLabel htmlFor="dni" color="primary.600">C.I</FormLabel>
                    <Input
                        id="dni"
                        type="number"
                        placeholder={'12345678'}
                        focusBorderColor='primary.400'
                        {...register('dni', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState?.errors.dni && formState?.errors.dni.message}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
        </>
    );
};
