import React, { useEffect } from 'react';
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Text } from '@chakra-ui/react';
import { FormState, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { searchPatientSchemaType } from './search-patient-schema';
import { useFetch } from '../../hooks';
import { IPetSex, IRace, ISpecie } from '../../interfaces';

interface IPetForm {
    title: string;
    loading: boolean;
    register: UseFormRegister<searchPatientSchemaType>;
    formState: FormState<searchPatientSchemaType>;
    watch: UseFormWatch<searchPatientSchemaType>;
}

export const PetForm = (props: IPetForm): JSX.Element => {
    const { title, loading, register, formState, watch } = props;

    const { fetchData: fetchSpecies, data: species } = useFetch<ISpecie[]>('/species', undefined, false);
    const { fetchData: fetchRace, data: races} = useFetch<IRace[]>(`/races/${watch('specie_id')}`, undefined, false);
    const { fetchData: fetchSexes, data: sexes } = useFetch<IPetSex[]>('/pet_sex', undefined, false);

    useEffect(() => {
        fetchSpecies();
        fetchSexes();
    }, []);

    useEffect(() => {
        const specie_id = watch('specie_id') || 0;
        if (specie_id > 0) {
            fetchRace();
        }
    }, [watch('specie_id')]);

    return (
        <>
            <Box mt={10} mb={2}>
                <Text noOfLines={1} fontSize={'xl'} fontWeight={'bold'}>{title}</Text>
            </Box>
            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                <FormControl isInvalid={!!formState?.errors?.name?.message} mt={2} isDisabled={loading}>
                    <FormLabel htmlFor="name" color="primary.600">Nombre</FormLabel>
                    <Input
                        id="name"
                        type="text"
                        focusBorderColor='primary.400'
                        {...register('name', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState?.errors.name && formState?.errors.name.message}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                <FormControl mt={2}  isDisabled={loading}>
                    <FormLabel htmlFor="specie" color="primary.600">Especie</FormLabel>
                    <Select
                        {...register('specie_id', { required: true })}
                        focusBorderColor='primary.400'
                        bg={'white'}
                        variant={'outline'}
                    >
                        <option key={'blank'} value={0}>SELECCIONE ESPECIE</option>
                        {species?.map(specie => {
                            return <option key={specie.id} value={specie.id} className={'capitalize'}>{specie.name}</option>;
                        })}
                    </Select>
                </FormControl>
                <FormControl ml={{base: 'none', md: 2}} isInvalid={!!formState?.errors?.race_id?.message} mt={2}  isDisabled={loading}>
                    <FormLabel htmlFor="race" color="primary.600">Raza</FormLabel>
                    <Select
                        id="race"
                        {...register('race_id', { required: true })}
                        focusBorderColor='primary.400'
                        bg={'white'}
                        variant={'outline'}
                    >
                        <option key={'blank'} value={0}>SELECCIONE RAZA</option>
                        {races?.map(race => {
                            return <option key={race.id} value={race.id} className={'capitalize'}>{race.name}</option>;
                        })}
                    </Select>
                    <FormErrorMessage>
                        {formState?.errors.race_id && formState?.errors.race_id.message}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                <FormControl isInvalid={!!formState?.errors?.color?.message} mt={2}  isDisabled={loading}>
                    <FormLabel htmlFor="color" color="primary.600">Color</FormLabel>
                    <Input
                        id="color"
                        type="text"
                        focusBorderColor='primary.400'
                        {...register('color', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState?.errors.color && formState?.errors.color.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mx={{base: 'none', md: 2}} mt={2}  isDisabled={loading} isInvalid={!!formState?.errors?.sex_id?.message} >
                    <FormLabel htmlFor="sex_id" color="primary.600">Sexo</FormLabel>
                    <Select {...register('sex_id', { required: true })}
                        focusBorderColor='primary.400' bg={'white'} variant={'outline'}
                    >
                        <option key={'blank'} value={0}>SELECCIONE SEXO</option>
                        {sexes?.map(sex => {
                            return <option key={sex.id} value={sex.id} className={'capitalize'}>{sex.name}</option>;
                        })}
                    </Select>
                    <FormErrorMessage>
                        {formState?.errors.sex_id && formState?.errors.sex_id.message}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
        </>
    );
};
