import React, { useEffect, useMemo, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { IPaginateResponse, ISummary, ISurgery } from '../../../../interfaces';
import { Button, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { useDelete, useFetch, usePost } from '../../../../hooks';
import { TableDrawer } from '../../../../components';
import { AddSurgeryForm } from '../tab-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { addSurgerySchema, addSurgerySchemaType } from '../tab-components/surgery-schema';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { DeleteSurgeryModal, SurgeryObservationsModal } from '../modals';
import { useAppSelector } from '../../../../state/hooks';
import { ProfileConstants } from '../../../../constants';

interface IPets {
    petId?: string;
}
interface ISurgeryOnPets {
    pet_id: number;
    surgery_id: number;
    description?: string;
    surgery: ISummary;
}

interface IDeleteSurgery {
    surgeryId: number;
    openModal: boolean;
}

interface IObservationSurgery {
    observations?: string;
    openModal: boolean;
}

export const Surgeries = (props: IPets): JSX.Element => {
    const { petId } = props;
    const { user } = useAppSelector(state => state.user);

    const [page, setPage] = useState<number>(1);
    const [openDeleteSurgery, setOpenDeleteSurgery] = useState<IDeleteSurgery>({
        surgeryId: 0,
        openModal: false,
    });
    const [openObservationsSurgery, setOpenObservationsSurgery] = useState<IObservationSurgery>({
        observations: '',
        openModal: false,
    });

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { fetchData, loading, error, data} = useFetch<IPaginateResponse<ISurgeryOnPets>>(`/patients/${petId}/surgeries/?page=${page}`, undefined, false);
    const { doRequest, loading: createLoading, error: createError, data: newSurgery } = usePost<ISurgery, { surgery_id: number, description?: string }>(`/patients/${petId}/surgeries`);
    const { doDelete, loading: deletedLoading, error: deletedError, data: deletedSurgery } = useDelete<ISurgery>(`/patients/${petId}/surgeries/${openDeleteSurgery.surgeryId}`);

    useEffect(() => {
        if (petId) {
            fetchData();
        }
    }, [page]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!deletedLoading && deletedSurgery) {
            fetchData();
            doCloseDeleteSurgeryModal();
            toast({
                description: 'Cirugía eliminada exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [deletedLoading]);

    useEffect(() => {
        if (!deletedLoading && Boolean(deletedError)) {
            toast({
                description: deletedError?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [deletedLoading]);

    const columns = useMemo<Column<ISurgeryOnPets>[]>(() => [
        {
            id: 'name',
            Header: 'Nombre',
            accessor: 'surgery',
            Cell: ({ value }: CellProps<ISurgeryOnPets>) => {
                return (
                    <Text>{value.name}</Text>
                );
            },
        },
        {
            id: 'createdAt',
            Header: 'Registrada',
            accessor: 'surgery',
            Cell: ({ value }: CellProps<ISurgeryOnPets>) => {
                return (
                    <Text>{format(new Date(value.createdAt), 'dd/LL/yyyy')}</Text>
                );
            },
        },
        {
            id: 'observations',
            Header: 'Observaciones',
            accessor: 'description',
            Cell: ({ value }: CellProps<ISurgeryOnPets>) => {
                return (
                    <Button
                        bg={'secondary.400'} color={'white'} _hover={{bg: 'secondary.500'}}
                        variant={'outline'}
                        size={'xs'}
                        onClick={() => {
                            setOpenObservationsSurgery({
                                openModal: true,
                                observations: value,
                            });
                        }}
                    >
                        VER
                    </Button>
                );
            },
            disableSortBy: true,
        },
        {
            id: 'remove',
            Header: 'Remover',
            accessor: 'surgery',
            Cell: ({ value }: CellProps<ISurgeryOnPets>) => {
                return (
                    <Button
                        bg={'red.400'} color={'white'} _hover={{bg: 'red.500'}}
                        variant={'outline'}
                        size={'xs'}
                        onClick={() => {
                            setOpenDeleteSurgery({
                                openModal: true,
                                surgeryId: value.id,
                            });
                        }}
                    >
                        REMOVER
                    </Button>
                );
            },
            disableSortBy: true,
        },
    ],  []);

    const { register, handleSubmit, formState } = useForm<addSurgerySchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(addSurgerySchema),
        criteriaMode: 'firstError',
        defaultValues: {
            surgery_id: 0,
            description: '',
        }
    });

    const handlePagination = (page: number): void => setPage(page);

    const onSubmit = (schema: addSurgerySchemaType): void => {
        doRequest({ surgery_id: schema.surgery_id, description: schema.description });
    };

    useEffect(() => {
        if(!createLoading && newSurgery) {
            fetchData();

            toast({
                title: 'Cirugía registrada.',
                description: 'Se ha registrado una nueva cirugía.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        }
    }, [createLoading]);

    useEffect(() => {
        if (!createLoading && Boolean(createError)) {
            toast({
                description: createError?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [createLoading]);

    const doCloseDeleteSurgeryModal = (): void => {
        setOpenDeleteSurgery({
            ...openDeleteSurgery,
            openModal: false,
        });
    };

    const doCloseObservationsSurgeryModal = (): void => {
        setOpenObservationsSurgery({
            observations: '',
            openModal: false,
        });
    };

    const onDeleteSurgery = (): void => {
        doDelete();
    };

    return (
        <>
            <TableDrawer
                isOpen={isOpen}
                drawerTitle={'Registrar Cirugía'}
                children={<AddSurgeryForm formState={formState} register={register} loading={createLoading} />}
                loadingCreate={createLoading}
                buttonActionTitle={'Registar'}
                buttonCancelTitle={'Cancelar'}
                onSubmit={handleSubmit(onSubmit)}
                onCloseDrawer={onClose}
                // table
                columns={columns}
                data={data?.items || []}
                loading={loading}
                searchTitle={'Búsqueda local'}
                hideSearch={false}
                buttonTableTitle={'Registrar'}
                onClickButtonTable={onOpen}
                hideButtonTable={user?.role_id !== ProfileConstants.DOCTOR}
                minHeight={130}
                numberOfPage={data?.numberOfPages || 1}
                currentPage={data?.currentPage || 1}
                handlePagination={handlePagination}
                hiddenColumns={user?.role_id === ProfileConstants.DOCTOR ? undefined : ['remove']}
            />
            <DeleteSurgeryModal
                isOpen={openDeleteSurgery.openModal}
                onClose={doCloseDeleteSurgeryModal}
                doDelete={onDeleteSurgery}
            />
            <SurgeryObservationsModal
                isOpen={openObservationsSurgery.openModal}
                observations={openObservationsSurgery.observations}
                onClose={doCloseObservationsSurgeryModal}
            />
        </>
    );
};
