import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Heading, useToast, Text } from '@chakra-ui/react';
import { DataTable } from '../../../components';
import { CellProps, Column } from 'react-table';
import { IAppointment } from '../../../interfaces';
import { getAppointmentStatus } from '../../../tools';
import { AppointmentStatusConstants } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { getAppointments } from '../../../state/appointment';

export const InProgress = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { appointments, loading, error } = useAppSelector(state => state.appointment);
    const toast = useToast();

    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        dispatch(getAppointments({ page, status: AppointmentStatusConstants.IN_PROGRESS }));
    }, [page]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const statusColor = (status: string): string => {
        switch (status) {
            case 'En espera':
                return 'orange.300';
            case 'En consulta':
                return 'primary.300';
            case 'Finalizada':
                return 'secondary.300';
            default:
                return '';
        }
    };

    const columns = useMemo<Column<IAppointment>[]>(() => [
        {
            id: 'id',
            Header: 'Historia',
            accessor: 'patient',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value.id}</Text>
                );
            },
        },
        {
            id: 'patient',
            Header: 'Paciente',
            accessor: 'patient',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value.name}</Text>
                );
            },
        },
        {
            id: 'specie',
            Header: 'Especie',
            accessor: 'patient',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value.race.specie.name}</Text>
                );
            },
        },
        {
            id: 'doctor',
            Header: 'Asignado',
            accessor: 'doctor',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value ? `${value.user.first_name} ${value.user.last_name.charAt(0)}.`: 'Libre'}</Text>
                );
            },
        },
        {
            id: 'status',
            Header: 'Estado',
            accessor: 'status',
            Cell: ({ value }: CellProps<IAppointment>) => {
                const status = getAppointmentStatus(value);
                return (
                    <Badge borderRadius={'md'} backgroundColor={statusColor(status)} variant='solid'>
                        {status}
                    </Badge>
                );
            },
        }
    ],  []);

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'}>
                Consultas en Progreso
            </Heading>
            <DataTable
                columns={columns}
                data={appointments?.items || []}
                loading={loading}
                searchTitle={'Búsqueda local'}
                numberOfPage={appointments?.numberOfPages || 1}
                currentPage={appointments?.currentPage || 1}
                handlePagination={page => setPage(page)}
            />
        </>
    );
};
