import React, { useEffect } from 'react';
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Select,
    Stack,
    useToast
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventSchema, eventSchemaType } from './event-schema';
import { format } from 'date-fns';
import { useFetch, usePost } from '../../../../../../hooks';
import { IEvent, IEventReq, IEventType } from '../../../../../../interfaces';
import { useAppSelector } from '../../../../../../state/hooks';

interface IEventForm {
    petId?: string;
    refreshEvents: () => void;
}

export const EventForm = ({ petId, refreshEvents }: IEventForm): JSX.Element => {

    const { doctor } = useAppSelector(state => state.doctor);
    const toast = useToast();

    const { data: types, loading: loadingTypes, error: errorTypes } = useFetch<IEventType[]>('/event-type');
    const { doRequest, loading, error, data } = usePost<IEvent, IEventReq>('events');

    const { register, handleSubmit, formState: { errors } } = useForm<eventSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(eventSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            type_id: 1,
            title: '',
            startDate: format(new Date(), 'yyyy-LL-dd'),
            description: '',
        }
    });

    useEffect(() => {
        if (!loadingTypes && Boolean(errorTypes)) {
            toast({
                description: errorTypes?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingTypes]);

    useEffect(() => {
        if(!loading && data) {
            refreshEvents();
            toast({
                description: 'Evento registrados exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error registrando evento',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const onSubmit = async (schema: eventSchemaType): Promise<void> => {
        if (doctor && petId) {
            doRequest({
                ...schema,
                pet_id: Number(petId),
                doctor_id: doctor.id,
            });
        }
    };

    return (
        <Stack spacing='24px'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl mt={2} isInvalid={!!errors?.type_id?.message}>
                    <FormLabel htmlFor="type" color="primary.600">Tipo</FormLabel>
                    <Select
                        {...register('type_id', { required: true })}
                        focusBorderColor='primary.400'
                        bg={'white'}
                        variant={'outline'}
                    >
                        {types?.map(type => {
                            return <option key={type.id} value={type.id} className={'capitalize'}>{type.name}</option>;
                        })}
                    </Select>
                    <FormErrorMessage>
                        {errors.type_id && errors.type_id.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mt={2} isInvalid={!!errors?.title?.message}>
                    <FormLabel htmlFor='title'>Título</FormLabel>
                    <Input
                        id='title'
                        type='text'
                        placeholder='Ej. Consulta kiki'
                        focusBorderColor='primary.400'
                        bg={'white'}
                        disabled={false}
                        {...register('title', { required: true })}
                    />
                    <FormErrorMessage>
                        {errors.title && errors.title.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mt={2} isInvalid={!!errors?.startDate?.message}>
                    <FormLabel htmlFor='startDate'>Fecha</FormLabel>
                    <Input
                        id='startDate'
                        type='datetime-local'
                        focusBorderColor='primary.400'
                        bg={'white'}
                        disabled={false}
                        {...register('startDate', { required: true })}
                    />
                    <FormErrorMessage>
                        {errors.startDate && errors.startDate.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mt={2} isInvalid={!!errors?.description?.message}>
                    <FormLabel htmlFor='description'>Descripción</FormLabel>
                    <Input
                        id='description'
                        type='text'
                        focusBorderColor='primary.400'
                        bg={'white'}
                        disabled={false}
                        {...register('description', { required: true })}
                    />
                    <FormErrorMessage>
                        {errors.description && errors.description.message}
                    </FormErrorMessage>
                </FormControl>
                <Flex justifyContent={'flex-end'}>
                    <Button
                        type='submit'
                        mt={5}
                        isDisabled={false}
                        bg={'primary.400'}
                        color={'white'}
                        _hover={{bg: 'primary.500'}}
                    >
                        Registrar
                    </Button>
                </Flex>
            </form>
        </Stack>
    );
};
