export const TREATMENT_TEMPLATE = `
<h1>
    <strong>Tratamiento:</strong>
</h1>

<p><br></p>
<p><br></p>
<p><br></p>

<h1>
    <strong>Indicaciones:</strong>
</h1>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p style="text-align: center;">
    <strong>_______________________________</strong>
</p>
<h2 style="text-align: center;">
    <strong style="color: rgb(0, 0, 0); text-align: center;">Firma M.V</strong>
</h2>
`;
