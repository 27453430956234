/* eslint-disable react/jsx-key */
import React from 'react';
import {
    Box,
    Button,
    Flex,
    Stack,
    Text,
    Table,
    TableContainer,
    Tbody,
    Thead,
    Tr,
    Th,
    Td,
    TableCaption,
    Skeleton
} from '@chakra-ui/react';

import {
    Column,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable
} from 'react-table';

import { TbChevronsLeft, TbChevronLeft, TbChevronRight, TbChevronsRight } from 'react-icons/tb';
import { TiArrowUnsorted, TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { AddIcon } from '@chakra-ui/icons';

import { Search } from './search';

interface IDataTable<Data extends object> {
    columns: Column<Data>[];
    data: Data[];
    loading?: boolean;
    searchTitle?: string;
    hideSearch?: boolean
    buttonTitle?: string;
    hideButton?: boolean
    numberOfPage: number;
    currentPage: number;
    minHeight?: number;
    hiddenColumns?: string[];
    handlePagination: (page: number) => void;
    onClickButton?: () => void;
}

export function DataTable<Data extends object>(props: IDataTable<Data>) {
    const { columns, data, loading, searchTitle, hideSearch, buttonTitle, hideButton, numberOfPage, currentPage, hiddenColumns, minHeight } = props;
    const { handlePagination, onClickButton } = props;
    const tableInstance = useTable({
        columns,
        data,
        initialState: {
            hiddenColumns: hiddenColumns ? hiddenColumns : [],
        }
    }, useGlobalFilter, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { globalFilter },
        setGlobalFilter,
    } = tableInstance;

    if(loading) {
        return (
            <Stack>
                <Box display={'flex'} justifyContent={'space-between'} flexDir={'row'}>
                    <Skeleton w={'30%'} height='20px' />
                    <Skeleton w={'20%'} height='20px' />
                </Box>
                <Skeleton height='20px' />
                <Skeleton height='20px' />
                <Skeleton height='20px' />
                <Skeleton height='20px' />
                <Skeleton height='20px' />
            </Stack>
        );
    }

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'} flexDir={'row'}>
                {!hideSearch &&
                    <Search
                        title={searchTitle}
                        value={globalFilter}
                        setValue={setGlobalFilter}
                    />
                }
                {!hideButton &&
                    <Button
                        leftIcon={<AddIcon />}
                        bg={'secondary.400'}
                        color={'white'}
                        _hover={{bg: 'secondary.600'}}
                        onClick={onClickButton}
                    >
                        {buttonTitle}
                    </Button>
                }
            </Box>
            <TableContainer backgroundColor={'white'} border={'1px'} borderColor={'primary.100'} borderRadius={'lg'} minHeight={minHeight || 570}>
                <Table variant='simple' {...getTableProps()}>
                    {!(page.length > 0) && <TableCaption>No hay datos que mostrar</TableCaption>}
                    <Thead>
                        {// Loop over the header rows
                            headerGroups.map(headerGroup => (
                            // Apply the header row props
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {// Loop over the headers in each row
                                        headerGroup.headers.map(column => (
                                        // Apply the header cell props
                                            <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                <Flex align={'center'}>
                                                    {// Render the header
                                                        column.render('Header')}
                                                    <Box mb={'1'} ml={'2'} color={'primary.200'}>
                                                        {column.disableSortBy ? undefined :
                                                            column.isSorted ?
                                                                column.isSortedDesc
                                                                    ? <TiArrowSortedDown />
                                                                    : <TiArrowSortedUp />
                                                                : <TiArrowUnsorted />
                                                        }
                                                    </Box>
                                                </Flex>
                                            </Th>
                                        ))}
                                </Tr>
                            ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {
                            page.map(row => {
                                prepareRow(row);
                                return (
                                    <Tr {...row.getRowProps()}>
                                        {
                                            row.cells.map(cell => {
                                                // Apply the cell props
                                                return (
                                                    <Td {...cell.getCellProps()}>
                                                        {// Render the cell contents
                                                            cell.render('Cell')}
                                                    </Td>
                                                );
                                            })}
                                    </Tr>
                                );
                            })
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Stack direction={{ base: 'column', sm: 'row' }} align='center' my={'2'} mx={'0.5'}>
                    <Stack
                        spacing={1} direction='row' align='center' mr={'1'}
                        justifyContent={{ base: 'center', sm: 'flex-start' }}
                    >
                        <Button _hover={{ boxShadow: 'none' }}
                            backgroundColor='primary.400' size='sm'
                            onClick={() => handlePagination(1)} isDisabled={currentPage === 1}
                        >
                            <TbChevronsLeft color='white' />
                        </Button>
                        <Button _hover={{ boxShadow: 'none' }}
                            backgroundColor='primary.400' size='sm'
                            onClick={() => handlePagination(currentPage - 1)} isDisabled={currentPage === 1}
                        >
                            <TbChevronLeft color='white' />
                        </Button>
                        <Button _hover={{ boxShadow: 'none' }}
                            backgroundColor='primary.400' size='sm'
                            onClick={() => handlePagination(currentPage + 1)} isDisabled={currentPage === numberOfPage}
                        >
                            <TbChevronRight color='white' />
                        </Button>
                        <Button _hover={{ boxShadow: 'none' }} backgroundColor='primary.400' size='sm' onClick={() => handlePagination(numberOfPage)} isDisabled={currentPage === numberOfPage}>
                            <TbChevronsRight color='white' />
                        </Button>
                    </Stack>
                    <Text color={'primary.400'} fontSize='sm'>Pág <strong>{currentPage}</strong> de <strong>{numberOfPage}</strong></Text>
                </Stack>
            </Flex>
        </>
    );
}
