import React from 'react';
import { Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { Events, Overview } from './tabs';

export const DoctorMain = (): JSX.Element => {

    return (
        <Tabs isLazy>
            <TabList>
                <Tab color={'primary.500'}>Calendario</Tab>
                <Tab color={'primary.500'}>Resumen</Tab>
            </TabList>
            <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="'primary.500'"
                borderRadius="1px"
            />
            <TabPanels>
                <TabPanel>
                    <Events />
                </TabPanel>
                <TabPanel>
                    <Overview />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};
