import React, { useEffect, useRef, useState } from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Select, useToast } from '@chakra-ui/react';
import { useFetch, usePut } from '../../../../hooks';
import { IAppointment, IDoctor } from '../../../../interfaces';
import { IAppointmentReq } from '../../../../interfaces/network/req/IAppointmentReq';

interface IReassignToDoctorModal {
    isOpen: boolean;
    onClose: () => void;
    patientId: number;
    appointmentId: number;
    doGetAppointments: () => void;
}

export const ReassignModal = (props: IReassignToDoctorModal): JSX.Element => {

    const toast = useToast();
    const { isOpen, onClose, appointmentId, doGetAppointments } = props;
    const cancelRef = useRef<HTMLButtonElement>(null);

    const [showAssign, setShowAssign] = useState<boolean>(false);

    const { loading: loadingDoctor, error: errorDoctor, data: doctors} = useFetch<IDoctor[]>('/doctors');
    const [doctor, setDoctor] = useState<number | undefined>(doctors ? doctors[0].id : undefined);

    const { doUpdate, loading, error, data } = usePut<IAppointment, IAppointmentReq>(`/appointments/${appointmentId}/reasign`);

    const sendPatientToRoom = (): void => {
        if(doctor) {
            doUpdate({ doctor_id: doctor });
        }
        onClose();
    };

    const freePatient = (): void => {
        doUpdate({ doctor_id: 0 });
        onClose();
    };

    const ReassignComponent = (): JSX.Element => {
        return (
            <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Seleccione a un doctor
                </AlertDialogHeader>

                <AlertDialogBody>
                    <Select value={doctor} onChange={e => setDoctor(Number(e.target.value))}
                        focusBorderColor='primary.400' bg={'white'} variant={'outline'}
                    >
                        <option key={0} value={undefined} className={'capitalize'}>Ver doctores</option>;
                        {doctors?.map(doctor => {
                            return <option key={doctor.id} value={doctor.id} className={'capitalize'}>{`${doctor.user.first_name} ${doctor.user.last_name}`}</option>;
                        })}
                    </Select>
                </AlertDialogBody>

                <AlertDialogFooter>
                    <Button isDisabled={!Boolean(doctor)} color={'white'} backgroundColor={'secondary.300'} _hover={{ backgroundColor: 'secondary.300' }} onClick={sendPatientToRoom} ml={3}>
                        Seleccionar
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        );
    };

    useEffect(() => {
        if (!loading && data) {
            toast({
                description: 'Paciente reasignado exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
            doGetAppointments();
            onClose();
        }
        if (!loading && Boolean(error)) {
            const message: string = error?.message || 'Error al reasignado paciente';
            toast({
                description: message,
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }

    }, [loading]);

    useEffect(() => {
        if (!loadingDoctor && Boolean(errorDoctor)) {

            toast({
                description: error?.message || 'Error al cargar a los doctores',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }

    }, [loadingDoctor]);

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isCentered
            onCloseComplete={() => {
                setShowAssign(false);
                setDoctor(undefined);
            }}
        >
            <AlertDialogOverlay>
                {
                    showAssign ?
                        <ReassignComponent /> :
                        (
                            <AlertDialogContent>
                                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                    ¿Desea reasignar este paciente a otro doctor?
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <Button ref={cancelRef} onClick={onClose}>
                                        No
                                    </Button>
                                    <Button color={'white'} backgroundColor={'primary.300'} _hover={{ backgroundColor: 'primary.300' }} onClick={() => setShowAssign(true)} ml={3}>
                                        Si
                                    </Button>
                                    <Button color={'white'} backgroundColor={'secondary.300'} _hover={{ backgroundColor: 'primary.300' }} onClick={freePatient} ml={3}>
                                        Liberar
                                    </Button>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        )
                }
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
