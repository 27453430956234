import React, { /* ChangeEvent, useRef */ useCallback, useEffect } from 'react';

// components
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    useToast,
    Text,
} from '@chakra-ui/react';
import { /* AttachmentIcon, */ DeleteIcon } from '@chakra-ui/icons';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useFetch } from '../../../../../../hooks';
import { ITests, ITestsRecordReq } from '../../../../../../interfaces';
import { testRecordSchema, testRecordSchemaType } from './test-record-schema';

interface ITestModal {
    tests: ITestsRecordReq[];
    isOpen: boolean;
    onAdd: (test: ITestsRecordReq) => void;
    onRemove: (id: number) => void;
    onClose: () => void;
}

export const TestModal = (props: ITestModal): JSX.Element => {
    const { tests, isOpen, onAdd, onRemove, onClose } = props;
    const toast = useToast();
    const { loading, error, data } = useFetch<ITests[]>('/tests');

    // const imageRef = useRef<HTMLInputElement>(null);

    const { register, handleSubmit, formState: { errors }, /* watch */ } = useForm<testRecordSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(testRecordSchema),
        criteriaMode: 'firstError',
        defaultValues: {}
    });

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const getTestName = (id: number): string => {
        let name = 'N/P';
        if (data) {
            const test = data.filter(test => test.id === id);
            name = test[0].name;
        }
        return name;
    };

    const wasAdded = (name: string): boolean => {
        return Boolean(tests.filter(test => test.name === name).length);
    };

    const onSubmit = (schema: testRecordSchemaType): void => {
        const name = getTestName(schema.id);
        if (wasAdded(name)) {
            toast({
                description: 'Esta vacuna ya fue agregada a la lista',
                status: 'warning',
                duration: 7000,
                isClosable: true,
                position: 'bottom-right',
            });
            return;
        }

        onAdd({
            ...schema,
            name,
        });
    };

    // const onClickItem = (): void => {
    //     imageRef.current?.click();
    // };

    // const uploadTestImg = (file: ChangeEvent): void => {
    //     const { files } = file.target as HTMLInputElement;
    //     if (files && files.length !== 0) {
    //         const formData = new FormData();
    //         formData.append('file', files[0]);

    //         const testIndex = tests.findIndex(test => test.id === Number(watch('id')));
    //         const newTest = tests[testIndex];
    //         newTest.img = formData;
    //         console.log(newTest);
    //         // onAdd({...newTest});
    //     }
    // };

    const intemList = useCallback((test: ITestsRecordReq, i: number): JSX.Element => {
        const doRemove = (): void => onRemove(test.id);
        return (
            <Flex
                key={`${test.id}-${i}`}
                borderWidth={0.5}
                my={1}
                borderColor={'gray.200'}
                borderRadius={'md'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Text
                    paddingLeft={2}
                    color="primary.600"
                >
                    {test.name}
                </Text>
                <Button ml={2} size={'sm'} onClick={doRemove}>
                    <DeleteIcon color={'red.400'} />
                </Button>
            </Flex>
        );
    }, [tests]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Registrar Exámen</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex justifyContent={'space-between'}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                                <FormControl mr={2} mt={2} isInvalid={!!errors?.id?.message} isDisabled={loading}>
                                    <FormLabel htmlFor="id" color="primary.600">Examenes</FormLabel>
                                    <Flex>
                                        <Select
                                            {...register('id', { required: true })}
                                            focusBorderColor='primary.400'
                                            bg={'white'}
                                            variant={'outline'}
                                        >
                                            <option key={'blank'}>SELECCIONE EXAMEN</option>
                                            {data?.map(test => {
                                                return <option key={test.id} value={test.id} className={'capitalize'}>{test.name}</option>;
                                            })}
                                        </Select>
                                        {/* <input
                                            type='file'
                                            accept="image/png, image/jpeg"
                                            onChange={uploadTestImg}
                                            ref={imageRef}
                                            style={{ display: 'none' }}
                                        />
                                        <Button
                                            ml={3}
                                            disabled={isNaN(Number(watch('id')))}
                                            onClick={onClickItem}
                                        >
                                            <AttachmentIcon
                                                color={'secondary.500'}
                                            />
                                        </Button> */}
                                    </Flex>
                                    <FormErrorMessage>
                                        {errors.id && errors.id.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Button
                                type='submit'
                                mt={5}
                                width={'full'}
                                bg={'primary.400'}
                                color={'white'}
                                _hover={{bg: 'primary.500'}}
                            >
                                Registrar
                            </Button>
                        </form>
                        <Box>
                            <Text color="primary.600" size={'md'}>Lista de Registrar exámenes a ser registradas</Text>
                            {
                                tests.map((test, i) => {
                                    return intemList(test, i);
                                })
                            }
                        </Box>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
