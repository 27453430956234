import React, { ChangeEvent, useEffect } from 'react';

import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Select,
    Spinner,
    Text,
    useToast
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ownerSchema, ownerSchemaType } from '../owner-schema';
import { useFetch, usePost, usePut } from '../../../../hooks';
import { IOwner, IOwnerReq } from '../../../../interfaces';
import { format } from 'date-fns';

import avatar from '../../../../assets/images/galenos.webp';
import { Avatar } from '../../../../components';

interface IOverview {
    ownerId?: string;
}

export const Overview = (props: IOverview): JSX.Element => {
    const { ownerId } = props;

    const toast = useToast();
    const { fetchData, loading, error, data} = useFetch<IOwner>(`/owners/${Number(ownerId)}`, undefined, false);
    const { doUpdate, loading: loadingUpdated, error: errorUpdated, data: ownerUpdated } = usePut<IOwner, IOwnerReq>(`/owners/${Number(ownerId)}`);
    const { doRequest, loading: loadingAvatar, error: errorAvatar, data: avatarUpdate } = usePost(`/owners/${Number(ownerId)}/avatar`, {headers: {'Content-Type': 'multipart/form-data'}});

    const { register, handleSubmit, formState: { errors, isDirty }, reset} = useForm<ownerSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(ownerSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            first_name: data?.user.first_name || '',
            last_name: data?.user.last_name || '',
            dni: data?.user.dni || '',
            phone: data?.phone || '',
            address: data?.address || '',
            email: data?.user.email || '',
            occupation: data?.occupation || '',
            housing: data?.housing.toUpperCase() || '',
            other_pets: data?.other_pets || false,
        }
    });

    useEffect(() => {
        if (ownerId && !loading) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error al cargar datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if(data){
            reset({
                first_name: data.user.first_name,
                last_name: data.user.last_name,
                dni: data.user.dni,
                phone: data.phone,
                address: data.address,
                email: data.user.email,
                occupation: data.occupation,
                housing: data.housing.toUpperCase(),
                other_pets: data.other_pets,
            });
        }
    }, [data]);

    useEffect(() => {
        if (!loadingUpdated && ownerUpdated) {
            fetchData();
            toast({
                description: 'Datos actualizados exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }

        if (!loadingUpdated && Boolean(errorUpdated)) {
            toast({
                description: errorUpdated?.message || 'Error actualizando datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingUpdated]);

    useEffect(() => {
        if (!loadingAvatar && avatarUpdate) {
            fetchData();
            toast({
                description: 'Datos actualizados exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingAvatar]);

    useEffect(() => {
        if (!loadingAvatar && Boolean(errorAvatar)) {
            toast({
                description: errorAvatar?.message || 'Error actualizando datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingAvatar]);

    const onSubmit = async (schema: ownerSchemaType): Promise<void> => {
        doUpdate({
            first_name: schema.first_name,
            last_name: schema.last_name,
            dni: schema.dni,
            phone: schema.phone,
            address: schema.address,
            email: schema.email,
            occupation: schema.occupation,
            housing: schema.housing.toUpperCase(),
            other_pets: schema.other_pets,
        });
    };

    const onChangeAvatar = async (file: ChangeEvent): Promise<void> => {
        const { files } = file.target as HTMLInputElement;
        if (files && files.length !== 0) {
            const fileSize = files[0].size;
            const fileMB = fileSize / 1024 ** 4;
            if (fileMB > 4) {
                alert('Por favor, seleccione un archivo con peso menor a 4MB.');
            }

            const formData = new FormData();
            formData.append('file', files[0]);
            doRequest(formData);
        }
    };

    if(loading || !data) {
        return (
            <Flex alignItems={'center'} height={'lg'} flexDirection={'column'}>
                <Spinner
                    thickness='3px'
                    speed='0.65s'
                    emptyColor='primary.100'
                    color='primary.400'
                    size='xl'
                />
                <Text mt={2} size='sm' noOfLines={1} ml={'1'} mb={'5'} >
                    Cargando datos ...
                </Text>
            </Flex>
        );
    }

    return (
        <Box py={{ base: '0', sm: '8' }} px={{ base: '4', sm: '10' }}
            bg={'white'} borderRadius={{ base: 'none', sm: 'xl' }}
        >
            <Avatar
                avatarName={data.user.first_name || ''}
                imageUrl={data.user.avatar || avatar}
                onChange={onChangeAvatar}
            />
            <Box my={4} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <Text marginX={'auto'} noOfLines={1} fontSize={'sm'}>Cliente desde: {format(new Date(data?.createdAt || new Date()), 'dd/LL/yyyy')}</Text>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                    <FormControl isInvalid={!!errors?.first_name?.message} mt={2}>
                        <FormLabel htmlFor="email" color="primary.600">Nombre</FormLabel>
                        <Input
                            id="first_name"
                            type="text"
                            placeholder={'Pedro'}
                            focusBorderColor='primary.400'
                            {...register('first_name', { required: true })}
                        />
                        <FormErrorMessage>
                            {errors.first_name && errors.first_name.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl ml={{base: 'none', md: 2}} isInvalid={!!errors?.last_name?.message} mt={2}>
                        <FormLabel htmlFor="last_name" color="primary.600">Apellido</FormLabel>
                        <Input
                            id="last_name"
                            type="text"
                            placeholder={'Perez'}
                            focusBorderColor='primary.400'
                            {...register('last_name', { required: true })}
                        />
                        <FormErrorMessage>
                            {errors.last_name && errors.last_name.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                    <FormControl isInvalid={!!errors?.dni?.message} mt={2}>
                        <FormLabel htmlFor="dni" color="primary.600">C.I</FormLabel>
                        <Input
                            id="dni"
                            type="number"
                            placeholder={'12345678'}
                            focusBorderColor='primary.400'
                            {...register('dni', { required: true })}
                        />
                        <FormErrorMessage>
                            {errors.dni && errors.dni.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl mx={{base: 'none', md: 2}} isInvalid={!!errors?.phone?.message} mt={2}>
                        <FormLabel htmlFor="phone" color="primary.600">Teléfono</FormLabel>
                        <Input
                            id="phone"
                            type="text"
                            placeholder={'424-123-4567'}
                            focusBorderColor='primary.400'
                            {...register('phone', { required: true })}
                        />
                        <FormErrorMessage>
                            {errors.phone && errors.phone.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl mx={{base: 'none', md: 2}} isInvalid={!!errors?.email?.message} mt={2}>
                        <FormLabel htmlFor="email" color="primary.600">Correo</FormLabel>
                        <Input
                            id="email"
                            type="email"
                            placeholder={'pedro@mail.com'}
                            focusBorderColor='primary.400'
                            {...register('email', { required: true })}
                        />
                        <FormErrorMessage>
                            {errors.email && errors.email.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                    <FormControl isInvalid={!!errors?.address?.message} mt={2}>
                        <FormLabel htmlFor="address" color="primary.600">Dirección</FormLabel>
                        <Input
                            id="address"
                            type="text"
                            placeholder={'Lecheria'}
                            focusBorderColor='primary.400'
                            {...register('address', { required: true })}
                        />
                        <FormErrorMessage>
                            {errors.address && errors.address.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                    <FormControl isInvalid={!!errors?.housing?.message} mt={2}>
                        <FormLabel htmlFor="housing" color="primary.600">Tipo de Vivienda</FormLabel>
                        <Select
                            id="housing"
                            {...register('housing', { required: true })}
                            focusBorderColor='primary.400'
                            bg={'white'}
                            variant={'outline'}
                        >
                            <option key={'CASA'} value={'CASA'}>CASA</option>
                            <option key={'APARTAMENTO'} value={'APARTAMENTO'}>APARTAMENTO</option>
                        </Select>
                        <FormErrorMessage>
                            {errors.housing && errors.housing.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl mx={{base: 'none', md: 2}} isInvalid={!!errors?.occupation?.message} mt={2}>
                        <FormLabel htmlFor="occupation" color="primary.600">Ocupación</FormLabel>
                        <Input
                            id="occupation"
                            type="text"
                            placeholder={'Contador'}
                            focusBorderColor='primary.400'
                            {...register('occupation', { required: true })}
                        />
                        <FormErrorMessage>
                            {errors.occupation && errors.occupation.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={2}>
                        <FormLabel htmlFor="other_pets" color="primary.600" mb={4}>¿Posee más de una mascota?</FormLabel>
                        <Checkbox id="other_pets" {...register('other_pets')} mx={1}>Si</Checkbox>
                        <FormErrorMessage>
                            {errors.other_pets && errors.other_pets.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex justifyContent={'flex-end'}>
                    <Button type='submit' mt={5} isDisabled={!isDirty} bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}>
                            Actualizar
                    </Button>
                </Flex>
            </form>
        </Box>
    );
};
