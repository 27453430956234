import React, { useCallback, useEffect, useMemo, useState } from 'react';

// components
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Text,
    useToast
} from '@chakra-ui/react';
import { ModalEdit, DataTable } from '../../../components';

import { CellProps, Column } from 'react-table';
import { format } from 'date-fns';

import { useFetch, usePut } from '../../../hooks';
import { IPaginateResponse, ISurgeryReq, ISurgery } from '../../../interfaces';
import { yupResolver } from '@hookform/resolvers/yup';
import { sexSchema, sexSchemaType } from './sex-schema';
import { useForm } from 'react-hook-form';

interface IEdit {
    openEdit: boolean,
    id?: number;
}

export const Sexes = (): JSX.Element => {
    const [page, setPage] = useState<number>(1);
    const [edit, setEdit] = useState<IEdit | undefined>(undefined);

    const toast = useToast();

    const { fetchData, loading, error, data } = useFetch<IPaginateResponse<ISurgery>>(`/pet_sex?page=${page}`, undefined, false);
    const { doUpdate, loading: updateLoading, error: updateError, data: updateSurgery } = usePut<ISurgery, ISurgeryReq>(`/pet_sex/${edit?.id}`);

    const { register, handleSubmit, formState, clearErrors } = useForm<sexSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(sexSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            name: '',
        }
    });

    useEffect(() => fetchData(), [page]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if(!updateLoading && updateSurgery) {
            fetchData();
            closeEdit();

            toast({
                title: 'Sexo actualizado.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        }
    }, [updateLoading]);

    useEffect(() => {
        if (!updateLoading && Boolean(updateError)) {
            toast({
                description: updateError?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [updateLoading]);

    const columns = useMemo<Column<ISurgery>[]>(() => [
        {
            id: 'name',
            Header: 'Nombre',
            accessor: 'name',
            Cell: ({ value }: CellProps<ISurgery>) => {
                return (
                    <Text textTransform={'uppercase'}>{value}</Text>
                );
            },
        },
        {
            id: 'edit',
            Header: 'Editar',
            accessor: 'id',
            Cell: ({ value }: CellProps<ISurgery>) => {
                const onPress = (): void => {
                    setEdit({
                        id: value,
                        openEdit: true,
                    });
                };

                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'sm'}
                        onClick={onPress}>
                         Editar
                    </Button>
                );
            },
            disableSortBy: true,
        },
        {
            id: 'createdAt',
            Header: 'Registrado',
            accessor: 'createdAt',
            Cell: ({ value }: CellProps<ISurgery>) => {
                return (
                    <Text textTransform={'uppercase'}>{format(new Date(value), 'dd/LL/yyyy')}</Text>
                );
            },
        }
    ],  []);

    const handlePagination = (page: number): void => setPage(page);

    const onUpdate = (schema: sexSchemaType): void => {
        if (edit?.id) {
            doUpdate({ name: schema.name.toUpperCase() });
        }
    };

    const closeEdit = (): void => {
        setEdit({id: undefined, openEdit: false});
        clearErrors();
    };

    const editForm = useCallback((): JSX.Element => {
        return (
            <FormControl isInvalid={!!formState?.errors?.name?.message} mt={2}>
                <FormLabel htmlFor='nombre'>Nombre</FormLabel>
                <Input
                    id='nombre'
                    type='text'
                    placeholder='Ej. Hembra'
                    focusBorderColor='primary.400'
                    bg={'white'}
                    disabled={updateLoading}
                    {...register('name', { required: true })}
                />
                <FormErrorMessage>
                    {formState.errors.name && formState.errors.name.message}
                </FormErrorMessage>
            </FormControl>
        );
    }, [formState]);

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'} >
                Sexo
            </Heading>
            <DataTable
                columns={columns}
                data={data?.items || []}
                currentPage={data?.currentPage || 1}
                numberOfPage={data?.numberOfPages || 1}
                handlePagination={handlePagination}
            />
            <ModalEdit
                isOpen={Boolean(edit?.openEdit)}
                title='Editar Nombre'
                children={editForm}
                loading={updateLoading}
                onClose={closeEdit}
                onSubmit={handleSubmit(onUpdate)}
            />
        </>
    );
};
