import React, { useEffect, useState } from 'react';
import { Box, useToast } from '@chakra-ui/react';

import { BigCalendar, EventModal } from '../../../../components';
import { useAppSelector } from '../../../../state/hooks';
import { useFetch } from '../../../../hooks';
import { IEvent } from '../../../../interfaces';
import { EventClickArg, EventInput } from '@fullcalendar/core';
import { addMonths, format, subMonths } from 'date-fns';

interface IModalDetails {
    isOpen: boolean;
    eventId: string;
}

export const Events = () => {
    const { doctor } = useAppSelector(state => state.doctor);
    const [events, setEvents] = useState<EventInput[]>([]);
    const [currentDate, setCurrentDate] = useState<Date>(new Date());

    const toast = useToast();
    const { fetchData, data, loading, error } = useFetch<IEvent[]>('', undefined, false);
    const [modalDetails, setModalDetails] = useState<IModalDetails | undefined>(undefined);

    useEffect(() => {
        if (doctor && doctor.id) {
            const nextMonth = format(addMonths(currentDate, 1), 'yyyy-LL-dd');
            const prevMonth = format(subMonths(currentDate, 1), 'yyyy-LL-dd');
            fetchData(`/doctors/${doctor?.id}/events?startDate=${prevMonth}&endDate=${nextMonth}`);
        }
    }, [doctor, currentDate]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error al cargar datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!loading && data) {
            const formattedEvents = data.map(event => {
                return {
                    id: String(event.id),
                    title: event.title.toUpperCase(),
                    start: format(new Date(event.startDate), 'yyyy-LL-dd'),
                    color: event.type.color,
                };
            });
            setEvents(formattedEvents);
        }
    }, [loading]);

    const hanldeEventClick = (event: EventClickArg): void => {
        setModalDetails({
            isOpen: true,
            eventId: event.event.id,
        });
    };

    const onCloseModal = (): void => {
        setModalDetails(undefined);
    };

    return (
        <Box bg={'white'} padding={3} borderRadius={'lg'}>
            <BigCalendar
                events={events}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                hanldeEventClick={hanldeEventClick}
            />
            {
                modalDetails &&
                <EventModal
                    id={modalDetails.eventId}
                    isOpen={modalDetails.isOpen}
                    onClose={onCloseModal}
                />
            }
        </Box>
    );
};
