import React, { useCallback } from 'react';
import { Box, Button, Flex, Spinner } from '@chakra-ui/react';

interface IButtonActions {
    actionRight: () => void
    actionLeft: () => void
    loading?: boolean;
}

export const ButtonActions = (props:IButtonActions): JSX.Element => {
    const { actionRight, actionLeft, loading } = props;

    const button = useCallback((title: string, action :() => void): JSX.Element => {
        return (
            <Button
                textTransform={'uppercase'}
                variant={'outline'}
                color={'primary.500'}
                onClick={action}
                disabled={loading}
            >
                {title}
            </Button>
        );
    }, [loading]);

    return (
        <Flex justifyContent={'center'} alignItems={'center'} marginTop={8}>
            {
                loading ?
                    <Spinner color={'primary.500'}/>
                    :
                    <>
                        <Box marginInline={3}>
                            {button('Consulta', actionRight)}
                        </Box>
                        <Box marginInline={3}>
                            {button('Revision', actionLeft)}
                        </Box>
                    </>
            }
        </Flex>
    );
};
