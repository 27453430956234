import { ISession } from '../interfaces';

export const storeCredentials = (credentials: ISession): void => localStorage.setItem('credentials', JSON.stringify(credentials));
export const removeCredentials = (): void => localStorage.clear();
export const getCredentials = (): string | null => localStorage.getItem('credentials');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setStoredAppointment = (appointment: any): void => localStorage.setItem('appointment', JSON.stringify(appointment));
export const removeStoredAppointment = (): void => localStorage.removeItem('appointment');
export const getStoredAppointment = (): string | null => localStorage.getItem('appointment');
