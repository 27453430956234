import React from 'react';

import { RouteObject } from 'react-router-dom';
import { ILinkItem } from '../../interfaces';

import { FiHome, FiUsers } from 'react-icons/fi';
import { IoPawSharp } from 'react-icons/io5';
import { BsJournalMedical } from 'react-icons/bs';

import {
    Account,
    Appointment,
    ChangePassword,
    DoctorMain,
    EditAccount,
    Finished,
    NotFound,
    OwnerDetails,
    Owners,
    PatientDetails,
    Patients,
    RecordDetails,
    Waiting,
    SearchPatientPage,
    Recipe,
    AppointmentInProgress,
    SearchOwnerPage,
} from '../../pages';

export const doctorLinks: ILinkItem[] = [
    { name: 'Inicio', icon: FiHome, path: '/' },
    { name: 'Consultas',
        icon: BsJournalMedical,
        childrens: [
            { name: 'En Sala', path: '/sala' },
            { name: 'En Progreso', path: '/progreso' },
            { name: 'Finalizadas', path: '/finalizadas' },
        ]
    },
    { name: 'Pacientes',
        icon: IoPawSharp,
        childrens: [
            { name: 'Busqueda', path: '/buscar/paciente' },
            { name: 'Pacientes', path: '/pacientes' },
        ]
    },
    { name: 'Propietarios',
        icon: FiUsers,
        childrens: [
            { name: 'Busqueda', path: '/buscar/propietario' },
            { name: 'Listar', icon: FiUsers,  path: '/propietarios/lista' },
        ]
    },
];

export const doctorRoutes: RouteObject[] = [
    {
        path: '/',
        element: <DoctorMain />,
    },
    {
        path: '/sala',
        element: <Waiting />,
    },
    {
        path: '/progreso',
        element: <AppointmentInProgress />,
    },
    {
        path: '/progreso/:id',
        element: <Appointment />,
    },
    {
        path: '/finalizadas',
        element: <Finished />,
    },
    {
        path: '/historia/:id',
        element: <RecordDetails />,
    },
    {
        path: '/recipe/:record_id',
        element: <Recipe />,
    },
    {
        path: '/buscar/paciente',
        element: <SearchPatientPage />,
    },
    {
        path: '/pacientes',
        element: <Patients />,
    },
    {
        path: '/paciente/:id',
        element: <PatientDetails />,
    },
    {
        path: '/buscar/propietario',
        element: <SearchOwnerPage />,
    },
    {
        path: '/propietarios/lista',
        element: <Owners />,
    },
    {
        path: '/propietario/:id',
        element: <OwnerDetails />,
    },
    {
        path: '/cuenta',
        element: <Account />,
    },
    {
        path: '/cuenta/actualizar',
        element: <EditAccount />,
    },
    {
        path: '/cambiar/contraseña',
        element: <ChangePassword />,
    },
    {
        path: '*',
        element: (
            <NotFound />
        ),
    },
];
