import React, { FormEvent } from 'react';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
} from '@chakra-ui/react';

interface IAddDrawer {
    isOpen: boolean;
    drawerTitle: string;
    children: JSX.Element;
    loading: boolean;
    buttonActionTitle: string;
    buttonCancelTitle: string;
    onClose: () => void;
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export const AddDrawer = (props: IAddDrawer) => {
    const { isOpen, drawerTitle, children, loading, buttonActionTitle, buttonCancelTitle } = props;
    const {  onClose, onSubmit } = props;

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
        >
            <DrawerOverlay />
            <form onSubmit={onSubmit}>
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        {drawerTitle}
                    </DrawerHeader>

                    <DrawerBody>
                        {children}
                    </DrawerBody>

                    <DrawerFooter borderTopWidth='1px'>
                        <Button variant='outline' mr={3} onClick={onClose}>
                            {buttonCancelTitle}
                        </Button>
                        <Button
                            isLoading={loading}
                            type='submit'
                            bg={'primary.400'}
                            color={'white'}
                            _hover={{bg: 'primary.500'}}
                        >
                            {buttonActionTitle}
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </form>
        </Drawer>
    );
};
