import React from 'react';
import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Text } from '@chakra-ui/react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { addPatientSchemaType } from './add-patient-schema';

interface IOwnerForm {
    title: string;
    loading: boolean;
    isReadOnly: boolean;
    register: UseFormRegister<addPatientSchemaType>;
    formState: FormState<addPatientSchemaType>;
}

export const OwnerForm = (props: IOwnerForm): JSX.Element => {
    const { title, loading, isReadOnly, register, formState } = props;

    return (
        <>
            <Box mb={2}>
                <Text noOfLines={1} fontSize={'xl'} fontWeight={'bold'}>{title}</Text>
            </Box>
            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                <FormControl isInvalid={!!formState?.errors?.first_name?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                    <FormLabel htmlFor="email" color="primary.600">Nombre</FormLabel>
                    <Input
                        id="first_name"
                        type="text"
                        placeholder={'Pedro'}
                        focusBorderColor='primary.400'
                        {...register('first_name', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState?.errors.first_name && formState?.errors.first_name.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl ml={{base: 'none', md: 2}} isInvalid={!!formState?.errors?.last_name?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                    <FormLabel htmlFor="last_name" color="primary.600">Apellido</FormLabel>
                    <Input
                        id="last_name"
                        type="text"
                        placeholder={'Perez'}
                        focusBorderColor='primary.400'
                        {...register('last_name', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState?.errors.last_name && formState?.errors.last_name.message}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                <FormControl isInvalid={!!formState?.errors?.dni?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                    <FormLabel htmlFor="dni" color="primary.600">C.I</FormLabel>
                    <Input
                        id="dni"
                        type="number"
                        placeholder={'12345678'}
                        focusBorderColor='primary.400'
                        {...register('dni', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState?.errors.dni && formState?.errors.dni.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mx={{base: 'none', md: 2}} isInvalid={!!formState?.errors?.phone?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                    <FormLabel htmlFor="phone" color="primary.600">Teléfono</FormLabel>
                    <Input
                        id="phone"
                        type="text"
                        placeholder={'424-123-4567'}
                        focusBorderColor='primary.400'
                        {...register('phone', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState?.errors.phone && formState?.errors.phone.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mx={{base: 'none', md: 2}} isInvalid={!!formState?.errors?.email?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                    <FormLabel htmlFor="email" color="primary.600">Correo</FormLabel>
                    <Input
                        id="email"
                        type="email"
                        placeholder={'pedro@mail.com'}
                        focusBorderColor='primary.400'
                        {...register('email', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState?.errors.email && formState?.errors.email.message}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                <FormControl isInvalid={!!formState?.errors?.address?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                    <FormLabel htmlFor="address" color="primary.600">Dirección</FormLabel>
                    <Input
                        id="address"
                        type="text"
                        placeholder={'Lecheria'}
                        focusBorderColor='primary.400'
                        {...register('address', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState?.errors.address && formState?.errors.address.message}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                <FormControl isInvalid={!!formState?.errors?.housing?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                    <FormLabel htmlFor="housing" color="primary.600">Tipo de Vivienda</FormLabel>
                    <Select
                        id="housing"
                        {...register('housing', { required: true })}
                        focusBorderColor='primary.400'
                        bg={'white'}
                        variant={'outline'}
                    >
                        <option key={'CASA'} value={'CASA'}>CASA</option>
                        <option key={'APARTAMENTO'} value={'APARTAMENTO'}>APARTAMENTO</option>
                    </Select>
                    <FormErrorMessage>
                        {formState?.errors.housing && formState?.errors.housing.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mx={{base: 'none', md: 2}} isInvalid={!!formState?.errors?.occupation?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                    <FormLabel htmlFor="occupation" color="primary.600">Ocupación</FormLabel>
                    <Input
                        id="occupation"
                        type="text"
                        placeholder={'Contador'}
                        focusBorderColor='primary.400'
                        {...register('occupation', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState?.errors.occupation && formState?.errors.occupation.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mt={2}>
                    <FormLabel htmlFor="other_pets" color="primary.600" mb={4}>¿Posee más de una mascota?</FormLabel>
                    <Checkbox id="other_pets" {...register('other_pets')} isReadOnly={isReadOnly} isDisabled={loading} mx={1}>Si</Checkbox>
                    <FormErrorMessage>
                        {formState?.errors.other_pets && formState?.errors.other_pets.message}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
        </>
    );
};
