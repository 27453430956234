import React, { useEffect } from 'react';
import { Box, Button, Flex, FormControl, FormErrorMessage, Input, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { searchOwnerSchema, searchOwnerSchemaType } from './search-owner-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFetch } from '../../hooks';
import { IOwner } from '../../interfaces';

interface ISearchOwner {
    buttonTitle?: string;
    setOwner: ({owner, dni}: {owner?: IOwner, dni: string}) => void;
    onSearch?: () => void;
}

export const SearchOwner = ({ buttonTitle, setOwner, onSearch }: ISearchOwner): JSX.Element => {

    const toast = useToast();
    const { fetchData, loading, error, data} = useFetch<IOwner>('', undefined, false);

    const { register, handleSubmit, formState: { errors, isDirty } } = useForm<searchOwnerSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(searchOwnerSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            dni: '',
        }
    });

    useEffect(() => {
        if (!loading && data) {
            setOwner({
                owner: data,
                dni: data.user.dni,
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }

    }, [loading]);

    const onSearchOwner = (schema: searchOwnerSchemaType): void => {
        fetchData(`/owners/dni/${schema.dni}`);
        setOwner({
            dni: schema.dni,
        });
        if (onSearch) {
            onSearch();
        }
    };

    return (
        <Box w={{ base: 'full', lg: '2xl', md: 'l' }} mb={'2'}>
            <form onSubmit={handleSubmit(onSearchOwner)}>
                <Flex>
                    <FormControl isInvalid={!!errors?.dni?.message}  isRequired>
                        <Input type='number' placeholder={'Ingresar cédula del propietario'}
                            focusBorderColor='primary.400' bg={'white'}
                            variant={'outline'} disabled={loading}
                            {...register('dni', { required: true })}
                        />
                        <FormErrorMessage>
                            {errors.dni && errors.dni.message}
                        </FormErrorMessage>
                    </FormControl>
                    <Button ml={2}
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.600'}}
                        isLoading={loading} isDisabled={!isDirty} size={'sm'}
                        type='submit' px={'5'} py={'5'}
                    >
                        {buttonTitle ? buttonTitle :  'Iniciar registro'}
                    </Button>
                </Flex>
            </form>
        </Box>
    );
};
