import * as yup from 'yup';

export const userSchema = yup.object({
    first_name: yup.string().label('first_name').required('Este campo es requerido'),
    last_name: yup.string().label('last_name').required('Este campo es requerido'),
    dni: yup.string().label('dni').min(5, 'No de identificación no valido').required('Este campo es requerido').matches(/^[0-9]+$/, 'No de identificación no valido (Ej. 12345678)'),
    email: yup.string().label('email').email('Debe ingresar un correo valido').required('Este campo es requerido'),
    role_id: yup.number().label('role_id').required('Este campo es requerido'),
});

export type userSchemaType = yup.InferType<typeof userSchema>
