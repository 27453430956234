import React from 'react';
import { format } from 'date-fns';

import avatar from '../../../assets/images/galenos_square.png';

interface IRecupeTemplate {
    recordId: string;
    body: string;
}
export const RecipeTemplate = ({ recordId, body }: IRecupeTemplate) => {
    return (
        <div id={`recipe-${recordId}`}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 3,
                }}
            >
                <div style={{ display: 'flex'}}>
                    <div>
                        <img src={avatar} width={120} />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            borderBottomWidth: 2.5,
                            borderBottomColor: '#6161b7',
                            paddingBottom: 8
                        }}
                    >
                        <p style={{ fontSize: '17px', margin: 0, padding: 0, marginBottom: -3}}>Av. Píritu con calle Onoto, Qta La Gatera,</p>
                        <p style={{ fontSize: '17px', margin: 0, padding: 0, marginBottom: -3}}>Sector Morro I, , Edo. Anzoátegui.</p>
                        <p style={{ fontSize: '17px', margin: 0, padding: 0, marginBottom: -3}}>Teléfono: (0281) 282.06.58</p>
                        <p style={{ fontSize: '17px', margin: 0, padding: 0, marginBottom: -3}}>E-mail: galenosclinicaveterinaria37@gmail.com</p>
                        <p style={{ fontSize: '17px', margin: 0, padding: 0, marginBottom: -3}}>RIF: J-29536233-1</p>
                    </div>
                </div>
                <div>
                    <p>Fecha: {format(new Date(), 'dd/LL/yyyy')}</p>
                </div>
            </div>
            <div style={{ padding: 15 }} dangerouslySetInnerHTML={{__html: body}} />
        </div>
    );
};
