import React, { useEffect, useState } from 'react';

// components
import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
    Text
} from '@chakra-ui/react';
import { AxisOptions, Chart } from 'react-charts';
import { useFetch } from '../../hooks';
import { IPhysiologicalConstants } from '../../interfaces';

interface IChartModal {
    isOpen: boolean;
    petId: number;
    onClose: () => void;
}

type Weight = {
    createdAt: Date;
    weight: number;
}

type Series = {
  label: string,
  data: Weight[]
}

export const ChartModal = (props: IChartModal): JSX.Element => {
    const { isOpen, petId, onClose } = props;
    const toast = useToast();
    const [points, setPoints] = useState<Weight[]>([]);

    const { loading, error, data } = useFetch<IPhysiologicalConstants[]>(`/patients/${petId}/physiological_constants`);

    useEffect(() => {
        if (data && data.length > 0) {
            const mappedWeight: Weight[] = data.map(p_constant => {
                return {
                    createdAt: new Date(p_constant.createdAt),
                    weight: p_constant.weight,
                };
            });

            setPoints(mappedWeight);
        }
    }, [data]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error cargando datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const serie: Series[] = [
        {
            label: 'Peso',
            data: points,
        },
    ];

    const primaryAxis = React.useMemo(
        (): AxisOptions<Weight> => ({
            getValue: datum => datum.createdAt,
        }),
        []
    );

    const secondaryAxes = React.useMemo(
        (): AxisOptions<Weight>[] => [
            {
                getValue: datum => datum.weight,
            },
        ],
        []
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Gráfico de Constantes Fisiológicas</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {
                        points.length > 0
                            ? (
                                <Chart
                                    options={{
                                        data: serie,
                                        primaryAxis,
                                        secondaryAxes,
                                    }}
                                />
                            )
                            : (
                                <Flex justifyContent={'center'} alignItems={'center'}>
                                    <Text>Aun no hay datos para mostrar</Text>
                                </Flex>
                            )
                    }
                </ModalBody>

                <ModalFooter>

                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
