import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { OwnerForm } from './owner-form';
import { PetForm } from './pet-form';
import { searchPatientSchema, searchPatientSchemaType } from './search-patient-schema';

interface ISearchPatient {
    getData: (data: searchPatientSchemaType) => void;
    loading?: boolean;
}

export const SearchPatient = ({ getData, loading }: ISearchPatient): JSX.Element => {

    const { register, handleSubmit, formState, watch } = useForm<searchPatientSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(searchPatientSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            // owner
            first_name: '',
            last_name: '',
            dni: '',

            // patient
            race_id: undefined,
            specie_id: undefined,
            name: '',
            color: '',
            sex_id: undefined,
        }
    });

    const onSubmit = (schema: searchPatientSchemaType): void => {
        getData({
            // owner
            first_name: schema.first_name && schema.first_name.length > 0 ? schema.first_name : undefined,
            last_name: schema.last_name && schema.last_name.length > 0 ? schema.last_name : undefined,
            dni: schema.dni && schema.dni.length > 0 ? schema.dni : undefined,

            // patient
            race_id: schema.race_id ? schema.race_id : undefined,
            specie_id: schema.specie_id ? schema.specie_id : undefined,
            name: schema.name ? schema.name : undefined,
            color: schema.color ? schema.color : undefined,
            sex_id: schema.sex_id ? schema.sex_id : undefined,
        });
    };

    return (
        <Box
            py={{ base: '0', sm: '8' }}
            px={{ base: '4', sm: '10' }}
            bg={'white'}
            borderRadius={{ base: 'none', sm: 'xl' }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <OwnerForm
                    title={'Datos Propietario'}
                    loading={Boolean(loading)}
                    register={register}
                    formState={formState}
                />
                <PetForm
                    title={'Datos Mascota'}
                    loading={Boolean(loading)}
                    register={register}
                    formState={formState}
                    watch={watch}
                />
                <Flex justifyContent={'flex-end'}>
                    <Button
                        type='submit'
                        mt={5}
                        isDisabled={!formState.isDirty || loading}
                        isLoading={loading}
                        bg={'primary.400'}
                        color={'white'}
                        _hover={{bg: 'primary.500'}}
                    >
                        Buscar
                    </Button>
                </Flex>
            </form>
        </Box>
    );
};
