import { Reducer, combineReducers } from '@reduxjs/toolkit';
import { userSlice } from './user';
import { authSlice } from './auth';
import { appointmentSlice } from './appointment';
import { doctorSlice } from './doctor';

export const reducers: Reducer = combineReducers({
    auth: authSlice.reducer,
    user: userSlice.reducer,
    doctor: doctorSlice.reducer,
    appointment: appointmentSlice.reducer,
});

export type RootState = ReturnType<typeof reducers>;
