import React from 'react';

// components
import { Box, FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { surgerySchemaType } from './surgery-schema';

interface ISurgeryForm {
    loading: boolean;
    register: UseFormRegister<surgerySchemaType>;
    formState: FormState<surgerySchemaType>;
}

export const SurgeryForm = (props: ISurgeryForm): JSX.Element => {
    const { loading, register, formState } = props;

    return (
        <Stack spacing='24px'>
            <Box>
                <FormControl  isInvalid={!!formState?.errors?.name?.message} mt={2}>
                    <FormLabel htmlFor='nombre'>Nombre</FormLabel>
                    <Input
                        id='nombre'
                        type='text'
                        placeholder='Ej. Mastectomía'
                        focusBorderColor='primary.400'
                        bg={'white'}
                        disabled={loading}
                        {...register('name', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState.errors.name && formState.errors.name.message}
                    </FormErrorMessage>
                </FormControl>

            </Box>
        </Stack>
    );
};
