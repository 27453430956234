import React, { useEffect, useState } from 'react';
import { DataTable, SearchPatient, searchPatientSchemaType } from '../../../components';
import { CellProps, Column } from 'react-table';
import { IPaginateResponse, IPatient } from '../../../interfaces';
import { encodingToQuery } from '../../../tools';
import { Button, useToast, Text } from '@chakra-ui/react';
import { useFetch } from '../../../hooks';
import { LabelStep } from './label-step';

interface IFirstStep {
    petId?: number;
    setPetId: (id: number) => void;
}

export const FirstStep = (props: IFirstStep): JSX.Element | null => {
    const { petId, setPetId } = props;

    const [url, setUrl] = useState<string>('');
    const [page, setPage] = useState<number>(1);

    const toast = useToast();

    const { fetchData, loading, error, data } = useFetch<IPaginateResponse<IPatient>>(url, undefined, false);

    const onSearchPatient = (schema: searchPatientSchemaType): void => {
        setUrl(`/patients?${encodingToQuery({page, ...schema})}`);
    };

    useEffect(() => {
        if (url.length > 0) {
            fetchData();
        }
    }, [url]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error al cargar mascotas',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }

    }, [loading]);

    const columns: Column<IPatient>[] =  [
        {
            id: 'id',
            Header: 'Nro Historia',
            accessor: 'id',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value}
                    </Text>
                );
            },
        },
        {
            id: 'name',
            Header: 'Nombre',
            accessor: 'name',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value}
                    </Text>
                );
            },
        },
        {   id: 'specie',
            Header: 'Especie',
            accessor: 'race',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value.specie.name}
                    </Text>
                );
            },
        },
        {   id: 'race',
            Header: 'Raza',
            accessor: 'race',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value.name}
                    </Text>
                );
            },
        },
        {
            id: 'owner',
            Header: 'Propietario',
            accessor: 'owner',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value ? `${value.user.first_name} ${value.user.last_name}` : ''}
                    </Text>
                );
            },
        },
        {
            id: 'details',
            Header: 'Detalles',
            accessor: 'id',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => setPetId(value)}>
                        Seleccionar
                    </Button>
                );
            },
            disableSortBy: true,
        },
    ];

    if (!petId) {
        return (
            <>
                <LabelStep
                    step={1}
                    label={'Buscar paciente y seleccionarlo'}
                />
                <SearchPatient getData={onSearchPatient} loading={loading} />
                {
                    data && data.items.length > 0 && !petId &&
                    <DataTable
                        columns={columns}
                        data={data.items || []}
                        loading={loading}
                        numberOfPage={data?.numberOfPages || 1}
                        currentPage={data?.currentPage || 1}
                        handlePagination={page => setPage(page)}
                        hideSearch={true}
                    />
                }
            </>
        );
    }

    return null;
};
