import React, { useState } from 'react';
import { Heading } from '@chakra-ui/react';
import { IOwner } from '../../../interfaces';
import { FirstStep } from './first-step';
import { SecondStep } from './second-step';
import { ThirdStep } from './third-step';

export const ReassignPatient = (): JSX.Element => {
    const [petId, setPetId] = useState<number | undefined>(undefined);
    const [ownerInfo, setOwner] = useState<{owner?: IOwner, dni: string} | undefined>(undefined);

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'} >
                Reasignar Paciente
            </Heading>
            <FirstStep
                petId={petId}
                setPetId={setPetId}
            />
            <SecondStep
                petId={petId}
                setOwner={setOwner}
            />
            <ThirdStep
                petId={petId}
                ownerInfo={ownerInfo}
            />
        </>
    );
};

