import React, { useEffect, useRef } from 'react';
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    useToast
} from '@chakra-ui/react';
import { useFetch } from '../../../../hooks';
import { IAppointment } from '../../../../interfaces';
import { useNavigate } from 'react-router-dom';

interface IReassignToDoctorModal {
    isOpen: boolean;
    onClose: () => void;
    appointmentId: number;
    doGetDoctor: () => void;
    doGetAppointments: () => void;
}

export const StartModal = (props: IReassignToDoctorModal): JSX.Element => {
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, appointmentId } = props;
    const { onClose, doGetDoctor, doGetAppointments } = props;
    const cancelRef = useRef<HTMLButtonElement>(null);

    const { fetchData, loading, error, data } = useFetch<IAppointment>(`/appointments/start/${appointmentId}`, undefined, false);

    useEffect(() => {
        if (!loading && data) {
            toast({
                description: 'Consulta actualizada exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
            doGetDoctor();
            doGetAppointments();
            onClose();
            navigate('/progreso');
        }
    }, [loading]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const doStart = (): void => {
        fetchData();
    };

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        ¿Desea comenzar con esta consulta?
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            No
                        </Button>
                        <Button
                            color={'white'}
                            backgroundColor={'primary.300'}
                            _hover={{ backgroundColor: 'primary.300' }}
                            onClick={doStart}
                            ml={3}
                        >
                            Si
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
