import React from 'react';
import { useParams } from 'react-router-dom';

import {
    Heading,
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from '@chakra-ui/react';

// tabs
import { Overview, Pets } from './tabs';

export const OwnerDetails = (): JSX.Element => {
    const { id } = useParams();

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'} >
                Propietario
            </Heading>
            <Tabs isLazy>
                <TabList>
                    <Tab color={'primary.500'}>Detalles</Tab>
                    <Tab color={'primary.500'}>Mascotas</Tab>
                </TabList>
                <TabIndicator
                    mt="-1.5px"
                    height="2px"
                    bg="'primary.500'"
                    borderRadius="1px"
                />
                <TabPanels>
                    <TabPanel>
                        <Overview ownerId={id} />
                    </TabPanel>
                    <TabPanel>
                        <Pets ownerId={id} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};
