import * as yup from 'yup';

export const searchOwnerSchema = yup.object({
    first_name: yup.string().label('first_name').notRequired(),
    last_name: yup.string().label('last_name').notRequired(),
    dni: yup.string().label('dni').notRequired(),
    name: yup.string().label('name').notRequired(),
});

export type searchOwnerSchemaType = yup.InferType<typeof searchOwnerSchema>
