import React from 'react';

import { RouteObject } from 'react-router-dom';
import { ILinkItem } from '../../interfaces';

import { FiUsers } from 'react-icons/fi';
import { BsClockHistory } from 'react-icons/bs';
import { GiCat } from 'react-icons/gi';

import {
    Account,
    AddPatient,
    ChangePassword,
    OwnerDetails,
    EditAccount,
    NotFound,
    Owners,
    SearchOwnerPage,
    PatientDetails,
    PatientToRoom,
    Patients,
    Waiting,
    InProgress,
    Finished,
    RecordDetails,
    SearchPatientPage,
    ReassignPatient,
    Calendar
} from '../../pages';

export const receptionistLinks: ILinkItem[] = [
    { name: 'Consultas',
        icon: BsClockHistory,
        childrens: [
            { name: 'Nueva', icon: FiUsers, path: '/' },
            { name: 'Calendario', icon: FiUsers, path: '/consultas/calendario' },
            { name: 'En Espera', icon: FiUsers, path: '/consultas/espera' },
            { name: 'En Progreso', icon: FiUsers, path: '/consultas/progreso' },
            { name: 'Finalizadas', icon: FiUsers, path: '/consultas/finalizadas' },
        ]
    },
    { name: 'Pacientes',
        icon: GiCat,
        childrens: [
            { name: 'Registar', icon: FiUsers, path: '/paciente/nuevo' },
            { name: 'Busqueda', path: '/buscar/paciente' },
            { name: 'Listar', icon: FiUsers, path: '/pacientes/lista' },
            { name: 'Reasignar', icon: FiUsers, path: '/reasignar' },
        ]
    },
    { name: 'Propietarios',
        icon: FiUsers,
        childrens: [
            { name: 'Busqueda', path: '/buscar/propietario' },
            { name: 'Listar', icon: FiUsers,  path: '/propietarios/lista' },
        ]
    },
];

export const receptionistRoutes: RouteObject[] = [
    {
        path: '/',
        element: <PatientToRoom />,
    },
    {
        path: '/consultas/calendario',
        element: <Calendar />,
    },
    {
        path: '/consultas/espera',
        element: <Waiting />,
    },
    {
        path: '/consultas/progreso',
        element: <InProgress />,
    },
    {
        path: '/consultas/finalizadas',
        element: <Finished />,
    },
    {
        path: '/buscar/paciente',
        element: <SearchPatientPage />,
    },
    {
        path: '/reasignar',
        element: <ReassignPatient />,
    },
    {
        path: '/pacientes/lista',
        element: <Patients />,
    },
    {
        path: '/paciente/:id',
        element: <PatientDetails />,
    },
    {
        path: '/historia/:id',
        element: <RecordDetails />,
    },
    {
        path: '/paciente/nuevo',
        element: <AddPatient />,
    },
    {
        path: '/buscar/propietario',
        element: <SearchOwnerPage />,
    },
    {
        path: '/propietarios/lista',
        element: <Owners />,
    },
    {
        path: '/propietario/:id',
        element: <OwnerDetails />,
    },
    {
        path: '/cuenta',
        element: <Account />,
    },
    {
        path: '/cuenta/actualizar',
        element: <EditAccount />,
    },
    {
        path: '/cambiar/contraseña',
        element: <ChangePassword />,
    },
    {
        path: '*',
        element: (
            <NotFound />
        ),
    },
];
