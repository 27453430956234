import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';

interface IButtomGroup {
    previewsTests: string;
    openTest: () => void;
    previewsVaccines: string;
    openVaccine: () => void;
}

export const ButtomGroup = (props: IButtomGroup): JSX.Element => {
    const {openTest, previewsTests, openVaccine, previewsVaccines } = props;

    return (
        <Box mt={2} mb={5}>
            <Flex alignItems={'center'}>
                <Button
                    variant={'link'}
                    color={'primary.500'}
                    fontWeight={'light'}
                    onClick={openTest}
                >
                    Registrar Examen:
                </Button>
                <Text mt={1} ml={1} fontSize={'sm'}>{previewsTests}</Text>
            </Flex>
            <Flex alignItems={'center'}>
                <Button
                    variant={'link'}
                    color={'primary.500'}
                    fontWeight={'light'}
                    onClick={openVaccine}
                >
                    Registrar Vacuna:
                </Button>
                <Text mt={1} ml={1} fontSize={'sm'}>{previewsVaccines}</Text>
            </Flex>
        </Box>
    );
};
