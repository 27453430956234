import React, { useEffect } from 'react';
import {
    Flex,
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    useToast,
    Box,
    Heading,
    Divider,
    Button,
    ModalFooter,
    Badge,
} from '@chakra-ui/react';

import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { getAppointmentStatus } from '../../tools';
import { useFetch } from '../../hooks';
import { IEvent } from '../../interfaces';
import { AppointmentStatusConstants } from '../../constants';

interface IEventModal {
    id: string;
    isOpen: boolean;
    isDoctor?: boolean;
    onClose: () => void;
}

export const EventModal = (props: IEventModal): JSX.Element => {
    const { id, isOpen, isDoctor, onClose } = props;

    const navigate = useNavigate();
    const toast = useToast();
    const { fetchData, loading, error, data} = useFetch<IEvent>(`/events/${id}`, undefined, false);

    useEffect(() => {
        if (id && !loading) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error al cargar datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    if(loading || !data) {
        return (
            <Flex alignItems={'center'} height={'lg'} flexDirection={'column'}>
                <Spinner
                    thickness='3px'
                    speed='0.65s'
                    emptyColor='primary.100'
                    color='primary.400'
                    size='xl'
                />
                <Text mt={2} size='sm' noOfLines={1} ml={'1'} mb={'5'} >
                    Cargando datos ...
                </Text>
            </Flex>
        );
    }

    const { description, title, startDate, type, doctor, appointment, pet } = data;

    const ButtonActions = (): JSX.Element => {
        return (
            <>
                <Button
                    variant={'outline'}
                    color={type.color}
                    onClick={() => navigate(`/paciente/${pet.id}`)}
                >
                    Ver Paciente
                </Button>
                {
                    isDoctor && appointment &&
                    appointment.status === AppointmentStatusConstants.IN_PROGRESS &&
                    <Button
                        ml={3}
                        variant={'outline'}
                        color={'secondary.500'}
                        onClick={() => navigate('/progreso')}
                    >
                        Ir a Consulta
                    </Button>
                }
            </>
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader bg={type.color}>
                    <Heading as='h1' size='lg' color={'white'}>
                        {type.name.toUpperCase()}
                    </Heading>
                </ModalHeader>
                <ModalCloseButton color={'white'} />
                <ModalBody>
                    <Box mt={2}>
                        <Flex justifyContent={'space-between'} alignItems={'center'}>
                            <Text>
                                <strong>Fecha:</strong>{' '}
                                {format(new Date(startDate), 'dd-LL-yyyy')}
                            </Text>
                            <Text>
                                <strong>Inicio:</strong>{' '}
                                {format(new Date(startDate), 'hh:mm b')}
                            </Text>
                        </Flex>
                        <Divider my={3} />
                        <Flex justifyContent={'flex-end'}>
                            {
                                appointment &&
                                <Badge bg={type.color} color={'white'}>
                                    {getAppointmentStatus(appointment.status)}
                                </Badge>
                            }
                        </Flex>
                        <Text>
                            <strong>Título:</strong>{' '}
                            {title}
                        </Text>
                        <Text mt={2}>
                            <strong>Descripción:</strong>{' '}
                            {description}
                        </Text>
                    </Box>
                    <Divider my={3} />
                    <Box>
                        <Text>
                            <strong>Médico:</strong>{' '}
                            {doctor.user.first_name} {doctor.user.last_name}
                        </Text>
                        <Text my={1}>
                            <strong>Paciente:</strong>{' '}
                            {pet.name}
                        </Text>
                        <Text>
                            <strong>Propietario:</strong>{' '}
                            {pet.owner.user.first_name} {pet.owner.user.last_name}
                        </Text>
                    </Box>
                    <Divider my={3} />
                </ModalBody>
                <ModalFooter>
                    {ButtonActions()}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
