export const getPreview = (names: string[], oldPreview: string): string => {
    if (names.length > 6) return oldPreview;

    let preview = '';
    for (let i = 0; i < names.length; i++) {
        if (i === 0) {
            preview += names[i];
        }
        if (i >= 1 && i <= 4) {
            preview += ', ' + names[i];
        }
        if (i === 5) {
            preview += ' ...';
        }
    }
    return preview;
};
