import React, { useEffect } from 'react';

// components
import { Box, FormControl, FormErrorMessage, FormLabel, Select, Stack, Textarea, useToast } from '@chakra-ui/react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { addSurgerySchemaType } from './surgery-schema';
import { ISurgery } from '../../../../interfaces';
import { useFetch } from '../../../../hooks';

interface IAddSurgeryForm {
    loading: boolean;
    register: UseFormRegister<addSurgerySchemaType>;
    formState: FormState<addSurgerySchemaType>;
}

export const AddSurgeryForm = (props: IAddSurgeryForm): JSX.Element => {
    const { loading, register, formState } = props;

    const toast = useToast();
    const { loading: loadingSurgeries, error, data } = useFetch<ISurgery[]>('/surgeries');

    useEffect(() => {
        if (!loadingSurgeries && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingSurgeries]);

    return (
        <Stack spacing='24px'>
            <Box>
                <FormControl mt={2} isInvalid={!!formState?.errors?.surgery_id?.message} isDisabled={loading}>
                    <FormLabel htmlFor="surgery" color="primary.600">Cirugía</FormLabel>
                    <Select
                        {...register('surgery_id', { required: true })}
                        focusBorderColor='primary.400'
                        bg={'white'}
                        variant={'outline'}
                    >
                        <option key={'blank'} value={0}>SELECCIONE CIRUGÍA</option>
                        {data?.map(surgery => {
                            return <option key={surgery.id} value={surgery.id} className={'capitalize'}>{surgery.name}</option>;
                        })}
                    </Select>
                    <FormErrorMessage>
                        {formState?.errors.surgery_id && formState?.errors.surgery_id.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formState?.errors?.description?.message} mt={2} isDisabled={loading}>
                    <FormLabel htmlFor="description" color="primary.600">Observaciones</FormLabel>
                    <Textarea
                        id="description"
                        focusBorderColor='primary.400'
                        {...register('description', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState?.errors.description && formState?.errors.description.message}
                    </FormErrorMessage>
                </FormControl>
            </Box>
        </Stack>
    );
};
