import * as yup from 'yup';

export const constantsSchema = yup.object({
    temperature:
        yup.number()
            .label('temperature')
            .positive('Debe ingresar un numero mayor a 0'),
    weight:
        yup.number()
            .label('weight')
            .positive('Debe ingresar un numero mayor a 0'),
    body_condition:
        yup.number()
            .label('body_condition')
            .positive('Debe ingresar un numero mayor a 0'),
    heart_rate:
        yup.number()
            .label('heart_rate')
            .positive('Debe ingresar un numero mayor a 0'),
    breathing_rate:
        yup.number()
            .label('breathing_rate')
            .positive('Debe ingresar un numero mayor a 0'),
});

export type constantsSchemaType = yup.InferType<typeof constantsSchema>
