import * as yup from 'yup';

export const searchPatientSchema = yup.object({
    //owner
    first_name: yup.string().label('first_name').notRequired(),
    last_name: yup.string().label('last_name').notRequired(),
    dni: yup.string().label('dni').notRequired(),

    // pet
    race_id: yup.number().label('race_id').notRequired(),
    specie_id: yup.number().label('race_id').notRequired(),
    name: yup.string().label('name').notRequired(),
    color: yup.string().label('color').notRequired(),
    sex_id: yup.number().label('sex_id').notRequired(),
});

export type searchPatientSchemaType = yup.InferType<typeof searchPatientSchema>
