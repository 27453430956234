import React, { useCallback } from 'react';
import {
    Box,
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Button,
    Flex,
    Image,
} from '@chakra-ui/react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import avatar from '../../../../../../assets/images/galenos_square.png';
import { format } from 'date-fns';

interface ITreatmentModal {
    isOpen: boolean;
    onClose: () => void;
    treatment: string;
    setTreatment: (treatment: string) => void;
}

export const TreatmentModal = (props: ITreatmentModal): JSX.Element => {
    const { isOpen, onClose, treatment, setTreatment } = props;

    const header = useCallback((): JSX.Element => {
        return (
            <Flex justifyContent={'space-between'} mt={8}>
                <Flex>
                    <Image
                        boxSize='125px'
                        src={avatar}
                        alt='Galenos Veterinaria'
                    />
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
                        <Text fontSize={'sm'}>Av. Píritu con calle Onoto, Qta La Gatera,</Text>
                        <Text fontSize={'sm'}>Sector Morro I, , Edo. Anzoátegui.</Text>
                        <Text fontSize={'sm'}>Teléfono: (0281) 282.06.58</Text>
                        <Text fontSize={'sm'}>E-mail: galenosclinicaveterinaria37@gmail.com</Text>
                        <Text fontSize={'sm'}>RIF: J-29536233-1</Text>
                        <Box borderWidth={3} borderColor={'primary.500'} mb={2} />
                    </Box>
                </Flex>
                <Text fontSize={'md'}>Fecha: {format(new Date(), 'dd/LL/yyyy')}</Text>
            </Flex>
        );
    }, []);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {header()}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <ReactQuill
                        style={{
                            height: 500
                        }}
                        theme="snow"
                        value={treatment}
                        onChange={setTreatment}
                    />
                </ModalBody>

                <ModalFooter>
                    <Button
                        onClick={onClose}
                        mt={5}
                        width={'full'}
                        bg={'primary.400'}
                        color={'white'}
                        _hover={{bg: 'primary.500'}}
                    >
                        Finalizar Redacción
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
