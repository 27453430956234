import React, { useEffect } from 'react';
import { IOwner, IOwnerReq, IPatient } from '../../../interfaces';
import { LabelStep } from './label-step';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ownerSchema, ownerSchemaType } from './owner-schema';
import { Box, Button, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Text, useToast } from '@chakra-ui/react';
import { usePost } from '../../../hooks';
import { useNavigate } from 'react-router-dom';

interface IThirdStep {
    ownerInfo?: {owner?: IOwner, dni: string};
    petId?: number;
}

interface IReassignReq {
    owner_id?: number;
    patient_id: number;
    owner: IOwnerReq;
}

export const ThirdStep = (props: IThirdStep): JSX.Element | null => {
    const { ownerInfo, petId } = props;

    const navigate = useNavigate();
    const toast = useToast();
    const { doRequest, loading, error, data } = usePost<IPatient, IReassignReq>('/patients/reassign');

    const { register, handleSubmit, formState, reset } = useForm<ownerSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(ownerSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            first_name: ownerInfo?.owner?.user?.first_name || '',
            last_name: ownerInfo?.owner?.user?.last_name || '',
            dni: ownerInfo?.owner?.user?.dni || ownerInfo?.dni,
            address: ownerInfo?.owner?.address || '',
            phone: ownerInfo?.owner?.phone || '',
            email: ownerInfo?.owner?.user?.email || '',
            occupation: ownerInfo?.owner?.occupation || '',
            housing: ownerInfo?.owner?.housing.toUpperCase() || '',
            other_pets: ownerInfo?.owner?.other_pets || false,
        }
    });

    useEffect(() => {
        reset({
            first_name: ownerInfo?.owner?.user?.first_name || '',
            last_name: ownerInfo?.owner?.user?.last_name || '',
            dni: ownerInfo?.owner?.user?.dni || ownerInfo?.dni,
            address: ownerInfo?.owner?.address || '',
            phone: ownerInfo?.owner?.phone || '',
            email: ownerInfo?.owner?.user?.email || '',
            occupation: ownerInfo?.owner?.occupation || '',
            housing: ownerInfo?.owner?.housing.toUpperCase() || '',
            other_pets: ownerInfo?.owner?.other_pets || false,
        });
    }, [ownerInfo]);

    useEffect(() => {
        if (!loading && data) {
            toast({
                description: 'Paciente reasignado exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
            navigate(`/propietario/${data.owner_id}`);
        }
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error reasignando paciente',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }

    }, [loading]);

    const onSubmit = (schema: ownerSchemaType): void => {
        if (petId) {
            doRequest({
                patient_id: petId,
                owner_id: ownerInfo?.owner?.id,
                owner: {
                    first_name: schema.first_name,
                    last_name: schema.last_name,
                    dni: schema.dni,
                    address: schema.address,
                    phone: schema.phone,
                    email: schema.email,
                    occupation: schema.occupation,
                    housing: schema.housing.toUpperCase(),
                    other_pets: schema.other_pets || false,
                }
            });
        }
    };

    if (ownerInfo && petId) {
        const isReadOnly= Boolean(ownerInfo?.owner?.id);

        return (
            <>
                <LabelStep
                    step={3}
                    label={'Reasignar paciente a propietario'}
                />
                <Box
                    py={{ base: '0', sm: '8' }}
                    px={{ base: '4', sm: '10' }}
                    bg={'white'}
                    borderRadius={{ base: 'none', sm: 'xl' }}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box mb={2}>
                            <Text noOfLines={1} fontSize={'xl'} fontWeight={'bold'}>Datos Propietario</Text>
                        </Box>
                        <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                            <FormControl isInvalid={!!formState?.errors?.first_name?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                                <FormLabel htmlFor="email" color="primary.600">Nombre</FormLabel>
                                <Input
                                    id="first_name"
                                    type="text"
                                    placeholder={'Pedro'}
                                    focusBorderColor='primary.400'
                                    {...register('first_name', { required: true })}
                                />
                                <FormErrorMessage>
                                    {formState?.errors.first_name && formState?.errors.first_name.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl ml={{base: 'none', md: 2}} isInvalid={!!formState?.errors?.last_name?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                                <FormLabel htmlFor="last_name" color="primary.600">Apellido</FormLabel>
                                <Input
                                    id="last_name"
                                    type="text"
                                    placeholder={'Perez'}
                                    focusBorderColor='primary.400'
                                    {...register('last_name', { required: true })}
                                />
                                <FormErrorMessage>
                                    {formState?.errors.last_name && formState?.errors.last_name.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Flex>
                        <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                            <FormControl isInvalid={!!formState?.errors?.dni?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                                <FormLabel htmlFor="dni" color="primary.600">C.I</FormLabel>
                                <Input
                                    id="dni"
                                    type="number"
                                    placeholder={'12345678'}
                                    focusBorderColor='primary.400'
                                    {...register('dni', { required: true })}
                                />
                                <FormErrorMessage>
                                    {formState?.errors.dni && formState?.errors.dni.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl mx={{base: 'none', md: 2}} isInvalid={!!formState?.errors?.phone?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                                <FormLabel htmlFor="phone" color="primary.600">Teléfono</FormLabel>
                                <Input
                                    id="phone"
                                    type="text"
                                    placeholder={'424-123-4567'}
                                    focusBorderColor='primary.400'
                                    {...register('phone', { required: true })}
                                />
                                <FormErrorMessage>
                                    {formState?.errors.phone && formState?.errors.phone.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl mx={{base: 'none', md: 2}} isInvalid={!!formState?.errors?.email?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                                <FormLabel htmlFor="email" color="primary.600">Correo</FormLabel>
                                <Input
                                    id="email"
                                    type="email"
                                    placeholder={'pedro@mail.com'}
                                    focusBorderColor='primary.400'
                                    {...register('email', { required: true })}
                                />
                                <FormErrorMessage>
                                    {formState?.errors.email && formState?.errors.email.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Flex>
                        <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                            <FormControl isInvalid={!!formState?.errors?.address?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                                <FormLabel htmlFor="address" color="primary.600">Dirección</FormLabel>
                                <Input
                                    id="address"
                                    type="text"
                                    placeholder={'Lecheria'}
                                    focusBorderColor='primary.400'
                                    {...register('address', { required: true })}
                                />
                                <FormErrorMessage>
                                    {formState?.errors.address && formState?.errors.address.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Flex>
                        <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                            <FormControl isInvalid={!!formState?.errors?.housing?.message} mt={2}>
                                <FormLabel htmlFor="housing" color="primary.600">Tipo de Vivienda</FormLabel>
                                <Select
                                    id="housing"
                                    {...register('housing', { required: true })}
                                    focusBorderColor='primary.400'
                                    bg={'white'}
                                    variant={'outline'}
                                >
                                    <option key={'CASA'} value={'CASA'}>CASA</option>
                                    <option key={'APARTAMENTO'} value={'APARTAMENTO'}>APARTAMENTO</option>
                                </Select>
                                <FormErrorMessage>
                                    {formState?.errors.housing && formState?.errors.housing.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl mx={{base: 'none', md: 2}} isInvalid={!!formState?.errors?.occupation?.message} mt={2} isReadOnly={isReadOnly} isDisabled={loading}>
                                <FormLabel htmlFor="occupation" color="primary.600">Ocupación</FormLabel>
                                <Input
                                    id="occupation"
                                    type="text"
                                    placeholder={'Contador'}
                                    focusBorderColor='primary.400'
                                    {...register('occupation', { required: true })}
                                />
                                <FormErrorMessage>
                                    {formState?.errors.occupation && formState?.errors.occupation.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl mt={2}>
                                <FormLabel htmlFor="other_pets" color="primary.600" mb={4}>¿Posee más de una mascota?</FormLabel>
                                <Checkbox id="other_pets" {...register('other_pets')} isReadOnly={isReadOnly} isDisabled={loading} mx={1}>Si</Checkbox>
                                <FormErrorMessage>
                                    {formState?.errors.other_pets && formState?.errors.other_pets.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Flex>
                        <Flex justifyContent={'flex-end'}>
                            <Button
                                type='submit'
                                mt={5}
                                isDisabled={loading}
                                isLoading={loading}
                                bg={'primary.400'}
                                color={'white'}
                                _hover={{bg: 'primary.500'}}
                            >
                            Reasignar
                            </Button>
                        </Flex>
                    </form>
                </Box>
            </>
        );
    }

    return null;
};
