import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAppointmentReducer } from './IAppointmentReducer';
import { currentAppointment, getAppointments } from './AppointmentThunks';
import { IAppointment, IPaginateResponse } from '../../interfaces';

const initialState: IAppointmentReducer = {
    appointments: {
        items: [],
        totalItems: 0,
        currentPage: 1,
        resultsPerPage: 10,
        numberOfPages: 1,
    },
    appointment: undefined,
    loading: false,
    success: undefined,
    error: undefined
};

export const appointmentSlice = createSlice({
    name: 'appointment',
    initialState,
    reducers: {},
    extraReducers: builder => {
        // list
        builder.addCase(getAppointments.pending, (state: IAppointmentReducer) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(getAppointments.fulfilled, (state: IAppointmentReducer, { payload }: PayloadAction<IPaginateResponse<IAppointment>>) => {
            state.appointments = payload;
            state.loading = false;
        });
        builder.addCase(getAppointments.rejected, (state: IAppointmentReducer, action) => {
            state.loading = false;
            state.error = {
                ...action.error,
                message: action.error.message || 'Ha ocurrido un error'
            };
        });
        // current
        builder.addCase(currentAppointment.pending, (state: IAppointmentReducer) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(currentAppointment.fulfilled, (state: IAppointmentReducer, { payload }: PayloadAction<IAppointment>) => {
            state.appointment = payload;
            state.loading = false;
        });
        builder.addCase(currentAppointment.rejected, (state: IAppointmentReducer, action) => {
            state.loading = false;
            state.error = {
                ...action.error,
                message: action.error.message || 'Ha ocurrido un error'
            };
        });
    }
});
