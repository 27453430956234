import React, { useCallback, useState } from 'react';
import { Avatar, Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { intervalToDuration } from 'date-fns';
import { ChartModal } from '../../../../../components';

interface IHeader {
    // owner
    ownerName: string;
    email: string;
    phone: string;
    // pet
    petId: number;
    petName: string;
    specie: string;
    race: string;
    birth: string;
    color: string;
    sex: string;
    neutered: string;
    chip_id: string;
    pathology: string
    avatar: string;
}

export const Header = (props: IHeader): JSX.Element => {
    const { ownerName, email, phone } = props;
    const { petId, petName, specie, race, birth, color, sex, neutered, chip_id, pathology, avatar } = props;

    const [chartModal, setChartModal] = useState<boolean>(false);

    const { years, months, days } = intervalToDuration({ start: new Date(birth), end: new Date()});

    const getage = useCallback((): string => {
        const d: number = days || 0;
        const m: number = months || 0;
        const y: number = years || 0;

        if (!y && !m && d) {
            return `${d} dia(s)`;
        }

        if (!y && m && d) {
            return `${m} mes(es)`;
        }

        if (y && m) {
            return `${y} año(s) ${m} mes(es)`;
        }

        if (y && !m) {
            return `${y} año(s)`;
        }
        return '';
    }, []);

    const openChart = (): void => {
        setChartModal(true);
    };

    const closeChart = (): void => {
        setChartModal(false);
    };

    return (
        <>
            <Flex marginTop={2}>
                <Avatar
                    src={avatar}
                    width='95px'
                    height='95px'
                    padding={'0.5'}
                    marginRight={2}
                    borderRadius='15px'
                    backgroundColor={'primary.50'}
                />
                <Box>
                    <Heading size='md' marginBottom={2} textTransform={'uppercase'}>
                        Paciente
                    </Heading>
                    <Flex>
                        <Box marginRight={10}>
                            <Text fontSize={'sm'} textTransform={'uppercase'}><strong>Nombre:</strong> {petName}</Text>
                            <Text fontSize={'sm'} textTransform={'uppercase'}><strong>Especie:</strong> {specie}</Text>
                            <Text fontSize={'sm'} textTransform={'uppercase'}><strong>Raza:</strong> {race}</Text>
                            <Text fontSize={'sm'} textTransform={'uppercase'}><strong>Edad:</strong> {getage()}</Text>
                            <Text fontSize={'sm'} textTransform={'uppercase'}><strong>Color:</strong> {color}</Text>
                        </Box>
                        <Box marginRight={12}>
                            <Text fontSize={'sm'} textTransform={'uppercase'}><strong>Sexo:</strong> {sex}</Text>
                            <Text fontSize={'sm'} textTransform={'uppercase'}><strong>Esterilizado:</strong> {neutered}</Text>
                            <Text fontSize={'sm'} textTransform={'uppercase'}><strong>No. Chip:</strong> {chip_id}</Text>
                            <Text fontSize={'sm'} textTransform={'uppercase'}><strong>PATOLOGÍA:</strong> {pathology}</Text>
                            <Button
                                textTransform={'uppercase'}
                                variant={'link'}
                                color={'primary.500'}
                                fontSize={'sm'}
                                onClick={openChart}
                            >
                                Gráfica de Peso
                            </Button>
                        </Box>
                    </Flex>
                </Box>
                <Box marginLeft={20}>
                    <Heading size='md' marginBottom={2} textTransform={'uppercase'}>
                        Propietario
                    </Heading>
                    <Text fontSize={'sm'} textTransform={'uppercase'}><strong>Nombre:</strong> {ownerName}</Text>
                    <Text fontSize={'sm'} textTransform={'uppercase'}><strong>Correo:</strong> {email}</Text>
                    <Text fontSize={'sm'} textTransform={'uppercase'}><strong>TELÉFONO:</strong> {phone}</Text>
                </Box>
            </Flex>
            {
                chartModal &&
                <ChartModal
                    petId={petId}
                    isOpen={true}
                    onClose={closeChart}
                />
            }
        </>
    );
};
