import React, { ChangeEvent, useEffect, useRef } from 'react';

// components
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    useToast,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFetch, usePost } from '../../../../hooks';
import { ITests } from '../../../../interfaces';
import { AttachmentIcon } from '@chakra-ui/icons';
import { testRecordSchema, testRecordSchemaType } from './test-record-schema';

interface ITestModal {
    isOpen: boolean;
    patientId: number;
    reviewId: number;
    onAdd: () => void;
    onClose: () => void;
}

export const AddTestModal = (props: ITestModal): JSX.Element => {
    const { isOpen, reviewId, patientId, onAdd, onClose } = props;

    const formData = new FormData();
    const imageRef = useRef<HTMLInputElement>(null);

    const toast = useToast();
    const { loading, error, data } = useFetch<ITests[]>('/tests');
    const { doRequest, loading: loadingAddTest, error: errorAddTest, data: testAdded } = usePost(`/tests/add/review/${reviewId}/patient/${patientId}`, {headers: {'Content-Type': 'multipart/form-data'}});

    const { register, handleSubmit, formState: { errors } } = useForm<testRecordSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(testRecordSchema),
        criteriaMode: 'firstError',
        defaultValues: {}
    });

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!loadingAddTest && Boolean(errorAddTest)) {
            toast({
                description: error?.message || 'Ha ocurrido un error registrando vacuna',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingAddTest]);

    useEffect(() => {
        if (!loadingAddTest && Boolean(testAdded)) {
            onAdd();
            onClose();
        }
    }, [loadingAddTest]);

    const onClickItem = (): void => {
        imageRef.current?.click();
    };

    const onChange = (file: ChangeEvent): void => {
        const { files } = file.target as HTMLInputElement;
        if (files && files.length !== 0) {

            const fileSize = files[0].size;
            const fileMB = fileSize / 1000000;
            if (fileMB > 4) {
                alert('Por favor, seleccione un archivo con peso menor a 4MB.');
                if(imageRef.current) {
                    imageRef.current.value = '';
                }
                return;
            }

            formData.append('file', files[0]);
        }
    };

    const onSubmit = (schema: testRecordSchemaType): void => {
        if (schema.id) {
            formData.append('id', String(schema.id));
            doRequest(formData);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Registrar Exámen</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl isInvalid={!!errors?.id?.message} isDisabled={loading}>
                            <FormLabel htmlFor="id" color="primary.600">Examenes</FormLabel>
                            <Flex>
                                <Select
                                    {...register('id', { required: true })}
                                    focusBorderColor='primary.400'
                                    bg={'white'}
                                    variant={'outline'}
                                >
                                    <option key={'blank'}>SELECCIONE EXAMEN</option>
                                    {data?.map(test => {
                                        return <option key={test.id} value={test.id} className={'capitalize'}>{test.name}</option>;
                                    })}
                                </Select>
                                <input
                                    type='file'
                                    accept="image/png, image/jpeg"
                                    onChange={onChange}
                                    ref={imageRef}
                                    style={{ display: 'none' }}
                                />
                                <Button
                                    ml={3}
                                    onClick={onClickItem}
                                >
                                    <AttachmentIcon
                                        color={'secondary.500'}
                                    />
                                </Button>
                            </Flex>
                            <FormErrorMessage>
                                {errors.id && errors.id.message}
                            </FormErrorMessage>
                        </FormControl>
                        <Button
                            type='submit'
                            mt={5}
                            width={'full'}
                            bg={'primary.400'}
                            color={'white'}
                            _hover={{bg: 'primary.500'}}
                        >
                                Registrar
                        </Button>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
