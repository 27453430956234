import * as yup from 'yup';

export const eventSchema = yup.object({
    type_id: yup.number().label('type_id').required('Este campo es requerido'),
    title: yup.string().label('title').required('Este campo es requerido'),
    startDate: yup.string().label('startDate').required('Este campo es requerido'),
    description: yup.string().label('description').required('Este campo es requerido'),
});

export type eventSchemaType = yup.InferType<typeof eventSchema>
