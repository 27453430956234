import React, { useEffect } from 'react';
import { Box, Flex, FormControl, FormErrorMessage, Grid, Input, useToast, Text, Button, Spinner } from '@chakra-ui/react';

import { useAppSelector } from '../../../../state/hooks';
import { useFetch } from '../../../../hooks';
import { StatisticsCard } from '../../../../components';
import { useForm } from 'react-hook-form';
import { overviewSchema, overviewSchemaType } from './overview-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';

interface IOverview {
    finished: number;
    in_progress: number;
    waiting: number;
}

export const Overview = (): JSX.Element => {
    const { doctor } = useAppSelector(state => state.doctor);
    const toast = useToast();

    const { loading, error, data, fetchData } = useFetch<IOverview>(`/doctors/stats/${doctor?.id}`, undefined, false);

    useEffect(() => {
        if(doctor) {
            fetchData();
        }
    }, [doctor?.id]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const { register, handleSubmit, formState: { errors } } = useForm<overviewSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(overviewSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            startDate: format(new Date(), 'yyyy-LL-dd'),
            endDate: format(new Date(), 'yyyy-LL-dd'), // '2023-06-11'
        }
    });

    const onSubmit = (schema: overviewSchemaType): void => {
        if (doctor) {
            const { startDate, endDate } = schema;
            fetchData(`/doctors/stats/${doctor?.id}?startDate=${startDate}&endDate=${endDate}`);
        }
    };

    if(loading || !data) {
        return (
            <Flex alignItems={'center'} height={'lg'} flexDirection={'column'}>
                <Spinner
                    thickness='3px'
                    speed='0.65s'
                    emptyColor='primary.100'
                    color='primary.400'
                    size='xl'
                />
                <Text mt={2} size='sm' noOfLines={1} ml={'1'} mb={'5'} >
                    Cargando datos ...
                </Text>
            </Flex>
        );
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box bg={'white'} px={3} py={5} borderRadius={'lg'} mb={3}>
                    <Text mx={{base: 'none', md: 2}} mb={2} fontWeight={'bold'}>
                        Buscar por rango de fechas
                    </Text>
                    <Flex justifyContent={'flex-start'} alignItems={'center'}>
                        <FormControl maxWidth={300} mx={{base: 'none', md: 2}} isDisabled={loading} isInvalid={!!errors?.startDate?.message}>
                            <Input id="startDate"
                                type="date"
                                focusBorderColor='primary.400'
                                {...register('startDate', { required: true })}
                            />
                            <FormErrorMessage>
                                {errors.startDate && errors.startDate.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl maxWidth={300} mx={{base: 'none', md: 2}} isDisabled={loading} isInvalid={!!errors?.endDate?.message}>
                            <Input id="endDate"
                                type="date"
                                focusBorderColor='primary.400'
                                {...register('endDate', { required: true })}
                            />
                            <FormErrorMessage>
                                {errors.endDate && errors.endDate.message}
                            </FormErrorMessage>
                        </FormControl>
                        <Button
                            type='submit'
                            isDisabled={loading}
                            isLoading={loading}
                            bg={'primary.400'}
                            color={'white'}
                            _hover={{bg: 'primary.500'}}
                        >
                            Buscar
                        </Button>
                    </Flex>
                </Box>
            </form>
            <Flex direction='column'>
                <Grid templateColumns={{ sm: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }} gap='22px'>
                    <StatisticsCard
                        title='Sala de Espera'
                        description='Cantidad de pacientes en esperando por usted'
                        statistic={data?.waiting || 0}
                    />
                    <StatisticsCard
                        title='Consultas en Progreso'
                        description='Cantidad de consultas en progreso'
                        statistic={data?.in_progress || 0}
                    />
                    <StatisticsCard
                        title='Consultas Finalizadas'
                        description='Consultas finalizadas por usted'
                        statistic={data?.finished || 0}
                    />
                </Grid>
            </Flex>
        </>
    );
};
