import React, { useEffect, useState } from 'react';
import { useFetch, usePut } from '../../../../hooks';
import { Flex, Spinner, useToast, Text, Button, Box } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import { TREATMENT_TEMPLATE } from '../../../../tools';
import { useNavigate } from 'react-router-dom';

interface ITreatment {
    recordId?: string;
}

interface ITreatmentRes {
    createdAt: string;
    id: number;
    record_id: number;
    treatment: string;
    updatedAt: string;
}

export const Treatment = (props: ITreatment) => {
    const { recordId } = props;
    const navigate = useNavigate();
    const toast = useToast();

    const [treatment, setTreatment] = useState<string>(TREATMENT_TEMPLATE);

    const { fetchData, loading, error, data } = useFetch<ITreatmentRes>(`/records/${recordId}/treatment`, undefined, false);
    const { doUpdate, loading: treatmentLoading, error: treatmentError, data: treatmentUpdated } = usePut<ITreatmentRes, {treatment: string}>(`/records/treatment/${data?.id}`);

    useEffect(() => {
        if (recordId) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (!loading && data) {
            setTreatment(data.treatment);
        }
    }, [loading]);


    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!treatmentLoading && Boolean(treatmentError)) {
            toast({
                description: error?.message || 'Ha ocurrido un error actualizando el recipe',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [treatmentLoading]);

    useEffect(() => {
        if (!treatmentLoading && Boolean(treatmentUpdated)) {
            toast({
                description: 'Consulta finalizada exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
            navigate(`/recipe/${recordId}`);
        }
    }, [treatmentLoading]);

    const onDoUpdate = (): void => {
        if (data?.id) {
            doUpdate({
                treatment
            });
        }
    };

    if(loading) {
        return (
            <Flex alignItems={'center'} height={'lg'} flexDirection={'column'}>
                <Spinner
                    thickness='3px'
                    speed='0.65s'
                    emptyColor='primary.100'
                    color='primary.400'
                    size='xl'
                />
                <Text mt={2} size='sm' noOfLines={1} ml={'1'} mb={'5'} >
                    Cargando datos ...
                </Text>
            </Flex>
        );
    }

    return (
        <Box
            py={{ base: '0', sm: '8' }}
            px={{ base: '4', sm: '10' }}
            bg={'white'}
            borderRadius={{ base: 'none', sm: 'xl' }}
        >
            <ReactQuill
                theme="snow"
                value={treatment}
                onChange={setTreatment}
            />
            <Button
                onClick={onDoUpdate}
                mt={5}
                width={'full'}
                bg={'primary.400'}
                color={'white'}
                _hover={{bg: 'primary.500'}}
            >
                Actualizar
            </Button>
        </Box>
    );
};
