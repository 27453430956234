import React, { useEffect, useRef } from 'react';
import { AlertDialog, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useToast } from '@chakra-ui/react';
import { usePut } from '../../../../hooks';
import { IAppointment  } from '../../../../interfaces';
import { IAppointmentReq } from '../../../../interfaces/network/req/IAppointmentReq';

interface IReassignToDoctorModal {
    isOpen: boolean;
    onClose: () => void;
    patientId: number;
    appointmentId: number;
    doGetAppointments: () => void;
}

export const NoShowModal = (props: IReassignToDoctorModal): JSX.Element => {
    const toast = useToast();
    const { isOpen, patientId, appointmentId } = props;
    const { onClose, doGetAppointments } = props;
    const cancelRef = useRef<HTMLButtonElement>(null);

    const { doUpdate, loading: noShowLoading, error: noShowError, data } = usePut<IAppointment, IAppointmentReq>(`/appointments/no-show-up/${appointmentId}`);

    useEffect(() => {
        if (!noShowLoading && data) {
            toast({
                description: 'Consulta actualizada exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
            onClose();
            doGetAppointments();
        }
    }, [noShowLoading]);

    useEffect(() => {
        if (!noShowLoading && Boolean(noShowError)) {
            toast({
                description: noShowError?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [noShowLoading]);

    const doNoShowUp = (): void => {
        doUpdate({ patient_id: patientId });
    };

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        ¿Desea remover este paciente de la sala de espera?
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            No
                        </Button>
                        <Button
                            color={'white'}
                            backgroundColor={'primary.300'}
                            _hover={{ backgroundColor: 'primary.300' }}
                            onClick={doNoShowUp}
                            ml={3}
                        >
                            Si
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
