import React from 'react';
import { FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { recordSchemaType } from './record-schema';

interface ISummaryForm {
    loading: boolean;
    register: UseFormRegister<recordSchemaType>;
    formState: FormState<recordSchemaType>;
}

export const SummaryForm = (props: ISummaryForm): JSX.Element => {
    const { loading, register, formState } = props;
    const { errors } = formState;

    const placeholder = 'Ej.\n\n1 Consulta\n1 Hematología\n2 Inyecciones\n1 ECO';

    return (
        <FormControl isInvalid={!!errors?.summary?.message} mt={2} isDisabled={loading}>
            <FormLabel htmlFor="summary" color="primary.600">¿Qué tipo de procedimientos realizo en esta consulta?</FormLabel>
            <Textarea
                minH={170}
                id="summary"
                focusBorderColor='primary.400'
                placeholder={placeholder}
                {...register('summary', { required: true })}
            />
            <FormErrorMessage>
                {errors.summary && errors.summary.message}
            </FormErrorMessage>
        </FormControl>
    );
};
