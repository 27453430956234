import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Heading, Text, useToast } from '@chakra-ui/react';
import { CellProps, Column } from 'react-table';
import { IOwner, IPaginateResponse } from '../../../../interfaces';
import { encodingToQuery } from '../../../../tools';
import { useFetch } from '../../../../hooks';
import { useNavigate } from 'react-router-dom';
import { DataTable } from '../../../../components';
import { FormSearch, searchOwnerSchemaType } from './form-search';

export const SearchOwnerPage = (): JSX.Element => {
    const [params, setParams] = useState<searchOwnerSchemaType>();
    const [page, setPage] = useState<number>(1);

    const navigate = useNavigate();
    const toast = useToast();

    const { fetchData, loading, error, data } = useFetch<IPaginateResponse<IOwner>>('/owners', undefined, false);

    const onSearchOwner = (schema: searchOwnerSchemaType): void => {
        setParams(schema);
    };

    useEffect(() => {
        if (params && !loading) {
            fetchData(`/owners?${encodingToQuery({page, ...params})}`);
        }
    }, [params, page]);


    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error al cargar mascotas',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }

    }, [loading]);

    const columns = useMemo<Column<IOwner>[]>(() => [
        {
            id: 'first_name',
            Header: 'Nombre',
            accessor: 'user',
            Cell: ({ value }: CellProps<IOwner>) => {
                const { first_name } = value;
                return (
                    <Text textTransform={'uppercase'}>{first_name}</Text>
                );
            },
        },
        {
            id: 'last_name',
            Header: 'Apellido',
            accessor: 'user',
            Cell: ({ value }: CellProps<IOwner>) => {
                const { last_name } = value;
                return (
                    <Text textTransform={'uppercase'}>{last_name}</Text>
                );
            },
        },
        {
            id: 'dni',
            Header: 'C.I',
            accessor: 'user',
            Cell: ({ value }: CellProps<IOwner>) => {
                const { dni } = value;
                return (
                    <Text textTransform={'uppercase'}>{new Intl.NumberFormat('de-DE').format(Number(dni))}</Text>
                );
            },
        },
        {
            id: 'phone',
            Header: 'Teléfono',
            accessor: 'phone',
            Cell: ({ value }: CellProps<IOwner>) => {
                return (
                    <Text textTransform={'uppercase'}>{value}</Text>
                );
            },
        },
        {
            id: 'email',
            Header: 'Correo',
            accessor: 'user',
            Cell: ({ value }: CellProps<IOwner>) => {
                const { email } = value;
                return (
                    <Text textTransform={'uppercase'}>{email}</Text>
                );
            },
        },
        {
            id: 'details',
            Header: 'Detalles',
            accessor: 'id',
            Cell: ({ value }: CellProps<IOwner>) => {
                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => navigate(`/propietario/${value}`)}>
                        Ver
                    </Button>
                );
            },
            disableSortBy: true,
        },
    ],  []);

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'} >
                Buscar Propietario
            </Heading>
            <FormSearch getData={onSearchOwner} loading={loading} />
            {
                data && data.items.length > 0 &&
                <Box mt={5}>
                    <DataTable
                        columns={columns}
                        data={data.items || []}
                        loading={loading}
                        numberOfPage={data?.numberOfPages || 1}
                        currentPage={data?.currentPage || 1}
                        handlePagination={page => setPage(page)}
                        hideSearch={true}
                    />
                </Box>
            }
        </>
    );
};
