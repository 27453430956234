// eslint-disable-next-line @typescript-eslint/no-explicit-any
const treatObject = (item: { [key: string]: any } | any[], prefix: string): string => {
    if (Array.isArray(item)) {
        return item
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((value: any, index: number) =>
                typeof value === 'object' ? treatObject(value, `${encodeURIComponent(prefix || '')}[${index}]`) : `${encodeURIComponent(prefix || '')}[${index}]=${encodeURIComponent(value)}`
            )
            .join('&');
    } else {
        return Object.keys(item)
            .map((key: string) =>
                typeof item[key] === 'object'
                    ? treatObject(item[key], `${encodeURIComponent(prefix || '')}[${encodeURIComponent(key)}]`)
                    : `${encodeURIComponent(prefix || '')}[${encodeURIComponent(key)}]=${encodeURIComponent(item[key])}`
            )
            .join('&');
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encodingToQuery = <T extends { [key: string]: any }>(req: T): string => {
    const query = Object.keys(req).map((key: string) => {
        if (typeof req[key] === 'undefined' || req[key] === '') {
            return;
        }
        return typeof req[key] === 'object' ? treatObject(req[key], key) : `${encodeURIComponent(key)}=${encodeURIComponent(req[key])}`;
    });
    return query.filter(item => item !== undefined && item !== '').join('&');
};
