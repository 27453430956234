import React, { useRef } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';

interface ISurgeryObservationsModal {
    isOpen: boolean;
    onClose: () => void;
    observations?: string;
}

export const SurgeryObservationsModal = (props: ISurgeryObservationsModal): JSX.Element => {
    const { isOpen, onClose, observations } = props;
    const finalRef = useRef<HTMLButtonElement>(null);

    return (
        <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Observaciones de la cirugia</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {observations ? observations : 'Esta cirugia no posee observaciones'}
                </ModalBody>

                <ModalFooter>
                    <Button bg={'secondary.400'} color={'white'} _hover={{bg: 'secondary.500'}} mr={3} onClick={onClose}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
