import React, { useEffect, useState } from 'react';

// components
import { Box, FormControl, FormErrorMessage, FormLabel, Input, Select, Stack } from '@chakra-ui/react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { userSchemaType } from './user-schema';
import { useFetch } from '../../../hooks';
import { IRole } from '../../../interfaces';
import { userType } from '../../../tools';
import { ProfileConstants } from '../../../constants';

interface IUserForm {
    loading: boolean;
    register: UseFormRegister<userSchemaType>;
    formState: FormState<userSchemaType>;
}

export const UserForm = (props: IUserForm): JSX.Element => {
    const { loading, register, formState } = props;

    const { data } = useFetch<IRole[]>('/roles');
    const [roles, setRoles] = useState(data ?? []);


    useEffect(() => {
        if (data) {
            const filteredRoles = data.filter(role => {
                if (role.id !== ProfileConstants.OWNER) {
                    return role;
                }
            });
            setRoles(filteredRoles);
        }
    }, [data]);


    return (
        <Stack spacing='24px'>
            <Box>
                <FormControl  isInvalid={!!formState?.errors?.first_name?.message} mt={2}>
                    <FormLabel htmlFor='nombre'>Nombre</FormLabel>
                    <Input
                        id='nombre'
                        type='text'
                        placeholder='Pedro'
                        focusBorderColor='primary.400'
                        bg={'white'}
                        disabled={loading}
                        {...register('first_name', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState.errors.first_name && formState.errors.first_name.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl  isInvalid={!!formState?.errors?.last_name?.message} mt={2}>
                    <FormLabel htmlFor='apellido'>Apellido</FormLabel>
                    <Input
                        id='apellido'
                        type='text'
                        placeholder='Perez'
                        focusBorderColor='primary.400'
                        bg={'white'}
                        disabled={loading}
                        {...register('last_name', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState.errors.last_name && formState.errors.last_name.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl  isInvalid={!!formState?.errors?.dni?.message} mt={2}>
                    <FormLabel htmlFor='dni'>No. Identificación</FormLabel>
                    <Input
                        id='dni'
                        type='text'
                        placeholder='12345678'
                        focusBorderColor='primary.400'
                        bg={'white'}
                        disabled={loading}
                        {...register('dni', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState.errors.dni && formState.errors.dni.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formState?.errors?.first_name?.message} mt={2}>
                    <FormLabel htmlFor='email'>Correo</FormLabel>
                    <Input
                        id='email'
                        type='email'
                        placeholder='pedro@mail.com'
                        focusBorderColor='primary.400'
                        bg={'white'}
                        disabled={loading}
                        {...register('email', { required: true })}
                    />
                    <FormErrorMessage>
                        {formState.errors.first_name && formState.errors.first_name.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formState?.errors?.first_name?.message} mt={2}>
                    <FormLabel htmlFor="role_id" color="primary.600">Tipo de Usuario</FormLabel>
                    <Select
                        id='role_id'
                        focusBorderColor='primary.400'
                        bg={'white'}
                        disabled={loading}
                        {...register('role_id', { required: true })}
                    >
                        <option key={'blank'} value={0}>SELECCIONE TIPO</option>
                        {roles?.map(role => {
                            return <option key={role.id} value={role.id} className={'capitalize'}>{userType(role.id)}</option>;
                        })}
                    </Select>
                    <FormErrorMessage>
                        {formState.errors.role_id && formState.errors.role_id.message}
                    </FormErrorMessage>
                </FormControl>
            </Box>
        </Stack>
    );
};
