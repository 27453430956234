import React from 'react';

import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
} from '@chakra-ui/react';
import { EventForm } from './event-form';

interface IEventDrawer {
    isOpen: boolean;
    onClose: () => void;
    petId?: string;
    refreshEvents: () => void;
}

export const EventDrawer = (props: IEventDrawer): JSX.Element => {
    const { isOpen, onClose, petId, refreshEvents } = props;

    return (
        <Drawer onClose={onClose} isOpen={isOpen} size={'md'}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Registrar Evento</DrawerHeader>
                <DrawerBody>
                    <EventForm petId={petId} refreshEvents={refreshEvents}/>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
