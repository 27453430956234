import React, { useEffect } from 'react';

import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Spinner,
    Text,
    Textarea,
    useToast
} from '@chakra-ui/react';


import { useFetch, usePut } from '../../../../hooks';
import { IRecord, IRecordReq } from '../../../../interfaces';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { recordSchema, recordSchemaType } from './record-schema';
import { format } from 'date-fns';

interface IOverview {
    recordId?: string;
}

export const Overview = (props: IOverview): JSX.Element => {
    const { recordId } = props;

    const toast = useToast();
    const { fetchData, loading, error, data} = useFetch<IRecord>(`/records/${Number(recordId)}`, undefined, false);
    const { doUpdate, loading: loadingUpdate, error: errorUpdate, data: record } = usePut<IRecord, IRecordReq>(`/records/${recordId}`);

    const { register, handleSubmit, formState: { errors, isDirty }, reset } = useForm<recordSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(recordSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            reason: data?.reason || '',
            revelevant_clinic: data?.revelevant_clinic || '',
            diagnosis: data?.diagnosis || '',
        }
    });

    useEffect(() => {
        if (recordId && !loading) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error al cargar datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if (data) {
            reset({
                reason: data.reason,
                revelevant_clinic: data.revelevant_clinic,
                diagnosis: data.diagnosis,
            });
        }
    }, [data]);

    useEffect(() => {
        if (!loadingUpdate && Boolean(errorUpdate)) {
            toast({
                description: errorUpdate?.message || 'Error actualizando datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingUpdate]);

    useEffect(() => {
        if(!loadingUpdate && record) {
            fetchData();
            toast({
                description: 'Datos actualizados exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingUpdate]);

    const onSubmit = (schema: recordSchemaType): void => {
        doUpdate(schema);
    };

    if(loading || !data) {
        return (
            <Flex alignItems={'center'} height={'lg'} flexDirection={'column'}>
                <Spinner
                    thickness='3px'
                    speed='0.65s'
                    emptyColor='primary.100'
                    color='primary.400'
                    size='xl'
                />
                <Text mt={2} size='sm' noOfLines={1} ml={'1'} mb={'5'} >
                    Cargando datos ...
                </Text>
            </Flex>
        );
    }

    return (
        <Box
            py={{ base: '0', sm: '8' }}
            px={{ base: '4', sm: '10' }}
            bg={'white'}
            borderRadius={{ base: 'none', sm: 'xl' }}
        >
            <Box mb={5} display={'flex'} flexDirection={'column'}>
                <Text fontSize={'sm'}>
                    <strong>Registrada:</strong>
                    {format(new Date(data.createdAt || new Date()), ' dd/LL/yyyy')}
                </Text>
                <Text fontSize={'sm'}>
                    <strong>Actualizada:</strong>
                    {format(new Date(data.createdAt || new Date()), ' dd/LL/yyyy')}
                </Text>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl isInvalid={!!errors?.reason?.message} mt={2}  isDisabled={loading}>
                    <FormLabel htmlFor="reason" color="primary.600">Motivo de Consulta</FormLabel>
                    <Textarea
                        id="reason"
                        focusBorderColor='primary.400'
                        {...register('reason', { required: true })}
                    />
                    <FormErrorMessage>
                        {errors.reason && errors.reason.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mt={2}  isDisabled={loading} isInvalid={!!errors?.revelevant_clinic?.message} >
                    <FormLabel htmlFor="revelevant_clinic" color="primary.600">Clínica Relevante</FormLabel>
                    <Textarea id="revelevant_clinic"
                        focusBorderColor='primary.400'
                        {...register('revelevant_clinic', { required: true })}
                    />
                    <FormErrorMessage>
                        {errors.revelevant_clinic && errors.revelevant_clinic.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors?.diagnosis?.message} mt={2}  isDisabled={loading}>
                    <FormLabel htmlFor="diagnosis" color="primary.600">Diagnóstico</FormLabel>
                    <Textarea
                        minH={120}
                        id="diagnosis"
                        focusBorderColor='primary.400'
                        {...register('diagnosis', { required: true })}
                    />
                    <FormErrorMessage>
                        {errors.diagnosis && errors.diagnosis.message}
                    </FormErrorMessage>
                </FormControl>
                <Flex justifyContent={'flex-end'}>
                    <Button type='submit' mt={5} isDisabled={!isDirty} bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}>
                            Actualizar
                    </Button>
                </Flex>
            </form>
        </Box>
    );
};
