import React, { useCallback, useEffect } from 'react';

// components
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    useToast,
    Text,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useFetch } from '../../../../../../hooks';
import { IVaccineRecordReq, IVaccines } from '../../../../../../interfaces';
import { vaccineRecordSchema, vaccineRecordSchemaType } from './vaccine-record-schema';

interface IVaccineModal {
    vaccines: IVaccineRecordReq[];
    isOpen: boolean;
    onAdd: (vaccine: IVaccineRecordReq) => void;
    onRemove: (id: number) => void;
    onClose: () => void;
}

export const VaccineModal = (props: IVaccineModal): JSX.Element => {
    const { vaccines, isOpen, onAdd, onRemove, onClose } = props;
    const toast = useToast();
    const { loading, error, data } = useFetch<IVaccines[]>('/vaccines');

    const { register, handleSubmit, formState: { errors } } = useForm<vaccineRecordSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(vaccineRecordSchema),
        criteriaMode: 'firstError',
        defaultValues: {}
    });

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const getVaccineName = (id: number): string => {
        let name = 'N/P';
        if (data) {
            const vaccine = data.filter(vaccine => vaccine.id === id);
            name = vaccine[0].name;
        }
        return name;
    };

    const wasAdded = (name: string): boolean => {
        return Boolean(vaccines.filter(vaccine => vaccine.name === name).length);
    };

    const onSubmit = (schema: vaccineRecordSchemaType): void => {
        const name = getVaccineName(schema.id);
        if (wasAdded(name)) {
            toast({
                description: 'Esta vacuna ya fue agregada a la lista',
                status: 'warning',
                duration: 7000,
                isClosable: true,
                position: 'bottom-right',
            });
            return;
        }

        onAdd({
            ...schema,
            name,
        });
    };

    const intemList = useCallback((vaccine: IVaccineRecordReq, i: number): JSX.Element => {
        const doRemove = (): void => onRemove(vaccine.id);
        return (
            <Flex
                key={`${vaccine.id}-${i}`}
                borderWidth={0.5}
                my={1}
                borderColor={'gray.200'}
                borderRadius={'md'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Text
                    paddingLeft={2}
                    color="primary.600"
                >
                    {vaccine.name}
                </Text>
                <Button ml={2} size={'sm'} onClick={doRemove}>
                    <DeleteIcon color={'red.400'} />
                </Button>
            </Flex>
        );
    }, [vaccines]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Registrar Vacuna</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex justifyContent={'space-between'}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                                <FormControl mr={2} mt={2} isInvalid={!!errors?.id?.message} isDisabled={loading}>
                                    <FormLabel htmlFor="id" color="primary.600">Vacuna</FormLabel>
                                    <Select
                                        {...register('id', { required: true })}
                                        focusBorderColor='primary.400'
                                        bg={'white'}
                                        variant={'outline'}
                                    >
                                        <option key={'blank'}>SELECCIONE VACUNA</option>
                                        {data?.map(vaccine => {
                                            return <option key={vaccine.id} value={vaccine.id} className={'capitalize'}>{vaccine.name}</option>;
                                        })}
                                    </Select>
                                    <FormErrorMessage>
                                        {errors.id && errors.id.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors?.number_of_batch?.message} mt={2} isDisabled={loading}>
                                    <FormLabel htmlFor="number_of_batch" color="primary.600">No. de Lote</FormLabel>
                                    <Input
                                        id="number_of_batch"
                                        type="text"
                                        focusBorderColor='primary.400'
                                        {...register('number_of_batch', { required: true })}
                                    />
                                    <FormErrorMessage>
                                        {errors.number_of_batch && errors.number_of_batch.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                                <FormControl mr={2} isInvalid={!!errors?.expiration_date?.message} mt={2} isDisabled={loading}>
                                    <FormLabel htmlFor="expiration_date" color="primary.600">Fecha de Expiración</FormLabel>
                                    <Input
                                        id="expiration_date"
                                        type="date"
                                        focusBorderColor='primary.400'
                                        {...register('expiration_date', { required: true })}
                                    />
                                    <FormErrorMessage>
                                        {errors.expiration_date && errors.expiration_date.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors?.next_vaccine?.message} mt={2} isDisabled={loading}>
                                    <FormLabel htmlFor="next_vaccine" color="primary.600">Próxima Vacuna</FormLabel>
                                    <Input
                                        id="next_vaccine"
                                        type="date"
                                        focusBorderColor='primary.400'
                                        {...register('next_vaccine', { required: true })}
                                    />
                                    <FormErrorMessage>
                                        {errors.next_vaccine && errors.next_vaccine.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Button
                                type='submit'
                                mt={5}
                                width={'full'}
                                bg={'primary.400'}
                                color={'white'}
                                _hover={{bg: 'primary.500'}}
                            >
                                Registrar
                            </Button>
                        </form>
                        <Box>
                            <Text color="primary.600" size={'md'}>Lista de vacunas a ser registradas</Text>
                            {
                                vaccines.map((vaccine, i) => {
                                    return intemList(vaccine, i);
                                })
                            }
                        </Box>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
