import React from 'react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { recordSchemaType } from './record-schema';
import { Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightAddon, Select } from '@chakra-ui/react';
import { GiWeightScale } from 'react-icons/gi';
import { AiFillHeart } from 'react-icons/ai';
import { TbWind } from 'react-icons/tb';
import { RiTempColdFill } from 'react-icons/ri';

interface IPhysiologicalConstants {
    loading: boolean;
    register: UseFormRegister<recordSchemaType>;
    formState: FormState<recordSchemaType>;
}

export const PhysiologicalConstants = (props: IPhysiologicalConstants): JSX.Element => {
    const { loading, register, formState } = props;
    const { errors } = formState;

    return (
        <>
            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                <FormControl mt={2}  isDisabled={loading} isInvalid={!!errors?.temperature?.message} >
                    <FormLabel htmlFor="date" color="primary.600">Temperatura</FormLabel>
                    <InputGroup>
                        <Input
                            id="temperature"
                            type="number"
                            step={0.01}
                            focusBorderColor='primary.400'
                            {...register('temperature', { required: true })}
                        />
                        <InputRightAddon
                            children={<RiTempColdFill color='#98b399' />}
                        />
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.temperature && errors.temperature.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl marginInline={1} isInvalid={!!errors?.weight?.message} mt={2}  isDisabled={loading}>
                    <FormLabel htmlFor="weight" color="primary.600">Peso</FormLabel>
                    <InputGroup>
                        <Input
                            id="weight"
                            type="number"
                            step={0.01}
                            focusBorderColor='primary.400'
                            {...register('weight', { required: true })}
                        />
                        <InputRightAddon
                            children={<GiWeightScale color='#98b399' />}
                        />
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.weight && errors.weight.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mt={2}  isDisabled={loading} isInvalid={!!errors?.body_condition?.message} >
                    <FormLabel htmlFor="body_condition" color="primary.600">Condición Corporal</FormLabel>
                    <Select
                        id="body_condition"
                        {...register('body_condition', { required: true })}
                        focusBorderColor='primary.400'
                        bg={'white'}
                        variant={'outline'}
                    >
                        <option key={0} value={0}>SELECCIONE</option>
                        <option key={1} value={1}>1</option>
                        <option key={2} value={2}>2</option>
                        <option key={3} value={3}>3</option>
                        <option key={4} value={3}>4</option>
                        <option key={5} value={5}>5</option>
                    </Select>
                    <FormErrorMessage>
                        {errors.body_condition && errors.body_condition.message}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                <FormControl mt={2}  isDisabled={loading} isInvalid={!!errors?.heart_rate?.message} >
                    <FormLabel htmlFor="date" color="primary.600">Frecuencia Cardiaca</FormLabel>
                    <InputGroup>
                        <Input id="heart_rate"
                            type="number"
                            step={0.01}
                            focusBorderColor='primary.400'
                            {...register('heart_rate', { required: true })}
                        />
                        <InputRightAddon
                            children={<AiFillHeart color='#98b399' />}
                        />
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.heart_rate && errors.heart_rate.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl marginLeft={1} isInvalid={!!errors?.breathing_rate?.message} mt={2}  isDisabled={loading}>
                    <FormLabel htmlFor="breathing_rate" color="primary.600">Frecuencia Respiratoria</FormLabel>
                    <InputGroup>
                        <Input
                            id="breathing_rate"
                            type="number"
                            step={0.01}
                            focusBorderColor='primary.400'
                            {...register('breathing_rate', { required: true })}
                        />
                        <InputRightAddon
                            children={<TbWind color={'#98b399'} />}
                        />
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.breathing_rate && errors.breathing_rate.message}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
        </>
    );
};
