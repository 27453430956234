import React from 'react';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Image,
    Flex
} from '@chakra-ui/react';

interface IReviewsModal {
    image: string;
    isOpen: boolean;
    onClose: () => void;
}

export const ImageModal = (props: IReviewsModal): JSX.Element => {
    const { image, isOpen, onClose } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
            <ModalOverlay />
            <ModalContent margin={0}>
                <ModalCloseButton size={'lg'} />
                <ModalBody p={0}>
                    <Flex justifyContent={'center'} alignItems={'center'} marginTop={10}>
                        <Image src={image} w={'100%'} h={'100%'}/>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
