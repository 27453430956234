import React, { useEffect, useRef, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listGridPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { Box, Button, Flex, useDisclosure, useToast } from '@chakra-ui/react';
import { EventDrawer } from '../tab-components';
import { AddIcon } from '@chakra-ui/icons';
import { useFetch } from '../../../../hooks';
import { IEvent } from '../../../../interfaces';
import { EventInput } from '@fullcalendar/core';
import { useAppSelector } from '../../../../state/hooks';
import { ProfileConstants } from '../../../../constants';

interface IEvents {
    petId?: string;
}

export const Events = (props: IEvents): JSX.Element => {
    const { petId } = props;
    const { user } = useAppSelector(state => state.user);

    const { fetchData, data, loading, error } = useFetch<IEvent[]>(`/patients/${petId}/events`);
    const [events, setEvents] = useState<EventInput[]>([]);

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const calendarRef = useRef<FullCalendar>(null);

    useEffect(() => {
        if (petId) {
            fetchData();
        }
    }, [petId]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error al cargar datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!loading && data) {
            const formattedEvents = data.map(event => {
                return {
                    id: String(event.id),
                    title: event.title.toUpperCase(),
                    start: event.startDate,
                    color: event.type.color,
                };
            });
            setEvents(formattedEvents);
        }
    }, [loading]);

    const hanldeButtons = (action: string): void => {
        const calendarAPI =  calendarRef?.current?.getApi();
        switch (action) {
            case 'prev':
                calendarAPI?.prev();
                break;
            case 'next':
                calendarAPI?.next();
                break;
            case 'today':
                calendarAPI?.today();
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Box bg={'white'} padding={3} borderRadius={'lg'}>
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <Box>
                        <Button
                            size={'sm'}
                            bg={'secondary.400'}
                            color={'white'}
                            _hover={{bg: 'secondary.500'}}
                            mr={2}
                            onClick={() => hanldeButtons('today')}
                        >
                            Hoy
                        </Button>
                        <Button
                            size={'sm'}
                            bg={'secondary.400'}
                            borderRightRadius={0}
                            color={'white'}
                            _hover={{bg: 'secondary.500'}}
                            onClick={() => hanldeButtons('prev')}
                        >
                            {'<'}
                        </Button>
                        <Button
                            size={'sm'}
                            bg={'secondary.400'}
                            borderLeftRadius={0}
                            color={'white'}
                            _hover={{bg: 'secondary.500'}}
                            onClick={() => hanldeButtons('next')}
                        >
                            {'>'}
                        </Button>
                    </Box>
                    {
                        user?.role_id === ProfileConstants.DOCTOR &&
                        <Button
                            leftIcon={<AddIcon />}
                            bg={'secondary.400'}
                            color={'white'}
                            _hover={{bg: 'secondary.600'}}
                            onClick={onOpen}
                        >
                            Registrar
                        </Button>
                    }
                </Flex>
                <FullCalendar
                    ref={calendarRef}
                    initialView="listWeek"
                    locales={[esLocale]}
                    locale={'es'}
                    weekends={true}
                    events={events}
                    plugins={[interactionPlugin, dayGridPlugin, listGridPlugin]}
                    headerToolbar={{
                        left: '',
                        center: 'title',
                        right: ''
                    }}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                />
            </Box>
            <EventDrawer
                isOpen={isOpen}
                onClose={onClose}
                petId={petId}
                refreshEvents={fetchData}
            />
        </>
    );
};
