import React, { useEffect } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Flex,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    useToast
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { numberOfSummarySchema, numberOfSummarySchemaType } from './patient-schema';
import { usePut } from '../../../../hooks';
import { IAppointment, ISummary } from '../../../../interfaces';

interface SummaryModalProps {
    summary?: ISummary;
    isOpen: boolean;
    onClose: () => void;
    updateList: () => void;
}

export const SummaryUpdateModal = (props: SummaryModalProps): JSX.Element => {
    const { summary, isOpen, onClose, updateList} = props;
    const { doUpdate, loading, error, data } = usePut<IAppointment, { number_of_summary: string }>(`/appointments/${summary?.appointment_id}/number_of_summary`);

    const toast = useToast();

    const { register, handleSubmit, formState: { errors }, reset } = useForm<numberOfSummarySchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(numberOfSummarySchema),
        criteriaMode: 'firstError',
        defaultValues: {
            number_of_summary: summary?.number_of_summary || '',
        }
    });

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error actualizando datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if(!loading && data) {
            updateList();
            toast({
                description: 'Datos actualizados exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
            doClose();
        }
    }, [loading]);

    const onSubmit = (schema: numberOfSummarySchemaType): void => {
        if (summary && summary.appointment_id) {
            doUpdate({
                number_of_summary: schema.number_of_summary || ''
            });
        }
    };

    const doClose = (): void => {
        reset({
            number_of_summary: '',
        });
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={doClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Actualizar Número de factura</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                            <FormControl isInvalid={!!errors?.number_of_summary?.message} mt={2} isDisabled={loading}>
                                <FormLabel htmlFor="number_of_summary" color="primary.600">Ingresar Número</FormLabel>
                                <Input
                                    id="number_of_summary"
                                    type="text"
                                    placeholder='221136-96'
                                    focusBorderColor='primary.400'
                                    {...register('number_of_summary', { required: true })}
                                />
                                <FormErrorMessage>
                                    {errors.number_of_summary && errors.number_of_summary.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            type='submit'
                            ml={5}
                            color={'white'}
                            backgroundColor={'primary.300'}
                            _hover={{ backgroundColor: 'primary.300' }}
                        >
                            Actualizar
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};
