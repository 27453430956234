import * as yup from 'yup';

export const vaccineRecordSchema = yup.object({
    id:
        yup.number()
            .label('id')
            .positive('Debe ingresar un numero mayor a 0')
            .required('Este campo es requerido'),
    number_of_batch:
        yup.string()
            .label('number_of_batch')
            .min(5, 'número de lote muy corto, debe tener al menos 5 caracteres')
            .required('Este campo es requerido'),
    expiration_date:
        yup.string()
            .label('expiration_date')
            .required('Este campo es requerido'),
    next_vaccine:
        yup.string()
            .label('next_vaccine')
            .required('Este campo es requerido'),
});

export type vaccineRecordSchemaType = yup.InferType<typeof vaccineRecordSchema>
