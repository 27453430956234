import React, { useEffect, useState } from 'react';
import { Box, Button, Heading, Text, useToast } from '@chakra-ui/react';
import { CellProps, Column } from 'react-table';
import { IPaginateResponse, IPatient } from '../../../../interfaces';
import { encodingToQuery } from '../../../../tools';
import { useFetch } from '../../../../hooks';
import { useNavigate } from 'react-router-dom';
import { DataTable, SearchPatient, searchPatientSchemaType } from '../../../../components';

export const SearchPatientPage = (): JSX.Element => {
    const [params, setParams] = useState<searchPatientSchemaType>();
    const [page, setPage] = useState<number>(1);

    const navigate = useNavigate();
    const toast = useToast();

    const { fetchData, loading, error, data } = useFetch<IPaginateResponse<IPatient>>('/patients', undefined, false);

    const onSearchPatient = (schema: searchPatientSchemaType): void => {
        setParams(schema);
    };

    useEffect(() => {
        if (params && !loading) {
            fetchData(`/patients?${encodingToQuery({page, ...params})}`);
        }
    }, [params, page]);


    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error al cargar mascotas',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }

    }, [loading]);

    const columns: Column<IPatient>[] =  [
        {
            id: 'id',
            Header: 'Nro Historia',
            accessor: 'id',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value}
                    </Text>
                );
            },
        },
        {
            id: 'name',
            Header: 'Nombre',
            accessor: 'name',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value}
                    </Text>
                );
            },
        },
        {   id: 'specie',
            Header: 'Especie',
            accessor: 'race',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value.specie.name}
                    </Text>
                );
            },
        },
        {   id: 'race',
            Header: 'Raza',
            accessor: 'race',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value.name}
                    </Text>
                );
            },
        },
        {
            id: 'owner',
            Header: 'Propietario',
            accessor: 'owner',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value ? `${value.user.first_name} ${value.user.last_name}` : ''}
                    </Text>
                );
            },
        },
        {
            id: 'details',
            Header: 'Detalles',
            accessor: 'id',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => navigate(`/paciente/${value}`)}>
                        Ver
                    </Button>
                );
            },
            disableSortBy: true,
        },
    ];

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'} >
                Buscar Paciente
            </Heading>
            <SearchPatient getData={onSearchPatient} loading={loading} />
            {
                data && data.items.length > 0 &&
                <Box mt={5}>
                    <DataTable
                        columns={columns}
                        data={data.items || []}
                        loading={loading}
                        numberOfPage={data?.numberOfPages || 1}
                        currentPage={data?.currentPage || 1}
                        handlePagination={page => setPage(page)}
                        hideSearch={true}
                    />
                </Box>
            }
        </>
    );
};
