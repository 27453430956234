import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api';
import { DoctorConstants } from './DoctorConstants';
import { IDoctor } from '../../interfaces';

export const getDoctor = createAsyncThunk(
    DoctorConstants.DOCTOR_ME,
    async (): Promise<IDoctor> => {
        const response = await API.get<IDoctor>('/doctors/me');
        return response.data;
    });
