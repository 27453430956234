import React, { useEffect } from 'react';
import { Box, FormControl, FormErrorMessage, Grid, Input, useToast, Text, Button, Spinner, Flex } from '@chakra-ui/react';



import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { useFetch } from '../../../hooks';
import { overviewSchema, overviewSchemaType } from './overview-schema';
import { StatisticsCard } from '../../../components';

interface IAdminMain {
    users: number;
    patients: number;
    owners: number;

    tests: number;
    surgeries: number;
    vaccines: number;

    appointments: {
        waiting: number;
        inProgress: number;
        finished: number;
    }
}

interface IDataSection {
    title: string;
    description: string;
    statistic: number;
}

export const AdminMain = (): JSX.Element => {
    const toast = useToast();

    const { loading, error, data, fetchData } = useFetch<IAdminMain>('/administrator/stats');

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const { register, handleSubmit, formState: { errors } } = useForm<overviewSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(overviewSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            startDate: format(new Date(), 'yyyy-LL-dd'),
            endDate: format(new Date(), 'yyyy-LL-dd'), // '2023-06-11'
        }
    });

    const onSubmit = (schema: overviewSchemaType): void => {
        const { startDate, endDate } = schema;
        fetchData(`/administrator/stats?startDate=${startDate}&endDate=${endDate}`);
    };

    const headerSection = (title: string): JSX.Element => {
        return (
            <Box
                bg={'primary.500'}
                px={3}
                py={5}
                borderRadius={'lg'}
                mb={3}
                maxWidth={600}
            >
                <Text fontWeight={'bold'} color={'white'}>{title}</Text>
            </Box>
        );
    };

    const section = (title: string, data: IDataSection[]): JSX.Element => {
        return (
            <Box my={3}>
                {headerSection(title)}
                <Grid templateColumns={{ sm: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }} gap='22px'>
                    {
                        data.map((data, i) => (
                            <StatisticsCard
                                key={`${title}-${i}`}
                                title={data.title}
                                description={data.description}
                                statistic={data.statistic}
                            />
                        ))
                    }
                </Grid>
            </Box>
        );
    };

    if(loading || !data) {
        return (
            <Flex alignItems={'center'} height={'lg'} flexDirection={'column'}>
                <Spinner
                    thickness='3px'
                    speed='0.65s'
                    emptyColor='primary.100'
                    color='primary.400'
                    size='xl'
                />
                <Text mt={2} size='sm' noOfLines={1} ml={'1'} mb={'5'} >
                    Cargando datos ...
                </Text>
            </Flex>
        );
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box bg={'white'} px={3} py={5} borderRadius={'lg'} mb={3}>
                    <Text mx={{base: 'none', md: 2}} mb={2} fontWeight={'bold'}>
                        Buscar por rango de fechas
                    </Text>
                    <Flex justifyContent={'flex-start'} alignItems={'center'}>
                        <FormControl maxWidth={300} mx={{base: 'none', md: 2}} isDisabled={loading} isInvalid={!!errors?.startDate?.message}>
                            <Input id="startDate"
                                type="date"
                                focusBorderColor='primary.400'
                                {...register('startDate', { required: true })}
                            />
                            <FormErrorMessage>
                                {errors.startDate && errors.startDate.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl maxWidth={300} mx={{base: 'none', md: 2}} isDisabled={loading} isInvalid={!!errors?.endDate?.message}>
                            <Input id="endDate"
                                type="date"
                                focusBorderColor='primary.400'
                                {...register('endDate', { required: true })}
                            />
                            <FormErrorMessage>
                                {errors.endDate && errors.endDate.message}
                            </FormErrorMessage>
                        </FormControl>
                        <Button
                            type='submit'
                            isDisabled={loading}
                            isLoading={loading}
                            bg={'primary.400'}
                            color={'white'}
                            _hover={{bg: 'primary.500'}}
                        >
                            Buscar
                        </Button>
                    </Flex>
                </Box>
            </form>
            {
                section(
                    'ACTORES',
                    [
                        {
                            title: 'Usuarios',
                            description: 'Cantidad de usuarios registrados en el sistema',
                            statistic: data?.users || 0,
                        },
                        {
                            title: 'Clientes',
                            description: 'Cantidad de clientes registrados en el sistema',
                            statistic: data?.owners || 0,
                        },
                        {
                            title: 'Pacientes',
                            description: 'Cantidad de pacientes registrados en el sistema',
                            statistic: data?.patients || 0,
                        }
                    ]
                )
            }
            {
                section(
                    'CONSULTAS',
                    [
                        {
                            title: 'Sala de Espera',
                            description: 'Cantidad de consultas en espera',
                            statistic: data?.appointments.waiting || 0,
                        },
                        {
                            title: 'En Progreso',
                            description: 'Cantidad de consultas en progreso',
                            statistic: data?.appointments.inProgress || 0,
                        },
                        {
                            title: 'Finalizadas',
                            description: 'Consultas finalizadas',
                            statistic: data?.appointments.finished || 0,
                        }
                    ]
                )
            }
            {
                section(
                    'CONSULTAS',
                    [
                        {
                            title: 'Examenes',
                            description: 'Cantidad de tipos de examenes registrados en el sistema',
                            statistic: data?.tests || 0,
                        },
                        {
                            title: 'Cirugias',
                            description: 'Cantidad de tipos de cirugias registrados en el sistema',
                            statistic: data?.surgeries || 0,
                        },
                        {
                            title: 'Vacunas',
                            description: 'Cantidad de tipos de vacunas registrados en el sistema',
                            statistic: data?.vaccines || 0,
                        }
                    ]
                )
            }
        </>
    );
};
