import * as yup from 'yup';

export const testRecordSchema = yup.object({
    id:
        yup.number()
            .label('id')
            .positive('Debe ingresar un numero mayor a 0')
            .required('Este campo es requerido'),
});

export type testRecordSchemaType = yup.InferType<typeof testRecordSchema>
