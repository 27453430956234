import React, { useRef } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { EventClickArg, EventInput } from '@fullcalendar/core';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

interface IBigCalendar {
    events: EventInput[];
    currentDate: Date;
    setCurrentDate: (date: Date) => void;
    hanldeEventClick: (event: EventClickArg) => void;
}

export const BigCalendar = (props: IBigCalendar): JSX.Element => {
    const { events, currentDate, setCurrentDate, hanldeEventClick } = props;
    const calendarRef = useRef<FullCalendar>(null);

    const hanldeButtons = (action: string): void => {
        const calendarAPI =  calendarRef?.current?.getApi();
        switch (action) {
            case 'prev':
                calendarAPI?.prev();
                setCurrentDate(calendarAPI?.getDate() || new Date());
                break;
            case 'next':
                calendarAPI?.next();
                setCurrentDate(calendarAPI?.getDate() || new Date());
                break;
            case 'today':
                calendarAPI?.today();
                setCurrentDate(calendarAPI?.getDate() || new Date());
                break;
            default:
                break;
        }
    };

    const renderTitle = (): JSX.Element => {
        return (
            <Text textTransform={'uppercase'} fontWeight={'bold'}>
                {format(currentDate, 'MMMM yyyy', {locale: es})}
            </Text>
        );
    };

    return (
        <>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Button
                    size={'sm'}
                    bg={'primary.400'}
                    color={'white'}
                    _hover={{bg: 'primary.500'}}
                    onClick={() => hanldeButtons('today')}
                >
                    Hoy
                </Button>
                {renderTitle()}
                <Box>
                    <Button
                        size={'sm'}
                        bg={'primary.400'}
                        borderRightRadius={0}
                        color={'white'}
                        _hover={{bg: 'primary.500'}}
                        onClick={() => hanldeButtons('prev')}
                    >
                        {'<'}
                    </Button>
                    <Button
                        size={'sm'}
                        bg={'primary.400'}
                        borderLeftRadius={0}
                        color={'white'}
                        _hover={{bg: 'primary.500'}}
                        onClick={() => hanldeButtons('next')}
                    >
                        {'>'}
                    </Button>
                </Box>
            </Flex>
            <FullCalendar
                ref={calendarRef}
                initialView="dayGridMonth"
                locale={'es'}
                eventClick={hanldeEventClick}
                weekends={true}
                events={events}
                plugins={[interactionPlugin, dayGridPlugin]}
                headerToolbar={{
                    left: '',
                    center: '',
                    right: ''
                }}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
            />
        </>
    );
};
