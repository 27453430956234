import React, { useCallback, useEffect, useMemo, useState } from 'react';

// components
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { ModalEdit, TableDrawer } from '../../../components';

import { useNavigate } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { format } from 'date-fns';

import { useFetch, usePost, usePut } from '../../../hooks';
import { IPaginateResponse, ISpecie, ISpecieReq,  } from '../../../interfaces';
import { SpecieForm } from './specie-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { specieSchema, specieSchemaType } from './specie-schema';
import { useForm } from 'react-hook-form';

interface IEdit {
    openEdit: boolean,
    id?: number;
}

export const Species = (): JSX.Element => {
    const [page, setPage] = useState<number>(1);
    const [edit, setEdit] = useState<IEdit | undefined>(undefined);

    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const { fetchData, loading, error, data: species} = useFetch<IPaginateResponse<ISpecie>>(`/species?page=${page}`, undefined, false);
    const { doRequest, loading: createLoading, error: createError, data: newSpecie } = usePost<ISpecie, ISpecieReq>('/species');
    const { doUpdate, loading: updateLoading, error: updateError, data: updateSpecie } = usePut<ISpecie, ISpecieReq>(`/species/${edit?.id}`);

    const { register, handleSubmit, formState, clearErrors } = useForm<specieSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(specieSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            name: '',
        }
    });

    useEffect(() => fetchData(), [page]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if(!createLoading && newSpecie) {
            fetchData();

            toast({
                title: 'Espepcie agregada.',
                description: 'Se ha agregado una nueva especie.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        }
    }, [createLoading]);

    useEffect(() => {
        if (!createLoading && Boolean(createError)) {
            let message = 'Ha ocurrido un error';
            if(createError?.message == 'Specie already exists') {
                message = 'Ya existe una especie con este nombre';
            }
            toast({
                description: message,
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [createLoading]);

    useEffect(() => {
        if(!updateLoading && updateSpecie) {
            fetchData();
            closeEdit();

            toast({
                title: 'Especie actualizada.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        }
    }, [updateLoading]);

    useEffect(() => {
        if (!updateLoading && Boolean(updateError)) {
            toast({
                description: updateError?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [updateLoading]);

    const columns = useMemo<Column<ISpecie>[]>(() => [
        {
            id: 'name',
            Header: 'Nombre',
            accessor: 'name',
            Cell: ({ value }: CellProps<ISpecie>) => {
                return (
                    <Text textTransform={'uppercase'}>{value}</Text>
                );
            },
        },
        {
            id: 'races',
            Header: 'Razas',
            accessor: 'id',
            Cell: ({ value }: CellProps<ISpecie>) => {
                return (
                    <Button
                        color={'primary.500'}
                        variant='link'
                        size={'sm'}
                        onClick={() => navigate(`/razas/${value}`)}>
                        Ver Razas
                    </Button>
                );
            },
            disableSortBy: true,
        },
        {
            id: 'edit',
            Header: 'Editar',
            accessor: 'id',
            Cell: ({ value }: CellProps<ISpecie>) => {
                const onPress = (): void => {
                    setEdit({
                        id: value,
                        openEdit: true,
                    });
                };

                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'sm'}
                        onClick={onPress}>
                         Editar
                    </Button>
                );
            },
            disableSortBy: true,
        },
        {
            id: 'createdAt',
            Header: 'Registrado',
            accessor: 'createdAt',
            Cell: ({ value }: CellProps<ISpecie>) => {
                return (
                    <Text textTransform={'uppercase'}>{format(new Date(value), 'dd/LL/yyyy')}</Text>
                );
            },
        },
    ],  []);

    const handlePagination = (page: number): void => setPage(page);

    const onSubmit = (schema: specieSchemaType): void => {
        doRequest({ name: schema.name.toUpperCase() });
    };

    const onUpdate = (schema: specieSchemaType): void => {
        if (edit?.id) {
            doUpdate({ name: schema.name.toUpperCase() });
        }
    };

    const closeEdit = (): void => {
        setEdit({id: undefined, openEdit: false});
        clearErrors();
    };

    const editForm = useCallback((): JSX.Element => {
        return (
            <FormControl isInvalid={!!formState?.errors?.name?.message} mt={2}>
                <FormLabel htmlFor='nombre'>Nombre</FormLabel>
                <Input
                    id='nombre'
                    type='text'
                    placeholder='Ej. Mastectomia'
                    focusBorderColor='primary.400'
                    bg={'white'}
                    disabled={updateLoading}
                    {...register('name', { required: true })}
                />
                <FormErrorMessage>
                    {formState.errors.name && formState.errors.name.message}
                </FormErrorMessage>
            </FormControl>
        );
    }, [formState]);

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'} >
                Especies
            </Heading>
            <TableDrawer
                isOpen={isOpen}
                drawerTitle={'Nueva Especie'}
                children={<SpecieForm loading={createLoading} register={register} formState={formState} />}
                loadingCreate={createLoading}
                buttonActionTitle={'Registar'}
                buttonCancelTitle={'Cancelar'}
                onSubmit={handleSubmit(onSubmit)}
                onCloseDrawer={onClose}
                columns={columns}
                data={species?.items || []}
                loading={loading}
                searchTitle={'Búsqueda local'}
                hideSearch={false}
                buttonTableTitle={'Nueva'}
                onClickButtonTable={onOpen}
                hideButtonTable={false}
                currentPage={species?.currentPage || 1}
                numberOfPage={species?.numberOfPages || 1}
                handlePagination={handlePagination}
            />
            <ModalEdit
                isOpen={Boolean(edit?.openEdit)}
                title='Editar Nombre'
                children={editForm}
                loading={updateLoading}
                onClose={closeEdit}
                onSubmit={handleSubmit(onUpdate)}
            />
        </>
    );
};
