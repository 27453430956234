import React, { FormEvent } from 'react';

// components
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';

interface IModalEdit {
    isOpen: boolean;
    loading: boolean;
    title: string;
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
    onClose: () => void;
    children: () => JSX.Element;
}

export const ModalEdit = (props: IModalEdit): JSX.Element => {
    const { isOpen, loading, title, children, onSubmit, onClose } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <form onSubmit={onSubmit}>
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {children()}
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            bg={'primary.400'}
                            color={'white'}
                            _hover={{bg: 'primary.500'}}
                            colorScheme="blue"
                            mr={3}
                            onClick={onClose}
                        >
                            Cancelar
                        </Button>
                        <Button type='submit' variant="ghost" isLoading={loading}>Editar</Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    );
};
