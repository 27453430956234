/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { Button, useToast } from '@chakra-ui/react';

import { PhysiologicalConstants } from './physiological-constants';
import { BasicInfo } from './basic-info';
import { SummaryForm } from './summary';
import { ButtomGroup } from './buttom-group';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { recordDraftSchema, recordSchema, recordSchemaType } from './record-schema';

import { IAppointment, ITestsRecordReq, IVaccineRecordReq } from '../../../../../interfaces';
import { getPreview } from '../../../../../tools';
import { Treatment } from './treatment';
import { DraftOrFinish, TestModal, VaccineModal } from './modals';
import { usePut } from '../../../../../hooks';
import { IAppointmentReq } from '../../../../../interfaces/network/req/IAppointmentReq';
import { useAppDispatch } from '../../../../../state/hooks';
import { useNavigate } from 'react-router-dom';
import { getDoctor } from '../../../../../state/doctor';
import { ObservationForm } from './observation-form';

interface IFormRecord {
    appointment: IAppointment;
    doctorId: number;
}

interface IModal {
    vaccioneOpen: boolean;
    testOpen: boolean;
    draftOrFinishOpen: boolean;
}

interface IPreviews {
    vaccines: string;
    tests: string;
}

const TREATMENT_TEMPLATE = `
    <h1>
        <strong>Tratamiento:</strong>
    </h1>

    <p><br></p>
    <p><br></p>
    <p><br></p>

    <h1>
        <strong>Indicaciones:</strong>
    </h1>

    <p><br></p>
    <p><br></p>
    <p><br></p>
    <p><br></p>
    <p><br></p>

    <p style="text-align: center;">
        <strong>_______________________________</strong>
    </p>
    <h2 style="text-align: center;">
        <strong style="color: rgb(0, 0, 0); text-align: center;">Firma M.V</strong>
    </h2>
`;

export const FormRecord = (props: IFormRecord): JSX.Element => {
    const { appointment, doctorId } = props;
    const { record, id, patient, is_review, summary } = appointment;
    const { id: recordId, reason, revelevant_clinic, diagnosis, medical_review, treatment: recipe } = record;

    const dispatch = useAppDispatch();

    const [modals, setModals] = useState<IModal>({
        vaccioneOpen: false,
        testOpen: false,
        draftOrFinishOpen: false,
    });

    const [tests, setTests] = useState<ITestsRecordReq[]>([]);
    const [vaccines, setVaccines] = useState<IVaccineRecordReq[]>([]);
    const [treatment, setTreatment] = useState<string>(recipe?.treatment || TREATMENT_TEMPLATE);

    const [previews, setPreviews] = useState<IPreviews>({
        vaccines: '',
        tests: '',
    });

    const navigate = useNavigate();
    const toast = useToast();
    const { loading, error, data, doUpdate } = usePut<IAppointment, IAppointmentReq>(`/appointments/finish/${id}`);
    const { loading: loadingDraft, error: errorDraft, data: dataDraft, doUpdate: doUpdateDraft } = usePut<IAppointment, IAppointmentReq>(`/appointments/draft/${id}`);

    const { register, handleSubmit, formState, reset, getValues, setError } = useForm<recordSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(recordSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            weight: medical_review?.weight || 0,
            heart_rate: medical_review?.heart_rate || 0,
            breathing_rate: medical_review?.breathing_rate || 0,
            temperature: medical_review?.temperature || 0,
            body_condition: medical_review?.body_condition || 0,

            reason: reason || '',
            revelevant_clinic: revelevant_clinic || '',
            diagnosis: diagnosis || '',
            summary: summary?.description || ''
        }
    });

    const openTest = (): void => {
        setModals({
            vaccioneOpen: false,
            testOpen: true,
            draftOrFinishOpen: false,
        });
    };

    const closeTestModal = (): void => {
        setModals({
            vaccioneOpen: false,
            testOpen: false,
            draftOrFinishOpen: false,
        });
    };

    const openVaccine = (): void => {
        setModals({
            vaccioneOpen: true,
            testOpen: false,
            draftOrFinishOpen: false,
        });
    };

    const closeVaccineModal = (): void => {
        setModals({
            vaccioneOpen: false,
            testOpen: false,
            draftOrFinishOpen: false,
        });
    };

    const openDraftOrFinishModal = (): void => {
        setModals({
            vaccioneOpen: false,
            testOpen: false,
            draftOrFinishOpen: true,
        });
    };

    const closeDraftOrFinishModal = (): void => {
        setModals({
            vaccioneOpen: false,
            testOpen: false,
            draftOrFinishOpen: false,
        });
    };

    const addVaccine = (vaccine: IVaccineRecordReq): void => {
        setVaccines([...vaccines, vaccine]);
    };

    const removeVaccine = (id: number): void => {
        const newVaccines = vaccines.filter(vaccine => vaccine.id != id);
        setVaccines(newVaccines);
    };

    const addTest = (test: ITestsRecordReq): void => {
        setTests([...tests, test]);
    };

    const removeTest = (id: number): void => {
        const newTest = tests.filter(test => test.id != id);
        setTests(newTest);
    };

    const onSubmit = (schema: recordSchemaType): void => {
        const appointment: IAppointmentReq = {
            patient_id: patient.id,
            doctor_id: doctorId,
            record: {
                // basic record info
                reason: schema.reason,
                revelevant_clinic: schema.revelevant_clinic,
                diagnosis: schema.diagnosis,
                observation: schema.observation || '',
                treatment: treatment,
                // physiological constants
                weight: schema.weight,
                heart_rate: schema.heart_rate,
                breathing_rate: schema.breathing_rate,
                temperature: schema.temperature,
                body_condition: schema.body_condition,
                // tests and vaccines
                tests: tests,
                vaccines: vaccines,
            },
            summary: schema.summary,
        };
        doUpdate(appointment);
    };

    const onSubmitDraft = async (): Promise<void> => {
        const data = getValues();
        // try catch block for yup validate function. abortEarly: false so it returns all errors on our form.
        try {
            await recordDraftSchema.validate(data, { abortEarly: false });
            // if passes, data is valid
            const appointment: IAppointmentReq = {
                patient_id: patient.id,
                doctor_id: doctorId,
                record: {
                    // basic record info
                    reason: data.reason,
                    revelevant_clinic: data.revelevant_clinic,
                    diagnosis: data.diagnosis,
                    observation: data.observation || '',
                    treatment: treatment,
                    // physiological constants
                    weight: data.weight,
                    heart_rate: data.heart_rate,
                    breathing_rate: data.breathing_rate,
                    temperature: data.temperature,
                    body_condition: data.body_condition,
                    // tests and vaccines
                    tests: tests,
                    vaccines: vaccines,
                },
                summary: data.summary || '',
            };
            doUpdateDraft(appointment);
        } catch (error: any) {
            // loop over all errors and setError to use them in react-hook-form
            error.inner?.map((inner: any, index: number) => {
                const { type, path, errors } = inner;
                return setError(path, { type, message: errors[index] });
            });
        }
    };

    const doGetPreview = useCallback((names: string[], oldPreview: string) => getPreview(names, oldPreview), []);

    useEffect(() => {
        const prevent = (e: BeforeUnloadEvent) => {
            e.preventDefault();
            return e.returnValue = 'message';
        };

        window.addEventListener('beforeunload', prevent);
        return () => window.removeEventListener('beforeunload', prevent);
    }, []);

    // refreshing the form
    useEffect(() => {
        if (Boolean(record)) {
            reset({
                weight: medical_review?.weight || 0,
                heart_rate: medical_review?.heart_rate || 0,
                breathing_rate: medical_review?.breathing_rate || 0,
                temperature: medical_review?.temperature || 0,
                body_condition: medical_review?.body_condition || 0,

                reason: reason || '',
                revelevant_clinic: revelevant_clinic || '',
                diagnosis: diagnosis || '',

                summary: summary?.description || ''
            });
        }
    }, [record]);

    useEffect(() => {
        if (!loading && Boolean(data)) {
            dispatch(getDoctor());
            toast({
                description: 'Consulta finalizada exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
            navigate(`/recipe/${recordId}`);
        }
    }, [loading]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!loadingDraft && Boolean(dataDraft)) {
            closeDraftOrFinishModal();
            toast({
                description: 'Datos guardados exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingDraft]);

    useEffect(() => {
        if (!loadingDraft && Boolean(errorDraft)) {
            closeDraftOrFinishModal();
            toast({
                description: error?.message || 'Ha ocurrido un error guardando borrador',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingDraft]);

    useLayoutEffect(() => {
        const names = tests.map(test => test.name);
        const preview = doGetPreview(names, previews.tests);

        setPreviews({
            ...previews,
            tests: preview,
        });
    }, [tests.length]);

    useLayoutEffect(() => {
        const names = vaccines.map(vaccine => vaccine.name);
        const preview = doGetPreview(names, previews.vaccines);

        setPreviews({
            ...previews,
            vaccines: preview,
        });
    }, [vaccines.length]);

    return (
        <>
            <form>
                <PhysiologicalConstants
                    loading={loading}
                    register={register}
                    formState={formState}
                />
                <BasicInfo
                    loading={loading}
                    register={register}
                    formState={formState}
                />
                {
                    is_review &&
                    <ObservationForm
                        loading={loading}
                        register={register}
                        formState={formState}
                    />
                }
                <ButtomGroup
                    previewsTests={previews.tests}
                    openTest={openTest}
                    previewsVaccines={previews.vaccines}
                    openVaccine={openVaccine}
                />
                <Treatment
                    treatment={treatment}
                    setTreatment={setTreatment}
                />
                <SummaryForm
                    loading={loading}
                    register={register}
                    formState={formState}
                />
                <Button
                    onClick={openDraftOrFinishModal}
                    mt={5}
                    width={'full'}
                    bg={'primary.400'}
                    color={'white'}
                    isDisabled={loading}
                    isLoading={loading}
                    _hover={{bg: 'primary.500'}}
                >
                    Guardar
                </Button>
            </form>
            {
                modals.draftOrFinishOpen &&
                    <DraftOrFinish
                        isOpen={true}
                        onClose={closeDraftOrFinishModal}
                        onSubmit={handleSubmit(onSubmit)}
                        onSubmitDraft={onSubmitDraft}
                    />
            }
            {
                modals.testOpen &&
                <TestModal
                    tests={tests}
                    isOpen={true}
                    onAdd={addTest}
                    onRemove={removeTest}
                    onClose={closeTestModal}
                />
            }
            {
                modals.vaccioneOpen &&
                <VaccineModal
                    vaccines={vaccines}
                    isOpen={true}
                    onAdd={addVaccine}
                    onRemove={removeVaccine}
                    onClose={closeVaccineModal}
                />
            }
        </>
    );
};

