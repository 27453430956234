import React, { useEffect } from 'react';

// components
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    useToast,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFetch, usePost } from '../../../../hooks';
import { IVaccineRecordReq, IVaccines } from '../../../../interfaces';
import { vaccineRecordSchema, vaccineRecordSchemaType } from './vaccine-record-schema';

interface IVaccineModal {
    isOpen: boolean;
    patientId: number;
    reviewId: number;
    onAdd: () => void;
    onClose: () => void;
}

export const AddVaccineModal = (props: IVaccineModal): JSX.Element => {
    const { isOpen, reviewId, patientId, onAdd, onClose } = props;
    const toast = useToast();
    const { loading, error, data } = useFetch<IVaccines[]>('/vaccines');
    const { doRequest, loading: loadingAddVaccine, error: errorAddVaccine, data: vaccineAdded } = usePost<IVaccines, Omit<IVaccineRecordReq, 'name'>>(`/vaccines/add/review/${reviewId}/patient/${patientId}`);

    const { register, handleSubmit, formState: { errors } } = useForm<vaccineRecordSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(vaccineRecordSchema),
        criteriaMode: 'firstError',
        defaultValues: {}
    });

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!loadingAddVaccine && Boolean(errorAddVaccine)) {
            toast({
                description: error?.message || 'Ha ocurrido un error registrando vacuna',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingAddVaccine]);

    useEffect(() => {
        if (!loadingAddVaccine && Boolean(vaccineAdded)) {
            onAdd();
            onClose();
        }
    }, [loadingAddVaccine]);

    const onSubmit = (schema: vaccineRecordSchemaType): void => {
        doRequest({
            id: Number(schema.id),
            expiration_date: schema.expiration_date,
            next_vaccine: schema.next_vaccine,
            number_of_batch: schema.number_of_batch,
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Registrar Vacuna</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex justifyContent={'space-between'} p={2}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                                <FormControl mr={2} mt={2} isInvalid={!!errors?.id?.message} isDisabled={loading}>
                                    <FormLabel htmlFor="id" color="primary.600">Vacuna</FormLabel>
                                    <Select
                                        {...register('id', { required: true })}
                                        focusBorderColor='primary.400'
                                        bg={'white'}
                                        variant={'outline'}
                                    >
                                        <option key={'blank'}>SELECCIONE VACUNA</option>
                                        {data?.map(vaccine => {
                                            return <option key={vaccine.id} value={vaccine.id} className={'capitalize'}>{vaccine.name}</option>;
                                        })}
                                    </Select>
                                    <FormErrorMessage>
                                        {errors.id && errors.id.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors?.number_of_batch?.message} mt={2} isDisabled={loading}>
                                    <FormLabel htmlFor="number_of_batch" color="primary.600">No. de Lote</FormLabel>
                                    <Input
                                        id="number_of_batch"
                                        type="text"
                                        focusBorderColor='primary.400'
                                        {...register('number_of_batch', { required: true })}
                                    />
                                    <FormErrorMessage>
                                        {errors.number_of_batch && errors.number_of_batch.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                                <FormControl mr={2} isInvalid={!!errors?.expiration_date?.message} mt={2} isDisabled={loading}>
                                    <FormLabel htmlFor="expiration_date" color="primary.600">Fecha de Expiración</FormLabel>
                                    <Input
                                        id="expiration_date"
                                        type="date"
                                        focusBorderColor='primary.400'
                                        {...register('expiration_date', { required: true })}
                                    />
                                    <FormErrorMessage>
                                        {errors.expiration_date && errors.expiration_date.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors?.next_vaccine?.message} mt={2} isDisabled={loading}>
                                    <FormLabel htmlFor="next_vaccine" color="primary.600">Próxima Vacuna</FormLabel>
                                    <Input
                                        id="next_vaccine"
                                        type="date"
                                        focusBorderColor='primary.400'
                                        {...register('next_vaccine', { required: true })}
                                    />
                                    <FormErrorMessage>
                                        {errors.next_vaccine && errors.next_vaccine.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Button
                                type='submit'
                                mt={5}
                                width={'full'}
                                bg={'primary.400'}
                                color={'white'}
                                _hover={{bg: 'primary.500'}}
                            >
                                Registrar
                            </Button>
                        </form>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
