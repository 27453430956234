import * as yup from 'yup';

export const recordSchema = yup.object({
    temperature:
        yup.number()
            .label('temperature')
            .positive('Debe ingresar un numero mayor a 0')
            .required('Este campo es requerido'),
    weight:
        yup.number()
            .label('weight')
            .positive('Debe ingresar un numero mayor a 0')
            .required('Este campo es requerido'),
    body_condition:
        yup.number()
            .label('body_condition')
            .positive('Debe ingresar un numero mayor a 0')
            .required('Este campo es requerido'),
    heart_rate:
        yup.number()
            .label('heart_rate')
            .positive('Debe ingresar un numero mayor a 0')
            .required('Este campo es requerido'),
    breathing_rate:
        yup.number()
            .label('breathing_rate')
            .positive('Debe ingresar un numero mayor a 0')
            .required('Este campo es requerido'),

    reason: yup.string().label('reason').required('Este campo es requerido'),
    revelevant_clinic: yup.string().label('revelevant_clinic'),
    diagnosis: yup.string().label('diagnosis').required('Este campo es requerido'),
    observation: yup.string().label('observation'),

    summary: yup.string().label('summary').required('Este campo es requerido'),
});

export const recordDraftSchema = yup.object({
    temperature: yup.number().label('temperature'),
    weight: yup.number().label('weight'),
    body_condition:yup.number().label('body_condition'),
    heart_rate: yup.number().label('heart_rate'),
    breathing_rate: yup.number().label('breathing_rate'),
    reason: yup.string().label('reason'),
    revelevant_clinic: yup.string().label('revelevant_clinic'),
    diagnosis: yup.string().label('diagnosis'),
    observation: yup.string().label('observation'),

    summary: yup.string().label('summary'),
});

export type recordSchemaType = yup.InferType<typeof recordSchema>
