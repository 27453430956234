import React, { useEffect, useState } from 'react';
import { Button, Heading, useDisclosure, useToast, Text, Box } from '@chakra-ui/react';
import { useFetch } from '../../../hooks';
import { IPaginateResponse, IPatient } from '../../../interfaces';
import { DataTable, SearchPatient, searchPatientSchemaType } from '../../../components';
import { CellProps, Column } from 'react-table';
import { AssignToDoctorModal } from './assign-to-doctor-modal';
import { encodingToQuery } from '../../../tools';
import { useNavigate } from 'react-router-dom';

export const PatientToRoom = (): JSX.Element => {
    const [url, setUrl] = useState<string>('');
    const { fetchData, loading, error, data } = useFetch<IPaginateResponse<IPatient>>(url, undefined, false);
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [page, setPage] = useState<number>(1);
    const [selectedPatient, setSelectedPatient] = useState<number>(0);

    const onSearchPatient = (schema: searchPatientSchemaType): void => {
        setUrl(`/patients?${encodingToQuery({page, ...schema})}`);
    };

    useEffect(() => {
        if (url.length > 0) {
            fetchData();
        }
    }, [url]);


    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error al cargar mascotas',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }

    }, [loading]);

    const columns: Column<IPatient>[] =  [
        {
            id: 'id',
            Header: 'Nro Historia',
            accessor: 'id',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value}
                    </Text>
                );
            },
        },
        {
            id: 'name',
            Header: 'Nombre',
            accessor: 'name',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value}
                    </Text>
                );
            },
        },
        {   id: 'specie',
            Header: 'Especie',
            accessor: 'race',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value.specie.name}
                    </Text>
                );
            },
        },
        {   id: 'race',
            Header: 'Raza',
            accessor: 'race',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value.name}
                    </Text>
                );
            },
        },
        {
            id: 'owner',
            Header: 'Propietario',
            accessor: 'owner',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>
                        {value ? `${value.user.first_name} ${value.user.last_name}` : ''}
                    </Text>
                );
            },
        },
        {
            id: 'send',
            Header: 'Enviar a Sala',
            accessor: (row) => row,
            Cell: ({ value }: { value: IPatient }) => {
                return (
                    <Button
                        bg={'secondary.400'} color={'white'} _hover={{bg: 'secondary.500'}}
                        variant={'outline'}
                        size={'sm'}
                        isDisabled={value.in_appointment}
                        onClick={() => {
                            setSelectedPatient(value.id);
                            onOpen();
                        }}>
                        Enviar
                    </Button>
                );
            },
            disableSortBy: true,
        },
        {
            id: 'details',
            Header: 'Detalles',
            accessor: 'id',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => navigate(`/paciente/${value}`)}>
                        Ver
                    </Button>
                );
            },
            disableSortBy: true,
        },
    ];

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'} >
                Enviar Paciente a Sala
            </Heading>
            <SearchPatient getData={onSearchPatient} loading={loading} />
            {
                data && data.items.length > 0 &&
                <Box mt={5}>
                    <DataTable
                        columns={columns}
                        data={data.items || []}
                        loading={loading}
                        numberOfPage={data?.numberOfPages || 1}
                        currentPage={data?.currentPage || 1}
                        handlePagination={page => setPage(page)}
                        hideSearch={true}
                    />
                    <AssignToDoctorModal
                        isOpen={isOpen}
                        onClose={onClose}
                        patient={selectedPatient}
                        refreshList={fetchData}
                    />
                </Box>
            }
        </>
    );
};
