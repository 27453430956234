import React, { useEffect, useMemo, useState } from 'react';

// components
import { Button, Heading, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { TableDrawer } from '../../../components';

import { useNavigate } from 'react-router-dom';
import { CellProps, Column } from 'react-table';

import { useFetch, usePost } from '../../../hooks';
import { IPaginateResponse, IUserReq, IUser } from '../../../interfaces';
import { UserForm } from './user-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userSchema, userSchemaType } from './user-schema';
import { useForm } from 'react-hook-form';
import { userType } from '../../../tools';

export const Users = (): JSX.Element => {
    const [page, setPage] = useState<number>(1);

    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const { fetchData, loading, error, data: users} = useFetch<IPaginateResponse<IUser>>(`/users?page=${page}`, undefined, false);
    const { doRequest, loading: createLoading, error: createError, data: newUser } = usePost<IUser, IUserReq>('/users');

    const { register, handleSubmit, formState } = useForm<userSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(userSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            first_name: '',
            last_name: '',
            dni: '',
            email: '',
            role_id: 1,
        }
    });

    useEffect(() => fetchData(), [page]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if(!createLoading && newUser) {
            fetchData();

            toast({
                title: 'Usuario agregado.',
                description: 'Se ha agregado un nuevo usuario.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        }
    }, [createLoading]);

    useEffect(() => {
        if (!createLoading && Boolean(createError)) {
            let message = 'Ha ocurrido un error';
            if(createError?.message == 'User already exists') {
                message = 'Ya existe una usuarios con estos datos';
            }
            toast({
                description: message,
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [createLoading]);

    const columns = useMemo<Column<IUser>[]>(() => [
        {
            id: 'first_name',
            Header: 'Nombre',
            accessor: 'first_name',
            Cell: ({ value }: CellProps<IUser>) => {
                return (
                    <Text>{value}</Text>
                );
            },
        },
        {
            id: 'last_name',
            Header: 'Apellido',
            accessor: 'last_name',
            Cell: ({ value }: CellProps<IUser>) => {
                return (
                    <Text>{value}</Text>
                );
            },
        },
        {
            id: 'dni',
            Header: 'CI',
            accessor: 'dni',
            Cell: ({ value }: CellProps<IUser>) => {
                return (
                    <Text>{value}</Text>
                );
            },
        },
        {
            id: 'email',
            Header: 'Correo',
            accessor: 'email',
            Cell: ({ value }: CellProps<IUser>) => {
                return (
                    <Text>{value}</Text>
                );
            },
        },
        {
            id: 'Tipo',
            Header: 'Tipo',
            accessor: 'role_id',
            Cell: ({ value }: CellProps<IUser>) => {
                return (
                    <Text>{userType(value)}</Text>
                );
            },
        },
        {
            id: 'details',
            Header: 'Detalles',
            accessor: 'id',
            Cell: ({ value }: CellProps<IUser>) => {
                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => navigate(`/usuario/${value}`)}>
                         Ver
                    </Button>
                );
            },
            disableSortBy: true,
        },
    ],  []);

    const handlePagination = (page: number): void => setPage(page);

    const onSubmit = (schema: userSchemaType): void => {
        doRequest({
            first_name: schema.first_name,
            last_name: schema.last_name,
            dni: schema.dni,
            email: schema.email,
            role_id: schema.role_id,
        });
    };

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'} >
                Usuarios
            </Heading>
            <TableDrawer
                isOpen={isOpen}
                drawerTitle={'Nueva Usuario'}
                children={<UserForm loading={createLoading} register={register} formState={formState} />}
                loadingCreate={createLoading}
                buttonActionTitle={'Registar'}
                buttonCancelTitle={'Cancelar'}
                onSubmit={handleSubmit(onSubmit)}
                onCloseDrawer={onClose}
                columns={columns}
                data={users?.items || []}
                loading={loading}
                searchTitle={'Búsqueda local'}
                hideSearch={false}
                buttonTableTitle={'Nuevo'}
                onClickButtonTable={onOpen}
                hideButtonTable={false}
                currentPage={users?.currentPage || 1}
                numberOfPage={users?.numberOfPages || 1}
                handlePagination={handlePagination}
            />
        </>
    );
};
