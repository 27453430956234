import React, { FormEvent } from 'react';

import { AddDrawer } from './drawer';
import { DataTable } from './table';
import { Column } from 'react-table';

interface ITableDrawer<Data extends object> {
    // Drawer props
    isOpen: boolean;
    drawerTitle: string;
    children: JSX.Element;
    loadingCreate: boolean;
    buttonActionTitle: string;
    buttonCancelTitle: string;
    onCloseDrawer: () => void;
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
    // Datable props
    columns: Column<Data>[];
    data: Data[];
    loading: boolean;
    searchTitle: string;
    hideSearch: boolean
    buttonTableTitle: string;
    hideButtonTable: boolean
    numberOfPage: number;
    currentPage: number;
    minHeight?: number;
    hiddenColumns?: string[];
    handlePagination: (page: number) => void;
    onClickButtonTable: () => void;
}

export function TableDrawer<Data extends object>(props: ITableDrawer<Data>) {
    const { isOpen, drawerTitle, children, buttonActionTitle, buttonCancelTitle } = props;
    const { columns, data, loading, loadingCreate, hideSearch, searchTitle, hideButtonTable, buttonTableTitle, numberOfPage, currentPage, hiddenColumns, minHeight } = props;
    const { onSubmit, onCloseDrawer, handlePagination, onClickButtonTable } = props;


    return (
        <>
            <AddDrawer
                isOpen={isOpen}
                drawerTitle={drawerTitle}
                loading={loadingCreate}
                children={children}
                buttonActionTitle={buttonActionTitle}
                buttonCancelTitle={buttonCancelTitle}
                onClose={onCloseDrawer}
                onSubmit={onSubmit}
            />
            <DataTable
                columns={columns}
                data={data}
                loading={loading}
                hideSearch={hideSearch}
                searchTitle={searchTitle}
                hideButton={hideButtonTable}
                buttonTitle={buttonTableTitle}
                numberOfPage={numberOfPage}
                currentPage={currentPage}
                hiddenColumns={hiddenColumns}
                minHeight={minHeight}
                onClickButton={onClickButtonTable}
                handlePagination={handlePagination}
            />
        </>
    );
}
