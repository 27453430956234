import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Heading, useToast, Text, Button } from '@chakra-ui/react';
import { DataTable } from '../../../components';
import { CellProps, Column } from 'react-table';
import { IAppointment, IParamsReq } from '../../../interfaces';
import { getAppointmentStatus } from '../../../tools';
import { AppointmentStatusConstants, ProfileConstants } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { getAppointments } from '../../../state/appointment';
import { NoShowModal, ReassignModal, StartModal } from './modals';
import { format } from 'date-fns';
import { getDoctor } from '../../../state/doctor';
import { usePut } from '../../../hooks';

interface IButtonActions {
    patientId: number;
    appointmentId: number;
    showReassign: boolean;
    showNoShowUp: boolean;
    showStart: boolean;
}

interface IConfirmAppointmentReq {
    id: number;
    date: string;
}


export const Waiting = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.user);
    const { doctor } = useAppSelector(state => state.doctor);
    const { appointments, loading, error } = useAppSelector(state => state.appointment);
    const { doUpdate, loading: loadingConfirm, error: errorConfirm, data } = usePut<IAppointment, IConfirmAppointmentReq>('/appointments/confirm/show/up');

    const toast = useToast();

    const [page, setPage] = useState<number>(1);
    const [actions, setActions] = useState<IButtonActions>({
        patientId: 0,
        appointmentId: 0,
        showReassign: false,
        showNoShowUp: false,
        showStart: false,
    });

    const doGetAppointments = () => {
        let filter: IParamsReq = {
            page,
            status: AppointmentStatusConstants.WAITING,
            startDate: format(new Date().setDate(new Date().getDate() - 31), 'yyyy-LL-dd'),
            endDate: format(new Date().setDate(new Date().getDate() + 1), 'yyyy-LL-dd'),
        };

        const isDoctor = user?.role_id === ProfileConstants.DOCTOR;
        if (isDoctor) {
            filter = {
                ...filter,
                doctor_id: doctor?.id,
            };
        }

        dispatch(getAppointments(filter));
    };

    const doGetDoctor = () => {
        dispatch(getDoctor());
    };

    useEffect(() => {
        if (user) {
            doGetAppointments();
        }
    }, [page, user, doctor]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!loadingConfirm && Boolean(errorConfirm)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingConfirm]);

    useEffect(() => {
        if (!loadingConfirm && Boolean(data)) {
            doGetAppointments();
        }
    }, [loadingConfirm]);

    const closeModal = (): void => {
        setActions({
            patientId: 0,
            appointmentId: 0,
            showReassign: false,
            showNoShowUp: false,
            showStart: false,
        });
    };

    const statusColor = (status: string): string => {
        switch (status) {
            case 'En espera':
                return 'orange.300';
            case 'En consulta':
                return 'primary.300';
            case 'Finalizada':
                return 'secondary.300';
            default:
                return '';
        }
    };

    const hiddenColumns = (): string[] | undefined => {
        let hide: string[] | undefined = undefined;

        switch (user?.role_id) {
            case ProfileConstants.ADMINISTRATOR:
                hide = ['reassign', 'no_show_up', 'start'];
                return hide;
            case ProfileConstants.DOCTOR:
                hide = ['reassign', 'no_show_up'];
                return hide;
            case ProfileConstants.RECEPTIONIST:
                hide = ['start'];
                return hide;
            default:
                return hide;
        }
    };

    const columns = useMemo<Column<IAppointment>[]>(() => [
        {
            id: 'id',
            Header: 'Historia',
            accessor: 'patient',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value.id}</Text>
                );
            },
        },
        {
            id: 'patient',
            Header: 'Paciente',
            accessor: 'patient',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value.name}</Text>
                );
            },
        },
        {
            id: 'specie',
            Header: 'Especie',
            accessor: 'patient',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value.race.specie.name}</Text>
                );
            },
        },
        {
            id: 'doctor',
            Header: 'Asignado',
            accessor: 'doctor',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{value ? `${value.user.first_name} ${value.user.last_name.charAt(0)}.`: 'Libre'}</Text>
                );
            },
        },
        {
            id: 'date',
            Header: 'Fecha',
            accessor: 'createdAt',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{format(new Date(value), ' dd/LL/yyyy')}</Text>
                );
            },
        },
        {
            id: 'time',
            Header: 'Llegada',
            accessor: 'createdAt',
            Cell: ({ value }: CellProps<IAppointment>) => {
                return (
                    <Text textTransform={'uppercase'}>{format(new Date(value), ' hh:mm b')}</Text>
                );
            },
        },
        {
            id: 'status',
            Header: 'Estado',
            accessor: 'status',
            Cell: ({ value }: CellProps<IAppointment>) => {
                const status = getAppointmentStatus(value);
                return (
                    <Badge borderRadius={'md'} backgroundColor={statusColor(status)} variant='solid'>
                        {status}
                    </Badge>
                );
            },
        },
        {
            id: 'event',
            Header: 'Tipo',
            accessor: 'event',
            Cell: ({ row }: CellProps<IAppointment>) => {
                const appointment = row.original;
                if (Boolean(appointment.event)) {
                    return (
                        <Button
                            bg={'green.400'} color={'white'} _hover={{bg: 'green.500'}}
                            variant={'outline'}
                            size={'xs'}
                            onClick={() => {
                                doUpdate({
                                    id: appointment.id,
                                    date: format(new Date(), 'yyyy-LL-dd')
                                });
                            }}
                        >
                            Previa Cita
                        </Button>
                    );
                } else {
                    return (
                        <Badge borderRadius={'md'}  variant='solid'>
                            --
                        </Badge>
                    );
                }
            },
        },
        {
            id: 'reassign',
            Header: 'Reasignar',
            accessor: 'status',
            Cell: ({ row }: CellProps<IAppointment>) => {
                const appointment = row.original;
                return (
                    <Button
                        bg={'orange.400'} color={'white'} _hover={{bg: 'orange.500'}}
                        variant={'outline'}
                        size={'xs'}
                        isDisabled={appointment.status !== AppointmentStatusConstants.WAITING}
                        onClick={() => {
                            setActions({
                                patientId: appointment.patient.id,
                                appointmentId: appointment.id,
                                showReassign: true,
                                showNoShowUp: false,
                                showStart: false,
                            });
                        }}
                    >
                        REASIGNAR
                    </Button>
                );
            },
            disableSortBy: true,
        },
        {
            id: 'no_show_up',
            Header: 'Reportar',
            accessor: 'status',
            Cell: ({ row }: CellProps<IAppointment>) => {
                const appointment = row.original;
                return (
                    <Button
                        bg={'red.400'} color={'white'} _hover={{bg: 'red.500'}}
                        variant={'outline'}
                        size={'xs'}
                        isDisabled={appointment.status !== AppointmentStatusConstants.WAITING}
                        onClick={() => {
                            setActions({
                                patientId: appointment.patient.id,
                                appointmentId: appointment.id,
                                showReassign: false,
                                showNoShowUp: true,
                                showStart: false,
                            });
                        }}
                    >
                        NO ASISTIO
                    </Button>
                );
            },
            disableSortBy: true,
        },
        {
            id: 'start',
            Header: 'Comenzar',
            accessor: 'status',
            Cell: ({ row }: CellProps<IAppointment>) => {
                const appointment = row.original;
                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'xs'}
                        onClick={() => {
                            setActions({
                                patientId: appointment.patient.id,
                                appointmentId: appointment.id,
                                showReassign: false,
                                showNoShowUp: false,
                                showStart: true,
                            });
                        }}
                    >
                        COMENZAR
                    </Button>
                );
            },
            disableSortBy: true,
        },
    ],  [doctor, hiddenColumns]);

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'}>
                Sala de Espera
            </Heading>
            <DataTable
                columns={columns}
                data={appointments?.items || []}
                loading={loading}
                searchTitle={'Búsqueda local'}
                numberOfPage={appointments?.numberOfPages || 1}
                currentPage={appointments?.currentPage || 1}
                handlePagination={page => setPage(page)}
                hiddenColumns={hiddenColumns()}
            />
            {
                user?.role_id === ProfileConstants.RECEPTIONIST &&
                <>
                    <ReassignModal
                        isOpen={actions.showReassign}
                        appointmentId={actions.appointmentId}
                        patientId={actions.patientId}
                        onClose={closeModal}
                        doGetAppointments={doGetAppointments}
                    />
                    <NoShowModal
                        isOpen={actions.showNoShowUp}
                        appointmentId={actions.appointmentId}
                        patientId={actions.patientId}
                        onClose={closeModal}
                        doGetAppointments={doGetAppointments}
                    />
                </>
            }
            {
                user?.role_id === ProfileConstants.DOCTOR &&
                <StartModal
                    isOpen={actions.showStart}
                    appointmentId={actions.appointmentId}
                    onClose={closeModal}
                    doGetAppointments={doGetAppointments}
                    doGetDoctor={doGetDoctor}
                />
            }
        </>
    );
};
