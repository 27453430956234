import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { IoPawSharp } from 'react-icons/io5';

export const EmptyState = (): JSX.Element => {
    return (
        <Flex color={'secondary.400'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            <Heading as='h3' size='sm' my={3}>Aún no tiene una consulta en progreso ..</Heading>
            <IoPawSharp size={70} />
        </Flex>
    );
};
