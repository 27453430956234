import React from 'react';
import {
    Button,
    Modal,
    ModalBody,
    Text,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Box,
    Divider
} from '@chakra-ui/react';
import { ISummary } from '../../../../interfaces';

interface SummaryModalProps {
    summary?: ISummary;
    isOpen: boolean;
    onClose: () => void;
    handleSummaryNumberModal: (open: boolean) => void;
    handleSummaryDescriptionModal: (open: boolean) => void;
}

export const SummaryModal = ({ summary, isOpen, onClose, handleSummaryNumberModal, handleSummaryDescriptionModal }: SummaryModalProps): JSX.Element => {
    const description = summary?.description || '';
    const number_of_summary = summary?.number_of_summary || '';

    const openUpdate = (): void => {
        onClose();
        handleSummaryNumberModal(true);
    };

    const openUpdateDescription = (): void => {
        onClose();
        handleSummaryDescriptionModal(true);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Resumen de la Consulta</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text fontSize={'md'}>Los procedimientos a cobrar son:</Text>
                    <Text mt={4} fontWeight={'bold'} fontStyle={'italic'}>{description}</Text>
                    <Button
                        variant={'link'}
                        fontSize={'md'}
                        fontWeight={'light'}
                        color={'primary.500'}
                        onClick={openUpdateDescription}
                    >
                        Actualizar procedimientos
                    </Button>
                    <Divider />
                    <Box mt={5}>
                        <Divider />
                        {Boolean(number_of_summary) && <Text fontSize={'md'}><strong>No Factura:</strong> {number_of_summary}</Text> }
                        <Button
                            variant={'link'}
                            fontSize={'md'}
                            fontWeight={'light'}
                            color={'primary.500'}
                            onClick={openUpdate}
                        >
                            Actualizar numero de factura
                        </Button>
                        <Divider />
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button
                        color={'white'}
                        backgroundColor={'secondary.300'}
                        _hover={{ backgroundColor: 'secondary.300' }}
                        onClick={onClose}
                    >
                            Cerrar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
