import React, { useEffect } from 'react';
import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Heading,
    Divider,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Text,
    useToast
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { vaccineSchema, vaccineSchemaType } from './vaccine-schema';
import { IMedicalReviews, IVaccines } from '../../../../interfaces';
import { usePut } from '../../../../hooks';

interface IEditVaccinesModal {
    isOpen: boolean;
    medical_review_id: number;
    vaccine: {
        vaccine: IVaccines;
        expiration_date: string;
        next_vaccine: string;
        number_of_batch: string;
    };
    onClose: () => void;
    onUpdate: () => void;
}

interface IVaccineEditReq {
    vaccine_id: number;
    expiration_date: string;
    next_vaccine: string;
    number_of_batch: string;
}

export const EditvaccinesModal = (props: IEditVaccinesModal): JSX.Element => {
    const { isOpen, vaccine, medical_review_id, onClose, onUpdate } = props;
    const toast = useToast();

    const { loading: loadingUpdate, error: errorUpdate, data: dataUpdate, doUpdate } = usePut<IMedicalReviews, IVaccineEditReq>(`records/vaccine/${medical_review_id}`);

    const { register, handleSubmit, formState: { errors, isDirty } } = useForm<vaccineSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(vaccineSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            expiration_date: vaccine.expiration_date,
            number_of_batch: vaccine.number_of_batch,
            next_vaccine: vaccine.next_vaccine
        }
    });

    useEffect(() => {
        if (!loadingUpdate && Boolean(dataUpdate)) {
            toast({
                description: 'Datos actualizados exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
            onClose();
            onUpdate();
        }
    }, [loadingUpdate]);

    useEffect(() => {
        if (!loadingUpdate && Boolean(errorUpdate)) {
            toast({
                description: errorUpdate?.message || 'Error actualizando datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingUpdate, errorUpdate]);

    const onSubmit = (schema: vaccineSchemaType): void => {
        doUpdate({
            expiration_date: schema.expiration_date,
            next_vaccine: schema.next_vaccine,
            number_of_batch: schema.number_of_batch,
            vaccine_id: vaccine.vaccine.id,
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}>
            <ModalOverlay />
            <ModalContent pb={10}>
                <ModalHeader>
                    <Heading as='h1' size='lg'>
                        Editar Vacunas
                    </Heading>
                    <Divider />
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Flex><Text>VACUNA {vaccine.vaccine.name}</Text></Flex>
                        <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                            <FormControl isInvalid={!!errors?.number_of_batch?.message} mt={2} isDisabled={false}>
                                <FormLabel htmlFor="number_of_batch" color="primary.600">No. de Lote</FormLabel>
                                <Input
                                    id="number_of_batch"
                                    type="text"
                                    focusBorderColor='primary.400'
                                    {...register('number_of_batch', { required: true })}
                                />
                                <FormErrorMessage>
                                    {errors.number_of_batch && errors.number_of_batch.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Flex>
                        <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                            <FormControl mr={2} isInvalid={!!errors?.expiration_date?.message} mt={2} isDisabled={false}>
                                <FormLabel htmlFor="expiration_date" color="primary.600">Fecha de Expiración</FormLabel>
                                <Input
                                    id="expiration_date"
                                    type="date"
                                    focusBorderColor='primary.400'
                                    {...register('expiration_date', { required: true })}
                                />
                                <FormErrorMessage>
                                    {errors.expiration_date && errors.expiration_date.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors?.next_vaccine?.message} mt={2} isDisabled={false}>
                                <FormLabel htmlFor="next_vaccine" color="primary.600">Próxima Vacuna</FormLabel>
                                <Input
                                    id="next_vaccine"
                                    type="date"
                                    focusBorderColor='primary.400'
                                    {...register('next_vaccine', { required: true })}
                                />
                                <FormErrorMessage>
                                    {errors.next_vaccine && errors.next_vaccine.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Flex>
                        <Button
                            type='submit'
                            mt={5}
                            width={'full'}
                            bg={'primary.400'}
                            color={'white'}
                            _hover={{bg: 'primary.500'}}
                            isDisabled={!isDirty}
                        >
                            Actualizar
                        </Button>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
