import React, { useEffect, useMemo, useState } from 'react';
import { Button, Heading, Text, useToast } from '@chakra-ui/react';
import { DataTable } from '../../../components';
import { CellProps, Column } from 'react-table';
import { useFetch } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { IPaginateResponse, IPatient } from '../../../interfaces';

export const Patients = (): JSX.Element => {
    const navigate = useNavigate();
    const toast = useToast();

    const [page, setPage] = useState<number>(1);
    const { fetchData, loading, error, data} = useFetch<IPaginateResponse<IPatient>>(`/patients?page=${page}`, undefined, false);

    useEffect(() => fetchData(), [page]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const columns = useMemo<Column<IPatient>[]>(() => [
        {
            id: 'id',
            Header: 'Nro Historia',
            accessor: 'id',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>{value}</Text>
                );
            },
        },
        {
            id: 'name',
            Header: 'Nombre',
            accessor: 'name',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text textTransform={'uppercase'}>{value}</Text>
                );
            },
        },
        {
            id: 'specie',
            Header: 'Especie',
            accessor: 'race',
            Cell: ({ value }: CellProps<IPatient>) => {
                const { specie } = value;
                return (
                    <Text textTransform={'uppercase'}>{specie.name}</Text>
                );
            },
        },
        {
            id: 'race',
            Header: 'Raza',
            accessor: 'race',
            Cell: ({ value }: CellProps<IPatient>) => {
                const { name } = value;
                return (
                    <Text textTransform={'uppercase'}>{name}</Text>
                );
            },
        },
        {
            id: 'owner',
            Header: 'Propietario',
            accessor: 'owner',
            Cell: ({ value }: CellProps<IPatient>) => {
                const { user } = value;
                return (
                    <Text textTransform={'uppercase'}>{`${user.first_name} ${user.last_name}`}</Text>
                );
            },
        },
        {
            id: 'details',
            Header: 'Detalles',
            accessor: 'id',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => navigate(`/paciente/${value}`)}>
                        Ver
                    </Button>
                );
            },
            disableSortBy: true,
        },
    ],  []);

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'} >
                Pacientes
            </Heading>
            <DataTable
                columns={columns}
                data={data?.items || []}
                loading={loading}
                searchTitle={'Búsqueda local'}
                numberOfPage={data?.numberOfPages || 1}
                currentPage={data?.currentPage || 1}
                handlePagination={page => setPage(page)}
            />
        </>
    );
};
