import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import {
    Badge,
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Select,
    Spinner,
    Text,
    useToast
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { patientSchema, patientSchemaType } from '../patient-schema';
import { useFetch, usePost, usePut } from '../../../../hooks';
import { IPatient, IPatientReq, IPetSex, IRace } from '../../../../interfaces';
import { format } from 'date-fns';

import avatar from '../../../../assets/images/galenos.webp';
import { Avatar, ChartModal } from '../../../../components';
import { MortalityModal } from '../modals';
import { useNavigate } from 'react-router-dom';

interface IOverview {
    petId?: string;
}

export const Overview = (props: IOverview): JSX.Element => {
    const { petId } = props;

    const navigate = useNavigate();
    const [specieId, setSpecieId] = useState<number>(0);
    const [openMortaliy, setOpenMortaliy] = useState<boolean>(false);
    const [chartModal, setChartModal] = useState<boolean>(false);
    const toast = useToast();

    const { fetchData, loading, error, data} = useFetch<IPatient>(`/patients/${petId}`, undefined, false);
    const { fetchData: fetchRaces, loading: loadingRaces, data: races} = useFetch<IRace[]>(`/races/${specieId}`, undefined, false);
    const { fetchData: fetchSpecies, data: species } = useFetch<IPetSex[]>('/species', undefined, false);
    const { fetchData: fetchSexes, data: sexes } = useFetch<IPetSex[]>('/pet_sex', undefined, false);
    const { doUpdate, loading: loadingUpdated, error: errorUpdated, data: patient } = usePut<IPatient, IPatientReq>(`/patients/${petId}`);
    const { doRequest, loading: loadingAvatar, error: errorAvatar, data: avatarUpdate } = usePost(`/patients/${petId}/avatar`, {headers: {'Content-Type': 'multipart/form-data'}});
    const { doUpdate: doUpdateMortality, loading: loadingMortality, error: errorMortality, data: mortality } = usePut<IPatient, object>(`/patients/${petId}/mortality`);

    const { register, handleSubmit, formState: { errors, isDirty }, reset, watch } = useForm<patientSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(patientSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            name: '',
            specie_id: specieId,
            race_id: 1,
            birth: format(new Date(), 'yyyy-LL-dd'),
            color: '',
            sex_id: 0,
            neutered: false,
            pathology: '',
            chip_id: '',
        }
    });

    useEffect(() => {
        fetchData();
        fetchSpecies();
        fetchSexes();
    }, []);

    useEffect(() => {
        if (data && specieId && !loadingRaces) {
            fetchRaces();
        }
    }, [data, specieId]);

    useEffect(() => {
        const specie_id = watch('specie_id') || 0;
        if (specie_id > 0) {
            setSpecieId(specie_id);
        }
    }, [watch('specie_id')]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error al cargar datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if (data) {
            setSpecieId(data.race.specie.id);
            reset({
                name: data.name,
                specie_id: data.race.specie.id,
                race_id: data.race.id,
                birth: format(new Date(data.birth), 'yyyy-LL-dd'),
                color: data.color,
                sex_id: data.sex.id,
                neutered: data.neutered,
                pathology: data.pathology,
                chip_id: data.chip_id ?? '',
            });
        }
    }, [data]);

    useEffect(() => {
        if (!loadingUpdated && Boolean(errorUpdated)) {
            toast({
                description: errorUpdated?.message || 'Error actualizando datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingUpdated]);

    useEffect(() => {
        if(!loadingUpdated && patient) {
            fetchData();
            toast({
                description: 'Datos actualizados exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingUpdated]);

    useEffect(() => {
        if (!loadingMortality && Boolean(errorMortality)) {
            toast({
                description: errorUpdated?.message || 'Error actualizando datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingMortality]);

    useEffect(() => {
        if(!loadingMortality && mortality) {
            fetchData();
            toast({
                description: 'Datos actualizados exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingMortality]);

    useEffect(() => {
        if (!loadingAvatar && avatarUpdate) {
            fetchData();
            toast({
                description: 'Datos actualizados exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingAvatar]);

    useEffect(() => {
        if (!loadingAvatar && Boolean(errorAvatar)) {
            toast({
                description: errorAvatar?.message || 'Error actualizando datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingAvatar]);

    const openChart = (): void => {
        setChartModal(true);
    };

    const closeChart = (): void => {
        setChartModal(false);
    };

    const aliveBadge = useCallback((alive?: boolean): JSX.Element => {
        return (
            <Button
                variant={'link'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                onClick={openModal}
            >
                <Text
                    mr={1}
                    fontSize={'sm'}
                    color={'primary.500'}
                    textDecoration={'underline'}
                >
                    Estado:
                </Text>
                <Badge
                    p={0}
                    colorScheme={alive ? 'green' : 'gray'}
                >
                    {alive ? 'Vivo' : 'Muerto'}
                </Badge>
            </Button>
        );
    }, [data]);

    const onSubmit = async (schema: patientSchemaType): Promise<void> => {
        if (data) {
            doUpdate({
                race_id: schema.race_id,
                specie_id: schema.specie_id,
                name: schema.name,
                birth: new Date(schema.birth),
                color: schema.color,
                sex_id: schema.sex_id,
                neutered: schema.neutered || false,
                pathology: schema.pathology || '',
                chip_id: schema.chip_id || '',
            });
        }
    };

    const onChangeAvatar = async (file: ChangeEvent): Promise<void> => {
        const { files } = file.target as HTMLInputElement;
        if (files && files.length !== 0) {
            const fileSize = files[0].size;
            const fileMB = fileSize / 1024 ** 4;
            if (fileMB > 4) {
                alert('Por favor, seleccione un archivo con peso menor a 4MB.');
            }

            const formData = new FormData();
            formData.append('file', files[0]);
            doRequest(formData);
        }
    };

    const openModal = (): void => {
        setOpenMortaliy(true);
    };

    const closeModal = (): void => {
        setOpenMortaliy(false);
    };

    const updateMortality = (): void => {
        doUpdateMortality({});
        setOpenMortaliy(false);
    };

    if(loading || !data || !sexes || !species || !races) {
        return (
            <Flex alignItems={'center'} height={'lg'} flexDirection={'column'}>
                <Spinner
                    thickness='3px'
                    speed='0.65s'
                    emptyColor='primary.100'
                    color='primary.400'
                    size='xl'
                />
                <Text mt={2} size='sm' noOfLines={1} ml={'1'} mb={'5'} >
                    Cargando datos ...
                </Text>
            </Flex>
        );
    }

    return (
        <Box
            py={{ base: '0', sm: '8' }}
            px={{ base: '4', sm: '10' }}
            bg={'white'}
            borderRadius={{ base: 'none', sm: 'xl' }}
        >
            <Avatar
                avatarName={data.name || ''}
                imageUrl={data.avatar || avatar}
                onChange={onChangeAvatar}
            />
            <Box my={4} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <Text marginX={'auto'} noOfLines={1} fontSize={'sm'} fontWeight={'bold'}>Historia Nro {data.id}</Text>
                <Text marginX={'auto'} noOfLines={1} fontSize={'sm'}>Paciende desde: {format(new Date(data.createdAt || new Date()), 'dd/LL/yyyy')}</Text>
                <Button variant="link" color="primary.600" size="sm" onClick={() => navigate(`/propietario/${data.owner.id}`)}>
                    <Text marginX={'auto'} noOfLines={1} fontSize={'sm'}>Propietario: {`${data.owner.user.first_name} ${data.owner.user.last_name}`}</Text>
                </Button>
                {aliveBadge(data.alive)}
                <Button
                    mt={4}
                    textTransform={'uppercase'}
                    variant={'link'}
                    color={'primary.500'}
                    fontSize={'sm'}
                    onClick={openChart}
                >
                    Ver Gráfica de Peso
                </Button>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                    <FormControl isInvalid={!!errors?.name?.message} mt={2} isDisabled={loading}>
                        <FormLabel htmlFor="name" color="primary.600">Nombre</FormLabel>
                        <Input
                            id="name"
                            type="text"
                            focusBorderColor='primary.400'
                            {...register('name', { required: true })}
                        />
                        <FormErrorMessage>
                            {errors.name && errors.name.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                    <FormControl mt={2}  isDisabled={loading}>
                        <FormLabel htmlFor="specie" color="primary.600">Especie</FormLabel>
                        <Select
                            {...register('specie_id', { required: true })}
                            focusBorderColor='primary.400'
                            bg={'white'}
                            variant={'outline'}
                        >
                            {species?.map(specie => {
                                return <option key={specie.id} value={specie.id} className={'capitalize'}>{specie.name}</option>;
                            })}
                        </Select>
                    </FormControl>
                    <FormControl ml={{base: 'none', md: 2}} isInvalid={!!errors?.race_id?.message} mt={2}  isDisabled={loading}>
                        <FormLabel htmlFor="race" color="primary.600">Raza</FormLabel>
                        <Select
                            id="race"
                            {...register('race_id', { required: true })}
                            focusBorderColor='primary.400'
                            bg={'white'}
                            variant={'outline'}
                        >
                            {races?.map(race => {
                                return <option key={race.id} value={race.id} className={'capitalize'}>{race.name}</option>;
                            })}
                        </Select>
                        <FormErrorMessage>
                            {errors.race_id && errors.race_id.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                    <FormControl isInvalid={!!errors?.color?.message} mt={2}  isDisabled={loading}>
                        <FormLabel htmlFor="color" color="primary.600">Color</FormLabel>
                        <Input
                            id="color"
                            type="text"
                            focusBorderColor='primary.400'
                            {...register('color', { required: true })}
                        />
                        <FormErrorMessage>
                            {errors.color && errors.color.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl mx={{base: 'none', md: 2}} mt={2}  isDisabled={loading} isInvalid={!!errors?.sex_id?.message} >
                        <FormLabel htmlFor="sex_id" color="primary.600">Sexo</FormLabel>
                        <Select {...register('sex_id', { required: true })}
                            focusBorderColor='primary.400' bg={'white'} variant={'outline'}
                        >
                            {sexes?.map(sex => {
                                return <option key={sex.id} value={sex.id} className={'capitalize'}>{sex.name}</option>;
                            })}
                        </Select>
                        <FormErrorMessage>
                            {errors.sex_id && errors.sex_id.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl mx={{base: 'none', md: 2}} mt={2}  isDisabled={loading} isInvalid={!!errors?.birth?.message} >
                        <FormLabel htmlFor="date" color="primary.600">Nacimiento</FormLabel>
                        <Input id="date"
                            type="date"
                            focusBorderColor='primary.400'
                            {...register('birth', { required: true })}
                        />
                        <FormErrorMessage>
                            {errors.birth && errors.birth.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={2}  isDisabled={loading} isInvalid={!!errors?.neutered?.message}>
                        <FormLabel htmlFor="neutered" color="primary.600" mb={4}>Esterilizado</FormLabel>
                        <Checkbox id="neutered" {...register('neutered')} mx={1}>Si</Checkbox>
                        <FormErrorMessage>
                            {errors.neutered && errors.neutered.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                    <FormControl isInvalid={!!errors?.pathology?.message} mt={2} isDisabled={loading}>
                        <FormLabel htmlFor="pathology" color="primary.600">Patología</FormLabel>
                        <Input
                            id="pathology"
                            type="text"
                            focusBorderColor='primary.400'
                            {...register('pathology', { required: true })}
                        />
                        <FormErrorMessage>
                            {errors.pathology && errors.pathology.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                    <FormControl isInvalid={!!errors?.chip_id?.message} mt={2} isDisabled={loading}>
                        <FormLabel htmlFor="chip_id" color="primary.600">Número de Chip</FormLabel>
                        <Input
                            id="chip_id"
                            type="text"
                            focusBorderColor='primary.400'
                            {...register('chip_id', { required: true })}
                        />
                        <FormErrorMessage>
                            {errors.chip_id && errors.chip_id.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex justifyContent={'flex-end'}>
                    <Button type='submit' mt={5} isDisabled={!isDirty} bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}>
                            Actualizar
                    </Button>
                </Flex>
            </form>
            <MortalityModal
                isOpen={openMortaliy}
                doUpdate={updateMortality}
                onClose={closeModal}
            />
            {
                chartModal &&
                <ChartModal
                    petId={Number(petId)}
                    isOpen={true}
                    onClose={closeChart}
                />
            }
        </Box>
    );
};
