import React, { useRef } from 'react';
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react';

interface IMortalityModal {
    isOpen: boolean;
    onClose: () => void;
    doUpdate: () => void;
}

export const MortalityModal = (props: IMortalityModal): JSX.Element => {
    const { isOpen, onClose, doUpdate } = props;
    const cancelRef = useRef<HTMLButtonElement>(null);

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        ¿Desea actualizar la mortalidad de esta mascota?
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            No
                        </Button>
                        <Button
                            color={'white'}
                            backgroundColor={'primary.300'}
                            _hover={{ backgroundColor: 'primary.300' }}
                            onClick={doUpdate}
                            ml={3}
                        >
                            Si
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
