import React, { useEffect, useMemo, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { IMedicalReviews, IPaginateResponse } from '../../../../interfaces';
import { Button, Text, useToast } from '@chakra-ui/react';
import { useFetch } from '../../../../hooks';
import { DataTable } from '../../../../components';
import { format } from 'date-fns';
import { ReviewsModal } from '../modals';
import { ImageModal } from '../modals/image-modal';

interface IMedicalReviewsTab {
    recordId?: string;
    patientId: number;
}

export interface ITestImageModal {
    isOpen: boolean;
    image: string;
}

export const MedicalReviews = (props: IMedicalReviewsTab): JSX.Element => {
    const { recordId, patientId } = props;
    const toast = useToast();

    const [page, setPage] = useState<number>(1);
    const [reviewId, setReviewId] = useState<number>(0);
    const [openReview, setOpenReview] = useState<boolean>(false);
    const [openTestImage, setOpenTestImage] = useState<ITestImageModal>({
        isOpen: false,
        image: '',
    });

    const { fetchData, loading, error, data} = useFetch<IPaginateResponse<IMedicalReviews>>(`/records/${recordId}/reviews/?page=${page}`, undefined, false);

    useEffect(() => {
        if (recordId) {
            fetchData();
        }
    }, [page]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const columns = useMemo<Column<IMedicalReviews>[]>(() => [
        {
            id: 'id',
            Header: 'Id',
            accessor: 'id',
            Cell: ({ value }: CellProps<IMedicalReviews>) => {
                return (
                    <Text>{value}</Text>
                );
            },
        },
        {
            id: 'createdAt',
            Header: 'Registrada',
            accessor: 'createdAt',
            Cell: ({ value }: CellProps<IMedicalReviews>) => {
                return (
                    <Text>{format(new Date(value), 'dd/LL/yyyy')}</Text>
                );
            },
        },
        {
            id: 'updatedAt',
            Header: 'Actualizada',
            accessor: 'updatedAt',
            Cell: ({ value }: CellProps<IMedicalReviews>) => {
                return (
                    <Text>{format(new Date(value), 'dd/LL/yyyy')}</Text>
                );
            },
        },
        {
            id: 'details',
            Header: 'Detalles',
            accessor: 'id',
            Cell: ({ value }: CellProps<IMedicalReviews>) => {
                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => {
                            setReviewId(value);
                            setOpenReview(true);
                        }}
                    >
                        Ver
                    </Button>
                );
            },
            disableSortBy: true,
        },
    ],  []);

    const doCloseReviewModal = (): void => {
        setOpenReview(false);
    };

    const doCloseTestImage = (): void => {
        setOpenTestImage({
            isOpen: false,
            image: '',
        });
    };

    return (
        <>
            <DataTable
                columns={columns}
                data={data?.items || []}
                loading={loading}
                searchTitle={'Búsqueda local'}
                numberOfPage={data?.numberOfPages || 1}
                currentPage={data?.currentPage || 1}
                handlePagination={page => setPage(page)}
            />
            {
                openReview &&
                <ReviewsModal
                    id={reviewId}
                    patientId={patientId}
                    isOpen={true}
                    setOpenTestImage={setOpenTestImage}
                    onClose={doCloseReviewModal}
                />
            }

            <ImageModal
                image={openTestImage.image}
                isOpen={openTestImage.isOpen}
                onClose={doCloseTestImage}
            />
        </>
    );
};
