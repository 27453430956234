import React, { useEffect, useMemo, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { IPaginateResponse, IVaccines } from '../../../../interfaces';
import { Text, useToast } from '@chakra-ui/react';
import { useFetch } from '../../../../hooks';
import { DataTable } from '../../../../components';
import { format } from 'date-fns';

interface IVaccineTab {
    petId?: string;
}

interface IVaccinesOnPets {
    createdAt: string;
    expiration_date: string;
    image?: string;
    medical_review_id: number;
    next_vaccine: string;
    number_of_batch: string;
    patient_id: number;
    updatedAt: string;
    vaccine: IVaccines;
    vaccine_id: number;
}

export const Vaccines = (props: IVaccineTab): JSX.Element => {
    const { petId } = props;
    const toast = useToast();

    const [page, setPage] = useState<number>(1);
    const { fetchData, loading, error, data} = useFetch<IPaginateResponse<IVaccinesOnPets>>(`/patients/${petId}/vaccines/?page=${page}`, undefined, false);

    useEffect(() => {
        if (petId) {
            fetchData();
        }
    }, [page]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const columns = useMemo<Column<IVaccinesOnPets>[]>(() => [
        {
            id: 'name',
            Header: 'Nombre',
            accessor: 'vaccine',
            Cell: ({ value }: CellProps<IVaccinesOnPets>) => {
                return (
                    <Text>{value.name}</Text>
                );
            },
        },
        {
            id: 'next_vaccine',
            Header: 'Próxima Vacuna',
            accessor: 'next_vaccine',
            Cell: ({ value }: CellProps<IVaccinesOnPets>) => {
                return (
                    <Text>{format(new Date(value), 'dd/LL/yyyy')}</Text>
                );
            },
        },
        {
            id: 'expiration_date',
            Header: 'Fecha de Expiración',
            accessor: 'expiration_date',
            Cell: ({ value }: CellProps<IVaccinesOnPets>) => {
                return (
                    <Text>{format(new Date(value), 'dd/LL/yyyy')}</Text>
                );
            },
        },
        {
            id: 'number_of_batch',
            Header: 'Número de Lote',
            accessor: 'number_of_batch',
            Cell: ({ value }: CellProps<IVaccinesOnPets>) => {
                return (
                    <Text>{value}</Text>
                );
            },
        },
        {
            id: 'createdAt',
            Header: 'Registrada',
            accessor: 'createdAt',
            Cell: ({ value }: CellProps<IVaccinesOnPets>) => {
                return (
                    <Text>{format(new Date(value), 'dd/LL/yyyy')}</Text>
                );
            },
        },
    ],  []);

    return (
        <DataTable
            columns={columns}
            data={data?.items || []}
            loading={loading}
            searchTitle={'Búsqueda local'}
            numberOfPage={data?.numberOfPages || 1}
            currentPage={data?.currentPage || 1}
            handlePagination={page => setPage(page)}
        />
    );
};
