import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDoctor } from '../../interfaces';
import { IDoctorReducer } from './IDoctorReducer';
import { getDoctor } from './DoctorThunks';

const initialState: IDoctorReducer = {
    doctor: undefined,
    loading: false,
    success: undefined,
    error: undefined
};

export const doctorSlice = createSlice({
    name: 'doctor',
    initialState,
    reducers: {
        resetDoctorState: () => initialState,
    },
    extraReducers: builder => {
        // getDoctor
        builder.addCase(getDoctor.pending, (state: IDoctorReducer) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(getDoctor.fulfilled, (state: IDoctorReducer, { payload }: PayloadAction<IDoctor>) => {
            state.doctor = payload;
            state.loading = false;
        });
        builder.addCase(getDoctor.rejected, (state: IDoctorReducer, action) => {
            state.loading = false;
            state.error = {
                ...action.error,
                message: action.error.message || 'Ha ocurrido un error'
            };
        });
    }
});

export const { resetDoctorState } = doctorSlice.actions;
