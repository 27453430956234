import React, { useEffect, useMemo, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { IPaginateResponse, IRecord } from '../../../../interfaces';
import { Button, Text, useToast } from '@chakra-ui/react';
import { useFetch } from '../../../../hooks';
import { DataTable } from '../../../../components';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

interface IRecordTab {
    petId?: string;
}

interface IRecordOnPets {
    record: IRecord
}

export const Records = (props: IRecordTab): JSX.Element => {
    const { petId } = props;
    const navigate = useNavigate();
    const toast = useToast();

    const [page, setPage] = useState<number>(1);
    const { fetchData, loading, error, data} = useFetch<IPaginateResponse<IRecordOnPets>>(`/patients/${petId}/records/?page=${page}`, undefined, false);

    useEffect(() => {
        if (petId) {
            fetchData();
        }
    }, [page]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const columns = useMemo<Column<IRecordOnPets>[]>(() => [
        {
            id: 'id',
            Header: 'Id',
            accessor: 'record',
            Cell: ({ value }: CellProps<IRecordOnPets>) => {
                return (
                    <Text>{value.id}</Text>
                );
            },
        },
        {
            id: 'createdAt',
            Header: 'Registrada',
            accessor: 'record',
            Cell: ({ value }: CellProps<IRecordOnPets>) => {
                return (
                    <Text>{format(new Date(value.createdAt), 'dd/LL/yyyy')}</Text>
                );
            },
        },
        {
            id: 'updatedAt',
            Header: 'Actualizada',
            accessor: 'record',
            Cell: ({ value }: CellProps<IRecordOnPets>) => {
                return (
                    <Text>{format(new Date(value.updatedAt), 'dd/LL/yyyy')}</Text>
                );
            },
        },
        {
            id: 'details',
            Header: 'Resumen',
            accessor: 'record',
            Cell: ({ value }: CellProps<IRecordOnPets>) => {
                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => navigate(`/historia/${value.id}`, { state: { patientId: petId }})}>
                        Ver
                    </Button>
                );
            },
            disableSortBy: true,
        },
    ],  []);

    return (
        <DataTable
            columns={columns}
            data={data?.items || []}
            loading={loading}
            searchTitle={'Búsqueda local'}
            numberOfPage={data?.numberOfPages || 1}
            currentPage={data?.currentPage || 1}
            handlePagination={page => setPage(page)}
        />
    );
};
