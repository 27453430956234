import React from 'react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { recordSchemaType } from './record-schema';
import { FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';

interface IBasicInfo {
    loading: boolean;
    register: UseFormRegister<recordSchemaType>;
    formState: FormState<recordSchemaType>;
}

export const BasicInfo = (props: IBasicInfo): JSX.Element => {
    const { loading, register, formState } = props;
    const { errors } = formState;

    return (
        <>
            <FormControl isInvalid={!!errors?.reason?.message} mt={2}  isDisabled={loading}>
                <FormLabel htmlFor="reason" color="primary.600">Motivo de Consulta</FormLabel>
                <Textarea
                    id="reason"
                    minH={300}
                    focusBorderColor='primary.400'
                    {...register('reason', { required: true })}
                />
                <FormErrorMessage>
                    {errors.reason && errors.reason.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl mt={2}  isDisabled={loading} isInvalid={!!errors?.revelevant_clinic?.message} >
                <FormLabel htmlFor="revelevant_clinic" color="primary.600">Clínica Relevante</FormLabel>
                <Textarea
                    id="revelevant_clinic"
                    minH={300}
                    focusBorderColor='primary.400'
                    {...register('revelevant_clinic', { required: true })}
                />
                <FormErrorMessage>
                    {errors.revelevant_clinic && errors.revelevant_clinic.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors?.diagnosis?.message} mt={2}  isDisabled={loading}>
                <FormLabel htmlFor="diagnosis" color="primary.600">Diagnóstico</FormLabel>
                <Textarea
                    minH={300}
                    id="diagnosis"
                    focusBorderColor='primary.400'
                    {...register('diagnosis', { required: true })}
                />
                <FormErrorMessage>
                    {errors.diagnosis && errors.diagnosis.message}
                </FormErrorMessage>
            </FormControl>
        </>
    );
};
