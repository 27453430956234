import React from 'react';

import { RouteObject } from 'react-router-dom';
import { ILinkItem } from '../../interfaces';

import { IoPawSharp } from 'react-icons/io5';
import { FiHome } from 'react-icons/fi';
import { BsJournalMedical } from 'react-icons/bs';
import { TbBuildingHospital } from 'react-icons/tb';

import {
    Account,
    AdminMain,
    ChangePassword,
    DetailsUser,
    EditAccount,
    NotFound,
    Owners,
    PatientDetails,
    Patients,
    Tests,
    Users,
    Surgeries,
    Species,
    Races,
    OwnerDetails,
    Sexes,
    Waiting,
    InProgress,
    Finished,
    RecordDetails,
    Recipe,
} from '../../pages';
import { Vaccines } from '../../pages/shared/vaccines/vaccines';

export const adminLinks: ILinkItem[] = [
    { name: 'Inicio', icon: FiHome, path: '/' },
    { name: 'Clínica',
        icon: TbBuildingHospital,
        childrens: [
            { name: 'Usuarios', path: '/usuarios' },
            { name: 'Vacunas', path: '/vacunas' },
            { name: 'Examenes', path: '/examenes' },
            { name: 'Cirugías', path: '/cirugias' },
            { name: 'Especies', path: '/especies' },
            { name: 'Sexo', path: '/sexos' },
        ]
    },
    { name: 'Consultas',
        icon: BsJournalMedical,
        childrens: [
            { name: 'En Sala', path: '/sala' },
            { name: 'En Progreso', path: '/progreso' },
            { name: 'Finalizadas', path: '/finalizadas' },
        ]
    },
    { name: 'Pacientes',
        icon: IoPawSharp,
        childrens: [
            { name: 'Pacientes', path: '/pacientes' },
            { name: 'Propietarios', path: '/propietarios' },
        ]
    },
];

export const adminRoutes: RouteObject[] = [
    {
        path: '/',
        element: <AdminMain />,
    },
    {
        path: '/sala',
        element: <Waiting />,
    },
    {
        path: '/progreso',
        element: <InProgress />,
    },
    {
        path: '/finalizadas',
        element: <Finished />,
    },
    {
        path: '/usuarios',
        element: <Users />,
    },
    {
        path: '/usuario/:id',
        element: <DetailsUser />,
    },
    {
        path: '/vacunas',
        element: <Vaccines />,
    },
    {
        path: '/examenes',
        element: <Tests />,
    },
    {
        path: '/cirugias',
        element: <Surgeries />,
    },
    {
        path: '/especies',
        element: <Species />,
    },
    {
        path: '/razas/:id',
        element: <Races />,
    },
    {
        path: '/sexos',
        element: <Sexes />,
    },
    {
        path: '/pacientes',
        element: <Patients />,
    },
    {
        path: '/paciente/:id',
        element: <PatientDetails />,
    },
    {
        path: '/historia/:id',
        element: <RecordDetails />,
    },
    {
        path: '/recipe/:record_id',
        element: <Recipe />,
    },
    {
        path: '/propietarios',
        element: <Owners />,
    },
    {
        path: '/propietario/:id',
        element: <OwnerDetails />,
    },
    {
        path: '/cuenta',
        element: <Account />,
    },
    {
        path: '/cuenta/actualizar',
        element: <EditAccount />,
    },
    {
        path: '/cambiar/contraseña',
        element: <ChangePassword />,
    },
    {
        path: '*',
        element: <NotFound />,
    },
];
