import React from 'react';
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { searchOwnerSchema, searchOwnerSchemaType } from './search-owner-schema';

interface IOwnerPatient {
    getData: (data: searchOwnerSchemaType) => void;
    loading?: boolean;
}

export const FormSearch = ({ getData, loading }: IOwnerPatient): JSX.Element => {

    const { register, handleSubmit, formState } = useForm<searchOwnerSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(searchOwnerSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            first_name: '',
            last_name: '',
            dni: '',
            name: ''
        }
    });

    const onSubmit = (schema: searchOwnerSchemaType): void => {
        getData({
            first_name: schema.first_name && schema.first_name.length > 0 ? schema.first_name : undefined,
            last_name: schema.last_name && schema.last_name.length > 0 ? schema.last_name : undefined,
            dni: schema.dni && schema.dni.length > 0 ? schema.dni : undefined,
            name: schema.name && schema.name.length > 0 ? schema.name : undefined,
        });
    };

    return (
        <Box
            py={{ base: '0', sm: '8' }}
            px={{ base: '4', sm: '10' }}
            bg={'white'}
            borderRadius={{ base: 'none', sm: 'xl' }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                    <FormControl isInvalid={!!formState?.errors?.first_name?.message} mt={2} isDisabled={loading}>
                        <FormLabel htmlFor="email" color="primary.600">Nombre</FormLabel>
                        <Input
                            id="first_name"
                            type="text"
                            placeholder={'Pedro'}
                            focusBorderColor='primary.400'
                            {...register('first_name', { required: true })}
                        />
                        <FormErrorMessage>
                            {formState?.errors.first_name && formState?.errors.first_name.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl ml={{base: 'none', md: 2}} isInvalid={!!formState?.errors?.last_name?.message} mt={2} isDisabled={loading}>
                        <FormLabel htmlFor="last_name" color="primary.600">Apellido</FormLabel>
                        <Input
                            id="last_name"
                            type="text"
                            placeholder={'Perez'}
                            focusBorderColor='primary.400'
                            {...register('last_name', { required: true })}
                        />
                        <FormErrorMessage>
                            {formState?.errors.last_name && formState?.errors.last_name.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                    <FormControl isInvalid={!!formState?.errors?.dni?.message} mt={2} isDisabled={loading}>
                        <FormLabel htmlFor="dni" color="primary.600">C.I</FormLabel>
                        <Input
                            id="dni"
                            type="number"
                            placeholder={'12345678'}
                            focusBorderColor='primary.400'
                            {...register('dni', { required: true })}
                        />
                        <FormErrorMessage>
                            {formState?.errors.dni && formState?.errors.dni.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex flexDirection={{ base: 'column', md: 'row'}} my={1}>
                    <FormControl isInvalid={!!formState?.errors?.name?.message} mt={2} isDisabled={loading}>
                        <FormLabel htmlFor="name" color="primary.600">Nombre de la Mascota</FormLabel>
                        <Input
                            id="pet_name"
                            type="text"
                            placeholder={'Luna'}
                            focusBorderColor='primary.400'
                            {...register('name', { required: true })}
                        />
                        <FormErrorMessage>
                            {formState?.errors.name && formState?.errors.name.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex justifyContent={'flex-end'}>
                    <Button
                        type='submit'
                        mt={5}
                        isDisabled={!formState.isDirty || loading}
                        isLoading={loading}
                        bg={'primary.400'}
                        color={'white'}
                        _hover={{bg: 'primary.500'}}
                    >
                        Buscar
                    </Button>
                </Flex>
            </form>
        </Box>
    );
};
