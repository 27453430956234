import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, useToast, useDisclosure } from '@chakra-ui/react';

import { usePost } from '../../../hooks';
import { IOwner } from '../../../interfaces/network/res/IOwner';
import { IOwnerReq, IPatient, IPatientReq } from '../../../interfaces';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { addPatientSchema, addPatientSchemaType } from './add-patient-schema';
import { format } from 'date-fns';
import { ModalDoctor } from './modal-doctor';
import { OwnerForm } from './owner-form';
import { PetForm } from './pet-form';
import { SearchOwner } from '../../../components';

interface IPatientInfo {
    owner?: IOwnerReq;
    patient: IPatientReq;
}

export const AddPatient = (): JSX.Element => {
    const [ownerInfo, setOwner] = useState<{owner?: IOwner, dni: string} | undefined>(undefined);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [patient, setPatient] = useState<IPatientInfo | undefined>(undefined);

    const {
        loading,
        data,
        doRequest,
        error
    } = usePost<IOwner, IOwnerReq & IPatientReq & { doctor_id?: number}>('/owners');

    const {
        loading: loadingPatient,
        data: patientCreated,
        doRequest: createPatient,
        error: errorPatient
    } = usePost<IPatient, IPatientReq & { doctor_id?: number}>('/patients');

    const { isOpen, onToggle } = useDisclosure();
    const toast = useToast();

    const { register, handleSubmit, formState, reset, watch } = useForm<addPatientSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(addPatientSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            // owner
            first_name: '',
            last_name: '',
            dni: '',
            address: '',
            phone: '',
            email: '',
            occupation: '',
            housing: '',
            other_pets: false,

            // patient
            race_id: 1,
            specie_id: 1,
            name: '',
            birth: format(new Date(), 'dd/LL/yyyy'),
            color: '',
            sex_id: 1,
            neutered: false,
        }
    });

    useEffect(() => {
        reset({
            first_name: ownerInfo?.owner?.user?.first_name || '',
            last_name: ownerInfo?.owner?.user?.last_name || '',
            dni: ownerInfo?.owner?.user?.dni || ownerInfo?.dni,
            address: ownerInfo?.owner?.address || '',
            phone: ownerInfo?.owner?.phone || '',
            email: ownerInfo?.owner?.user?.email || '',
            occupation: ownerInfo?.owner?.occupation || '',
            housing: ownerInfo?.owner?.housing.toUpperCase() || '',
            other_pets: ownerInfo?.owner?.other_pets || false,
        });
    }, [ownerInfo]);

    const onSubmit = (schema: addPatientSchemaType): void => {
        setOpenModal(true);
        if(ownerInfo?.owner?.id) {
            setPatient({
                patient: {
                    race_id: schema.race_id,
                    specie_id: schema.specie_id,
                    name: schema.name,
                    birth: new Date(schema.birth),
                    color: schema.color,
                    sex_id: schema.sex_id,
                    neutered: schema.neutered || false,
                    owner_id: ownerInfo?.owner.id,
                }
            });
        } else {
            setPatient({
                owner: {
                    first_name: schema.first_name,
                    last_name: schema.last_name,
                    dni: schema.dni,
                    address: schema.address,
                    phone: schema.phone,
                    email: schema.email,
                    occupation: schema.occupation,
                    housing: schema.housing.toUpperCase(),
                    other_pets: schema.other_pets || false,
                },
                patient: {
                    race_id: schema.race_id,
                    specie_id: schema.specie_id,
                    name: schema.name,
                    birth: new Date(schema.birth),
                    color: schema.color,
                    sex_id: schema.sex_id,
                    neutered: schema.neutered || false,
                }
            });
        }
    };

    useEffect(() => {
        if ((!loading && data) || (!loadingPatient && patientCreated)) {
            onToggle();
            toast({
                description: 'Paciente registrado exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });

            reset({
                // owner
                first_name: '',
                last_name: '',
                dni: '',
                address: '',
                phone: '',
                email: '',
                occupation: '',
                housing: '',
                other_pets: false,

                // patient
                race_id: 1,
                specie_id: 1,
                name: '',
                birth: format(new Date(), 'dd/LL/yyyy'),
                color: '',
                sex_id: 1,
                neutered: false,
            });
        }
    }, [loading, loadingPatient]);

    useEffect(() => {
        if((!loading && Boolean(error)) || (!loadingPatient && Boolean(errorPatient))){
            toast({
                description: errorPatient?.message || 'Error registrando paciente',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading, loadingPatient]);

    const closeModal = (): void => {
        setOpenModal(false);
    };

    const onSearch = (): void => {
        if(!isOpen) {
            onToggle();
        }
    };

    const getDoctor = (doctor_id?: number): void => {
        if (!loading && patient && patient.owner) {
            doRequest({...patient.owner, ...patient.patient, doctor_id});
        } else if (!loadingPatient && patient && !patient?.owner) {
            createPatient({...patient.patient, doctor_id});
        }
        setPatient(undefined);
    };

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'} >
                Registar Paciente
            </Heading>
            <SearchOwner setOwner={setOwner} onSearch={onSearch} />
            {
                isOpen && (
                    <Box py={{ base: '0', sm: '8' }} px={{ base: '4', sm: '10' }}
                        bg={'white'} borderRadius={{ base: 'none', sm: 'xl' }}
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <OwnerForm
                                title={'Datos Propietario'}
                                loading={loading || loadingPatient}
                                isReadOnly={Boolean(ownerInfo?.owner?.id)}
                                register={register}
                                formState={formState}
                            />
                            <PetForm
                                title={'Datos Mascota'}
                                loading={loading || loadingPatient}
                                register={register}
                                formState={formState}
                                watch={watch}
                            />
                            <Flex justifyContent={'flex-end'}>
                                <Button
                                    type='submit'
                                    mt={5}
                                    isDisabled={!formState.isDirty || loading || loadingPatient}
                                    isLoading={loading || loadingPatient}
                                    bg={'primary.400'}
                                    color={'white'}
                                    _hover={{bg: 'primary.500'}}
                                >
                                    Registar
                                </Button>
                            </Flex>
                        </form>
                        <ModalDoctor
                            isOpen={openModal}
                            onClose={closeModal}
                            setData={getDoctor}
                        />
                    </Box>
                )
            }
        </>
    );
};
