import React, { useEffect, useRef } from 'react';
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    useToast
} from '@chakra-ui/react';
import { useDelete } from '../../../../hooks';

interface IDeleteModal {
    isOpen: boolean;
    type?: 'test' | 'vaccine';
    medical_review_id: number;
    itemId: number
    onClose: () => void;
    onDelete: () => void;
}

interface ITestDeleteReq {
    test_id: number;
}

interface IVaccineDeleteReq {
    vaccine_id: number;
}

export const DeleteModal = (props: IDeleteModal): JSX.Element => {
    const { isOpen, type, medical_review_id, itemId } = props;
    const { onClose, onDelete } = props;
    const cancelRef = useRef<HTMLButtonElement>(null);

    const toast = useToast();

    const { doDelete: doDeleteTest, loading: loadingTest, data: dataTest, error: errorTest } = useDelete<ITestDeleteReq>(`records/${medical_review_id}/test/delete/${itemId}`);
    const { doDelete: doDeleteVaccine, loading: loadingVaccine, data: dataVaccine, error: errorVaccine } = useDelete<IVaccineDeleteReq>(`records/${medical_review_id}/vaccine/delete/${itemId}`);

    const title = type === 'test' ? '¿Desea Eliminar este examen?' : '¿Desea Eliminar esta vacuna?';

    const doDelete = (): void => {
        if (itemId) {
            if (type === 'test') {
                doDeleteTest();
            } else {
                doDeleteVaccine();
            }
        }
    };

    console.log('dataVaccine: ', dataVaccine);

    useEffect(() => {
        if (!loadingTest && dataTest) {
            onDelete();
            onClose();
            toast({
                description: 'Examen eliminado exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingTest]);

    useEffect(() => {
        if (!loadingTest && Boolean(errorTest)) {
            toast({
                description: errorTest?.message || 'Error eliminando examen',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingTest]);

    useEffect(() => {
        if (!loadingVaccine && dataVaccine) {
            onDelete();
            onClose();
            toast({
                description: 'Vacuna eliminado exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingVaccine]);

    useEffect(() => {
        if (!loadingVaccine && Boolean(errorVaccine)) {
            toast({
                description: errorVaccine?.message || 'Error eliminando vacuna',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingVaccine]);

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {title}
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            No
                        </Button>
                        <Button
                            color={'white'}
                            backgroundColor={'primary.300'}
                            _hover={{ backgroundColor: 'primary.300' }}
                            onClick={doDelete}
                            ml={3}
                        >
                            Si
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
