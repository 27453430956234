import * as yup from 'yup';

export const vaccineSchema = yup.object({
    number_of_batch:
        yup.string()
            .label('number_of_batch')
            .min(5, 'número de lote muy corto, debe tener al menos 5 caracteres')
            .required('Este campo es requerido'),
    expiration_date:
        yup.string()
            .label('expiration_date')
            .required('Este campo es requerido'),
    next_vaccine:
        yup.string()
            .label('next_vaccine')
            .required('Este campo es requerido'),
});

export type vaccineSchemaType = yup.InferType<typeof vaccineSchema>
