import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
    Flex,
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    useToast,
    Box,
    Heading,
    Divider,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Progress,
    Button,
    FormControl,
    FormErrorMessage,
    Textarea,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { MdImageNotSupported } from 'react-icons/md';
import { MdImage } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa';

import { useFetch, usePut } from '../../../../hooks';
import { IMedicalReviews, IVaccines } from '../../../../interfaces';
import { ITestImageModal } from '../tabs';
import { EditPhysiologicalConstantsModal } from './EditPhysiologicalConstants';
import { EditvaccinesModal } from './EditVaccine';
import { AddVaccineModal } from './add-vaccine-modal';
import { AddTestModal } from './add-test-modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observationSchema, observationSchemaType } from './observation-schema';
import { DeleteModal } from './delete-modal';

interface IReviewsModal {
    id: number;
    patientId: number;
    isOpen: boolean;
    setOpenTestImage: (info: ITestImageModal) => void;
    onClose: () => void;
}

export const ReviewsModal = (props: IReviewsModal): JSX.Element => {
    const { id, patientId, isOpen, setOpenTestImage, onClose } = props;
    const imageRef = useRef<HTMLInputElement>(null);
    const testIdRef = useRef<number>(0);

    const [openConstant, setOpenConstant] = useState<boolean>(false);
    const [openVaccine, setOpenVaccine] = useState<boolean>(false);
    const [selectedVaccine, setSelectedVaccine] = useState<{
        vaccine: IVaccines;
        expiration_date: string;
        next_vaccine: string;
        number_of_batch: string;
        medical_review_id?: number;
        vaccine_id?: number;
    } | undefined>(undefined);
    const [addVaccineModal, setAddVaccineModal] = useState<boolean>(false);
    const [addTestModal, setAddTestModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteTypeModal, setDeleteTypeModal] = useState<'test' | 'vaccine' | undefined>(undefined);
    const [itemDeleteId, setItemDeleteId] = useState<number | undefined>(undefined);

    const toast = useToast();
    const { fetchData, loading, error, data} = useFetch<IMedicalReviews>(`/records/reviews/${id}`, undefined, false);
    const { doUpdate, loading: loadingTest, error: errorTest, data: testUpdate } = usePut('', {headers: {'Content-Type': 'multipart/form-data'}});
    const { doUpdate: doUpdateObservation, loading: loadingObservation, error: errorObservation, data: observationUpdate } = usePut(`/records/reviews/${data?.id}/observation`);

    const { register, handleSubmit, formState: { errors }, reset } = useForm<observationSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(observationSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            observation: data?.observation || 'No existen observaciones registradas'
        }
    });

    useEffect(() => {
        if (id && !loading) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Error al cargar datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!loadingTest && testUpdate) {
            fetchData();
            toast({
                description: 'Datos actualizados exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingTest]);

    useEffect(() => {
        if (!loadingTest && Boolean(errorTest)) {
            toast({
                description: errorTest?.message || 'Error actualizando datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingTest]);

    useEffect(() => {
        if (!loadingObservation && observationUpdate) {
            fetchData();
            toast({
                description: 'Datos actualizados exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingObservation]);

    useEffect(() => {
        if (!loadingObservation && Boolean(errorObservation)) {
            toast({
                description: errorTest?.message || 'Error actualizando datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingObservation]);

    useEffect(() => {
        if (selectedVaccine) {
            doOpenVaccine();
        }
    }, [selectedVaccine]);

    // refreshing the form
    useEffect(() => {
        if (Boolean(data)) {
            reset({
                observation: data?.observation || 'No existen observaciones registradas',
            });
        }
    }, [data]);

    const onClickItem = (id: number): void => {
        testIdRef.current = id;
        imageRef.current?.click();
    };

    const onChange = (file: ChangeEvent): void => {
        const { files } = file.target as HTMLInputElement;
        if (files && files.length !== 0) {

            const fileSize = files[0].size;
            const fileMB = fileSize / 1000000;
            if (fileMB > 4) {
                alert('Por favor, seleccione un archivo con peso menor a 4MB.');
                if(imageRef.current) {
                    imageRef.current.value = '';
                }
                return;
            }

            const formData = new FormData();
            formData.append('file', files[0]);
            doUpdate(formData, `/records/reviews/${id}/test/${testIdRef.current}/image`);
        }
    };

    if(loading || !data) {
        return (
            <Flex alignItems={'center'} height={'lg'} flexDirection={'column'}>
                <Spinner
                    thickness='3px'
                    speed='0.65s'
                    emptyColor='primary.100'
                    color='primary.400'
                    size='xl'
                />
                <Text mt={2} size='sm' noOfLines={1} ml={'1'} mb={'5'} >
                    Cargando datos ...
                </Text>
            </Flex>
        );
    }

    const onSubmit = (schema: observationSchemaType): void => {
        if (data) {
            doUpdateObservation({ observation: schema.observation });
        }
    };


    const doOpenConstants = (): void => {
        setOpenConstant(true);
    };

    const doCloseConstants = (): void => {
        setOpenConstant(false);
    };

    const doOpenVaccine = (): void => {
        setOpenVaccine(true);
    };

    const doCloseVaccine = (): void => {
        setOpenVaccine(false);
    };

    const doOpenAddVaccine = (): void => {
        setAddVaccineModal(true);
    };

    const doCloseAddVaccine = (): void => {
        setAddVaccineModal(false);
    };

    const doOpenAddTest = (): void => {
        setAddTestModal(true);
    };

    const doCloseAddTest = (): void => {
        setAddTestModal(false);
    };

    const doOpenDeleteTestModal = (itemId: number): void => {
        setDeleteTypeModal('test');
        setItemDeleteId(itemId);
        setDeleteModal(true);
    };

    const doOpenDeleteVaccineModal = (itemId: number): void => {
        setDeleteTypeModal('vaccine');
        setItemDeleteId(itemId);
        setDeleteModal(true);
    };

    const doCloseDeleteModal = (): void => {
        setDeleteModal(false);
    };

    const { createdAt, doctor, physiological_constants, tests, vaccines } = data;
    const { first_name, last_name } = doctor.user;
    const { temperature, weight, body_condition, breathing_rate, heart_rate } = physiological_constants;


    if (data && openConstant) {
        return (
            <EditPhysiologicalConstantsModal
                isOpen={true}
                id={physiological_constants.id}
                physiologicalConstants={physiological_constants}
                onClose={doCloseConstants}
                onUpdate={() => {
                    fetchData();
                    doCloseConstants();
                }}
            />
        );
    }

    if (data && addTestModal) {
        return (
            <AddTestModal
                isOpen={true}
                reviewId={id}
                patientId={patientId}
                onClose={doCloseAddTest}
                onAdd={fetchData}
            />
        );
    }

    if (data && addVaccineModal) {
        return (
            <AddVaccineModal
                isOpen={true}
                reviewId={id}
                patientId={patientId}
                onClose={doCloseAddVaccine}
                onAdd={fetchData}
            />
        );
    }

    if (data && deleteModal) {
        return (
            <DeleteModal
                isOpen={deleteModal}
                type={deleteTypeModal}
                medical_review_id={id}
                itemId={itemDeleteId || 0}
                onClose={doCloseDeleteModal}
                onDelete={fetchData}
            />
        );
    }

    if (data && openVaccine && selectedVaccine) {
        return (
            <EditvaccinesModal
                isOpen={true}
                medical_review_id={selectedVaccine.medical_review_id || 0}
                vaccine={selectedVaccine}
                onClose={doCloseVaccine}
                onUpdate={() => {
                    fetchData();
                    doCloseVaccine();
                    setSelectedVaccine(undefined);
                }}
            />
        );
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}>
            <ModalOverlay />
            <ModalContent pb={10}>
                {loadingTest && <Progress size='sm' isIndeterminate colorScheme='purple' />}
                <ModalHeader>
                    <Heading as='h1' size='lg'>
                        Revisión
                    </Heading>
                    <Divider />
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box mb={10}>
                        <Text fontSize={'lg'}>
                            <strong>Médico tratante: </strong>
                            {first_name} {last_name}
                        </Text>
                        <Box>
                            <Text>
                                <strong>Fecha: </strong>
                                {format(new Date(createdAt), ' dd MMM yyyy')}
                            </Text>
                            <Text>
                                <strong>Hora: </strong>
                                {format(new Date(createdAt), ' hh:mm b')}
                            </Text>
                        </Box>
                        <Divider />
                    </Box>
                    <Box mb={5} display={'flex'} justifyContent={'space-between'}>
                        <Box>
                            <Heading as='h2' size='md' mb={3}>
                                <Flex>
                                    <Text mr={'2'}>Constantes Fisiológicas</Text>
                                    <Button variant="link" color="primary.600" size="sm" onClick={doOpenConstants}>
                                        Editar
                                    </Button>
                                </Flex>
                            </Heading>
                            <Flex flexDir={'row'}>
                                <Box>
                                    <Text my={.5}>
                                        <strong>Condición Corporal: </strong>
                                        {body_condition}
                                    </Text>
                                    <Text my={.5}>
                                        <strong>Frecuencia Cardiaca: </strong>
                                        {heart_rate.toFixed(2)}
                                    </Text>
                                    <Text my={.5}>
                                        <strong>Frecuencia Respiratoria: </strong>
                                        {breathing_rate.toFixed(2)}
                                    </Text>
                                </Box>
                                <Box ml={30}>
                                    <Text my={.5}>
                                        <strong>Temperatura: </strong>
                                        {temperature.toFixed(2)} °C
                                    </Text>
                                    <Text my={.5}>
                                        <strong>Peso: </strong>
                                        {weight.toFixed(2)} Kg
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                    <Divider />
                    <Box mb={2}>
                        <Heading as='h2' size='md' mb={3}>
                            <Flex>
                                <Text mr={'2'}>Exámenes</Text>
                                <Button variant="link" mt={.5} color="primary.600" size="sm" onClick={doOpenAddTest}>
                                    Agregar
                                </Button>
                            </Flex>
                        </Heading>
                        {
                            tests.length === 0
                                ? (
                                    <Flex justifyContent={'center'} alignItems={'center'}>
                                        <Text>No existen exámenes registrados</Text>
                                    </Flex>
                                )
                                : (
                                    <TableContainer>
                                        <Table size='sm'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Nombre</Th>
                                                    <Th>Imagen</Th>
                                                    <Th>Editar Imagen</Th>
                                                    <Th>Eliminar</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {tests.map(test => {
                                                    return (
                                                        <Tr key={test.test.id}>
                                                            <Td>{test.test.name}</Td>
                                                            <Td>
                                                                {
                                                                    test.image ? (
                                                                        <Button variant="link" color="primary.600" size="sm" onClick={() => {
                                                                            setOpenTestImage({
                                                                                isOpen: true,
                                                                                image: test.image || ''
                                                                            });
                                                                        }}>
                                                                            <MdImage size={25} />
                                                                        </Button>
                                                                    ) : (
                                                                        <Button variant="link" color="primary.600" size="sm" onClick={() => onClickItem(test.test.id)}>
                                                                            <MdImageNotSupported size={25} />
                                                                        </Button>
                                                                    )
                                                                }
                                                                <input
                                                                    type='file'
                                                                    accept="image/png, image/jpeg"
                                                                    onChange={(e) => onChange(e)}
                                                                    ref={imageRef}
                                                                    style={{ display: 'none' }}
                                                                />
                                                            </Td>
                                                            <Td>
                                                                {
                                                                    test.image ? (
                                                                        <Button variant="link" color="primary.600" size="sm" onClick={() => onClickItem(test.test.id)}>
                                                                            <FaEdit size={25} />
                                                                        </Button>
                                                                    ) : '--'
                                                                }
                                                            </Td>
                                                            <Td>
                                                                <Button color="red.600" size="sm" onClick={() => doOpenDeleteTestModal(test.test.id)}>
                                                                    <FaTrash size={23} />
                                                                </Button>
                                                            </Td>
                                                        </Tr>
                                                    );
                                                })}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                )
                        }
                    </Box>
                    <Divider />
                    <Box mb={2}>
                        <Heading as='h2' size='md' mb={3}>
                            <Flex>
                                <Text mr={'2'}>Vacunas</Text>
                                <Button variant="link" mt={.5} color="primary.600" size="sm" onClick={doOpenAddVaccine}>
                                    Agregar
                                </Button>
                            </Flex>
                        </Heading>
                        {
                            vaccines.length === 0
                                ? (
                                    <Flex justifyContent={'center'} alignItems={'center'}>
                                        <Text>No existen vacunas registradas</Text>
                                    </Flex>
                                )
                                : (
                                    <TableContainer>
                                        <Table size='sm'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Nombre</Th>
                                                    <Th>Próxima Vacuna</Th>
                                                    <Th>Fecha de Expiración</Th>
                                                    <Th>Número de Lote</Th>
                                                    <Th>Accion</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {vaccines.map(vaccine => {
                                                    return (
                                                        <Tr key={vaccine.vaccine.id}>
                                                            <Td>{vaccine.vaccine.name}</Td>
                                                            <Td>{format(new Date(vaccine.next_vaccine), 'dd/LL/yyyy')}</Td>
                                                            <Td>{format(new Date(vaccine.expiration_date), 'dd/LL/yyyy')}</Td>
                                                            <Td>{vaccine.number_of_batch}</Td>
                                                            <Td>
                                                                <Button color="primary.600" size="sm" onClick={() => setSelectedVaccine(vaccine)}>
                                                                    Editar
                                                                </Button>
                                                                <Button ml={4} color="red.600" size="sm" onClick={() => doOpenDeleteVaccineModal(vaccine.vaccine_id || 0)}>
                                                                    Eliminar
                                                                </Button>
                                                            </Td>
                                                        </Tr>
                                                    );
                                                })}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                )
                        }
                    </Box>
                    <Divider />
                    <Box mt={4}>
                        <Heading as='h2' size='md' mb={3}>
                            Observaciones
                        </Heading>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl isInvalid={!!errors?.observation?.message} mt={2} isDisabled={loading}>
                                <Textarea
                                    minH={300}
                                    id="observation"
                                    focusBorderColor='primary.400'
                                    {...register('observation', { required: true })}
                                />
                                <FormErrorMessage>
                                    {errors.observation && errors.observation.message}
                                </FormErrorMessage>
                            </FormControl>
                            <Button
                                type='submit'
                                mt={5}
                                width={'full'}
                                bg={'primary.400'}
                                color={'white'}
                                _hover={{bg: 'primary.500'}}
                            >
                                Actualizar observaciones
                            </Button>
                        </form>
                    </Box>
                    <Divider />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
