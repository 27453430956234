import React from 'react';
import { useParams } from 'react-router-dom';

import {
    Heading,
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from '@chakra-ui/react';

// tabs
import { Events, Overview, Records, Surgeries, Vaccines } from './tabs';

export const PatientDetails = (): JSX.Element => {
    const { id } = useParams();

    return (
        <>
            <Heading as='h1' size='lg' noOfLines={1} ml={'1'} mb={'5'} >
                Mascota
            </Heading>
            <Tabs isLazy>
                <TabList>
                    <Tab color={'primary.500'}>Detalles</Tab>
                    <Tab color={'primary.500'}>Historia</Tab>
                    <Tab color={'primary.500'}>Vacunas</Tab>
                    <Tab color={'primary.500'}>Cirugías</Tab>
                    <Tab color={'primary.500'}>Eventos</Tab>
                </TabList>
                <TabIndicator
                    mt="-1.5px"
                    height="2px"
                    bg="'primary.500'"
                    borderRadius="1px"
                />
                <TabPanels>
                    <TabPanel>
                        <Overview petId={id} />
                    </TabPanel>
                    <TabPanel>
                        <Records petId={id} />
                    </TabPanel>
                    <TabPanel>
                        <Vaccines petId={id} />
                    </TabPanel>
                    <TabPanel>
                        <Surgeries petId={id} />
                    </TabPanel>
                    <TabPanel>
                        <Events petId={id} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};
