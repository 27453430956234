import * as yup from 'yup';

export const patientSchema = yup.object({
    name: yup.string().label('name').required('Este campo es requerido'),
    specie_id: yup.number().label('specie_id').required('Este campo es requerido'),
    race_id: yup.number().label('race_id').required('Este campo es requerido'),
    birth: yup.string().label('birth').required('Este campo es requerido'),
    color: yup.string().label('color').required('Este campo es requerido'),
    sex_id: yup.number().label('sex_id').required('Este campo es requerido'),
    neutered: yup.boolean().label('neutered').required('Este campo es requerido'),
    pathology: yup.string().label('pathology'),
    chip_id: yup.string().label('chip_id'),
});

export type patientSchemaType = yup.InferType<typeof patientSchema>
