import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { IPaginateResponse, IPatient } from '../../../../interfaces';
import { Button, Text, useToast } from '@chakra-ui/react';
import { useFetch } from '../../../../hooks';
import { DataTable } from '../../../../components';

interface IPets {
    ownerId?: string;
}

export const Pets = (props: IPets): JSX.Element => {
    const { ownerId } = props;
    const navigate = useNavigate();
    const toast = useToast();

    const [page, setPage] = useState<number>(1);
    const { fetchData, loading, error, data} = useFetch<IPaginateResponse<IPatient>>(`/patients/owner/${Number(ownerId)}?page=${page}`, undefined, false);

    useEffect(() => fetchData(), [page]);

    useEffect(() => {
        if (!loading && Boolean(error)) {
            toast({
                description: error?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loading]);

    const columns = useMemo<Column<IPatient>[]>(() => [
        {
            id: 'id',
            Header: 'Nro Historia',
            accessor: 'id',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text>{value}</Text>
                );
            },
        },
        {
            id: 'name',
            Header: 'Nombre',
            accessor: 'name',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Text>{value}</Text>
                );
            },
        },
        {
            id: 'specie',
            Header: 'Especie',
            accessor: 'race',
            Cell: ({ value }: CellProps<IPatient>) => {
                const { specie } = value;
                return (
                    <Text>{specie.name}</Text>
                );
            },
        },
        {
            id: 'race',
            Header: 'Raza',
            accessor: 'race',
            Cell: ({ value }: CellProps<IPatient>) => {
                const { name } = value;
                return (
                    <Text>{name}</Text>
                );
            },
        },
        {
            id: 'details',
            Header: 'Detalles',
            accessor: 'id',
            Cell: ({ value }: CellProps<IPatient>) => {
                return (
                    <Button
                        bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => navigate(`/paciente/${value}`)}>
                        Ver
                    </Button>
                );
            },
            disableSortBy: true,
        },
    ],  []);

    return (
        <DataTable
            columns={columns}
            data={data?.items || []}
            loading={loading}
            searchTitle={'Búsqueda local'}
            numberOfPage={data?.numberOfPages || 1}
            currentPage={data?.currentPage || 1}
            handlePagination={page => setPage(page)}
        />
    );
};
