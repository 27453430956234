import React, { useEffect } from 'react';

import { Box, Flex, Heading, useToast } from '@chakra-ui/react';
import { ButtonActions, EmptyState, FormRecord, Header } from './sections';

import { useAppSelector } from '../../../state/hooks';
import { useFetch } from '../../../hooks';
import { IAppointment, IParamsReq } from '../../../interfaces';
import { encodingToQuery } from '../../../tools';
import default_avatar from '../../../assets/images/galenos.webp';
import { useParams } from 'react-router-dom';

export const Appointment = (): JSX.Element => {
    const { doctor } = useAppSelector(state => state.doctor);
    const { id: appointmentId } = useParams();

    const toast = useToast();
    const {loading, data, fetchData} = useFetch<IAppointment>('', undefined, false);
    const {loading: loadingStart, error: errorStart, data: startData, fetchData: doStart} = useFetch<IAppointment>('', undefined, false);

    useEffect(() => {
        if (!loading && doctor) {
            fetchData(`/appointments/${doctor.id}/current/${appointmentId}`);
        }
    }, [doctor]);

    useEffect(() => {
        if (!loadingStart && startData && doctor) {
            fetchData(`/appointments/${doctor.id}/current`);
        }
    }, [loadingStart]);

    useEffect(() => {
        if (!loadingStart && Boolean(errorStart)) {
            toast({
                description: errorStart?.message || 'Ha ocurrido un error',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingStart]);

    if (!data || !doctor) {
        return <EmptyState />;
    }

    const { patient, record } = data;
    const { id, name, race, birth, color, sex, neutered, pathology, chip_id, avatar } = patient;
    const { user, phone } = patient.owner;

    const start = (): void => {
        const query: IParamsReq = {
            doctor_id: doctor.id,
        };
        doStart(`/appointments/${data.id}/set?${encodingToQuery(query)}`);
    };

    const newReview = (): void => {
        const query: IParamsReq = {
            doctor_id: doctor.id,
            is_review: true,
            patient_id: id,

        };
        doStart(`/appointments/${data.id}/set?${encodingToQuery(query)}`);
    };

    return (
        <>
            <Flex justifyContent={'space-between'} marginBottom={5}>
                <Heading size='lg'>
                    Consulta en progreso
                </Heading>
                <Heading size='md' marginTop={2}>
                    Historia: {id}
                </Heading>
            </Flex>
            <Box bg={'white'} padding={3} borderRadius={'lg'}>
                <Header
                    ownerName={`${user.first_name} ${user.last_name}`}
                    email={user.email}
                    phone={phone}
                    petId={id}
                    petName={name}
                    specie={race.specie.name}
                    race={race.name}
                    birth={birth}
                    color={color}
                    sex={sex.name}
                    neutered={neutered ? 'SI' : 'NO'}
                    pathology={pathology || 'N/R'}
                    chip_id={chip_id || 'N/P'}
                    avatar={avatar || default_avatar}
                />
                <Box marginTop={4}>
                    {
                        !Boolean(record) ?
                            <ButtonActions
                                actionRight={start}
                                actionLeft={newReview}
                                loading={loading || loadingStart}
                            />
                            :
                            <FormRecord
                                appointment={data}
                                doctorId={doctor.id}
                            />
                    }
                </Box>
            </Box>
        </>
    );
};

