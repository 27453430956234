import React from 'react';
import { IOwner } from '../../../interfaces';
import { SearchOwner } from '../../../components';
import { LabelStep } from './label-step';
import { Box } from '@chakra-ui/react';

interface ISecondStep {
    ownerInfo?: {owner?: IOwner, dni: string};
    petId?: number;
    setOwner: (info: {owner?: IOwner, dni: string}) => void;
}

export const SecondStep = (props: ISecondStep): JSX.Element | null => {
    const { ownerInfo, petId, setOwner} = props;

    if (petId) {
        return (
            <Box mb={5}>
                {
                    !ownerInfo &&
                    <LabelStep
                        step={2}
                        label={'Buscar propietario a asignar'}
                    />
                }
                <SearchOwner buttonTitle={'Buscar'} setOwner={setOwner} />
            </Box>
        );
    }
    return null;
};
