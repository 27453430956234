import React, { useEffect } from 'react';
import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Heading,
    Divider,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    InputGroup,
    Input,
    InputRightAddon,
    Select,
    useToast
} from '@chakra-ui/react';
import { GiWeightScale } from 'react-icons/gi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { constantsSchema, constantsSchemaType } from './constants-schema';
import { RiTempColdFill } from 'react-icons/ri';
import { AiFillHeart } from 'react-icons/ai';
import { TbWind } from 'react-icons/tb';
import { IMedicalReviews, IPhysiologicalConstantsReq } from '../../../../interfaces';
import { usePut } from '../../../../hooks';

interface IEditPhysiologicalConstantsModal {
    isOpen: boolean;
    id: number;
    physiologicalConstants: IPhysiologicalConstantsReq;
    onClose: () => void;
    onUpdate: () => void;
}

export const EditPhysiologicalConstantsModal = (props: IEditPhysiologicalConstantsModal): JSX.Element => {
    const { isOpen, id, physiologicalConstants, onClose, onUpdate } = props;
    const toast = useToast();

    const { loading: loadingUpdate, error: errorUpdate, data: dataUpdate, doUpdate } = usePut<IMedicalReviews, IPhysiologicalConstantsReq>(`records/physiological_constants/${id}`);

    const { register, handleSubmit, formState } = useForm<constantsSchemaType>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(constantsSchema),
        criteriaMode: 'firstError',
        defaultValues: {
            weight: physiologicalConstants.weight || 0,
            heart_rate: physiologicalConstants.heart_rate || 0,
            breathing_rate: physiologicalConstants.breathing_rate || 0,
            temperature: physiologicalConstants.temperature || 0,
            body_condition: physiologicalConstants.body_condition || 0,
        }
    });
    const { errors, isDirty} = formState;


    useEffect(() => {
        if (!loadingUpdate && Boolean(dataUpdate)) {
            toast({
                description: 'Datos actualizados exitosamente',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
            onClose();
            onUpdate();
        }
    }, [loadingUpdate]);

    useEffect(() => {
        if (!loadingUpdate && Boolean(errorUpdate)) {
            toast({
                description: errorUpdate?.message || 'Error actualizando datos',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        }
    }, [loadingUpdate, errorUpdate]);

    const onSubmit = (schema: constantsSchemaType): void => {
        const constants: IPhysiologicalConstantsReq = {
            weight: schema.weight || 0,
            heart_rate: schema.heart_rate || 0,
            breathing_rate: schema.breathing_rate || 0,
            temperature: schema.temperature || 0,
            body_condition: schema.body_condition || 0,
        };
        doUpdate(constants);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}>
            <ModalOverlay />
            <ModalContent pb={10}>
                <ModalHeader>
                    <Heading as='h1' size='lg'>
                        Editar Constantes Fisiológicas
                    </Heading>
                    <Divider />
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl mt={2} isDisabled={loadingUpdate} isInvalid={!!errors?.temperature?.message}>
                            <FormLabel htmlFor="date" color="primary.600">Temperatura</FormLabel>
                            <InputGroup>
                                <Input
                                    id="temperature"
                                    type="number"
                                    step={0.01}
                                    focusBorderColor='primary.400'
                                    {...register('temperature')}
                                />
                                <InputRightAddon
                                    children={<RiTempColdFill color='#98b399' />}
                                />
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.temperature && errors.temperature.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl marginInline={1} isInvalid={!!errors?.weight?.message} mt={2} isDisabled={loadingUpdate}>
                            <FormLabel htmlFor="weight" color="primary.600">Peso</FormLabel>
                            <InputGroup>
                                <Input
                                    id="weight"
                                    type="number"
                                    step={0.01}
                                    focusBorderColor='primary.400'
                                    {...register('weight')}
                                />
                                <InputRightAddon
                                    children={<GiWeightScale color='#98b399' />}
                                />
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.weight && errors.weight.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl mt={2} isDisabled={loadingUpdate} isInvalid={!!errors?.body_condition?.message} >
                            <FormLabel htmlFor="body_condition" color="primary.600">Condición Corporal</FormLabel>
                            <Select
                                id="body_condition"
                                {...register('body_condition')}
                                focusBorderColor='primary.400'
                                bg={'white'}
                                variant={'outline'}
                            >
                                <option key={0} value={0}>SELECCIONE</option>
                                <option key={1} value={1}>1</option>
                                <option key={2} value={2}>2</option>
                                <option key={3} value={3}>3</option>
                                <option key={4} value={3}>4</option>
                                <option key={5} value={5}>5</option>
                            </Select>
                            <FormErrorMessage>
                                {errors.body_condition && errors.body_condition.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl mt={2} isDisabled={loadingUpdate} isInvalid={!!errors?.heart_rate?.message} >
                            <FormLabel htmlFor="date" color="primary.600">Frecuencia Cardiaca</FormLabel>
                            <InputGroup>
                                <Input id="heart_rate"
                                    type="number"
                                    step={0.01}
                                    focusBorderColor='primary.400'
                                    {...register('heart_rate')}
                                />
                                <InputRightAddon
                                    children={<AiFillHeart color='#98b399' />}
                                />
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.heart_rate && errors.heart_rate.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl marginLeft={1} isInvalid={!!errors?.breathing_rate?.message} mt={2} isDisabled={loadingUpdate}>
                            <FormLabel htmlFor="breathing_rate" color="primary.600">Frecuencia Respiratoria</FormLabel>
                            <InputGroup>
                                <Input
                                    id="breathing_rate"
                                    type="number"
                                    step={0.01}
                                    focusBorderColor='primary.400'
                                    {...register('breathing_rate')}
                                />
                                <InputRightAddon
                                    children={<TbWind color={'#98b399'} />}
                                />
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.breathing_rate && errors.breathing_rate.message}
                            </FormErrorMessage>
                        </FormControl>
                        <Flex justifyContent={'flex-end'}>
                            <Button type='submit' mt={5} isDisabled={!isDirty} bg={'primary.400'} color={'white'} _hover={{bg: 'primary.500'}}>
                            Actualizar
                            </Button>
                        </Flex>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
