import React from 'react';
import { Circle, Flex, Text } from '@chakra-ui/react';

interface ILabelStep {
    step: number;
    label: string;
}

export const LabelStep = (props: ILabelStep): JSX.Element => {
    const { step, label } = props;

    return (
        <Flex justifyContent={'flex-start'} alignItems={'center'} mb={2}>
            <Circle size='20px' bg='secondary.400' color='white'>
                <Text>{step}</Text>
            </Circle>
            <Text ml={2} textTransform={'uppercase'}>{label}</Text>
        </Flex>
    );
};
